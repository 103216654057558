import { deleteItem } from '../common/utilities';
import { CustomComponentType } from './customComponent';
import { CustomComponentRepositoryService } from './customComponentRepository.service';
import { CustomComponentBuilderModel } from './customComponentBuilder';
import { DiagramMissionsSet } from '../app/missionProgression';
import { CustomComponents } from './customComponents';



// Manages the set of custom component builder. One instance of this is created.
export class CustomComponentBuilders {
    customComponentBuilders: CustomComponentBuilderModel[] = [];
    constructor(readonly repository: CustomComponentRepositoryService, readonly history: DiagramMissionsSet, readonly customNodes: CustomComponents) {
        this.customComponentBuilders = customNodes.list.map(node =>
            new CustomComponentBuilderModel(node, customNodes, this.history));
    }
    create() {
        const id = this.nextId();
        const name = `comp ${id}`;
        const node = CustomComponentType.create(String(id), name, this.repository);
        this.customNodes.add(node);
        const comp = new CustomComponentBuilderModel(node, this.customNodes, this.history);
        this.customComponentBuilders.push(comp);
        this.save();
        return comp;
    }

    nextId() {
        if (this.customComponentBuilders.length > 0) {
            const ids = this.customComponentBuilders.map(cc => parseInt(cc.key, 10)).filter(num => !isNaN(num));
            return Math.max(...ids) + 1;
        } else {
            return 1;
        }
    }

    save() {
        this.repository.saveComponents(this.customComponentBuilders.map(c => c.key));
    }

    deleteComponent(currentComponent: CustomComponentBuilderModel) {
        const nodeType = currentComponent.nodeType;
        // delete from diagrams
        this.customComponentBuilders.forEach(c => c.nodeType.deleteAllComponentsOfType(nodeType));
        this.history.diagrams.forEach(c => c.structure.deleteAllComponentsOfType(nodeType));
        // delete from collections
        deleteItem(this.customComponentBuilders, currentComponent);
        this.customNodes.delete(currentComponent.nodeType);
        this.repository.deleteComponent(nodeType.key);
        this.save();
    }
}
