import React from "react";
import { NetworkDevice } from "./networkDevice";
import './NetworkDeviceComponent.css';


export function Led({ state, label }: {state: boolean, label:string}) {
    return (<div className="led-box">
        <span className={state ? 'led-on' : 'led-off'}></span>
        <div>{label}</div>
    </div>);
}

export function NetworkDeviceComponent({ networkDevice }: { networkDevice: NetworkDevice }) {
    const [state, setState] = React.useState(() => {
       return {
           started: false,
           running: false,
           dataIndex: 0,
       };
    });
    function onReset() {
        networkDevice.reset();
        setState(state => ({ ...state, running: false }));
    }
    function onTick() {
        networkDevice.step();
        setState(state => ({ ...state }));
    }
    function onRun() {
        setState(state => ({ ...state, running: true }));
    }
    function onStop() {
        setState(state => ({ ...state, running: false }));
    }
    return (<div className='network-device'>
        <p><i>The Other Computer</i></p>
        <div>
            <Led state={networkDevice.syncFlag} label='Sync' />
            <Led state={networkDevice.dataFlag} label='Data' />
        </div>
        {/* 
        {!state.running && <>
            <div><button className="btn btn-secondary" onClick={onReset}>Reset</button></div>
            <div><button className="btn btn-secondary" onClick={onTick}>
                <i className="bi bi-skip-end-fill"></i> Step</button></div>
            <div>
            <button className="btn btn-secondary" onClick={onRun}>
                <i className="bi bi-play-fill"></i> Run</button>
            </div>
            </>
        }
        {state.running && 
            <>
            <div>
                <button className="btn btn-secondary" onClick={onStop}>
                <i className="bi bi-stop-fill"></i> Stop</button>
            </div>
            <div>
                <i>Transmitting with a network speed of 1/100 of the cpu clock.</i>
            </div>
            </>
        }
        */}
    </div>);
}