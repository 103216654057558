import './ClockComponent.css';
import { ClockStateView } from '../../missions/clockNodeType';
import { useState } from 'react';

export function ClockComponent(props: {stateView: ClockStateView}) { 
	const stateView = props.stateView;

    const [state, setState] = useState(stateView.getState());

    function clockTick() {
        props.stateView.state.flip();
        setState(stateView.getState());
        window.setTimeout(() => {
            props.stateView.state.flip();
            setState(stateView.getState());
        }, 300);
    }

	return (<div className='input-controls'>
            <button type='button' disabled={state === 2} onClick={()=>clockTick()} className='btn btn-primary'>
                { state === 0 && (
                    <i className='bi bi-heart'></i>)} 
                { state === 1 && (
                    <i className='bi bi-heart-fill'></i>)} 
                <> </>Pulse
            </button>
        </div>); 
}

