import { AssemblerPersistence } from '../assemblerMissions';
import { pureStackTest } from './codeTester';
import { StackMissionState } from './stackMissionState';
import { MissionKind } from '../../app/task';
import { Repository } from '../../app/repository';
import { MissionProgression } from '../../app/missionProgression';

export const stackNotMissionTests = [
    pureStackTest([0], [0xFFFF]),
    pureStackTest([1], [0xFFFE])
];

export class StackNotMissionState extends StackMissionState {
    defaultCode = `# Bit-wise negation \n`;
    tests = stackNotMissionTests;
}

export const stackNotMission = {
    key: 'STACK_NOT',
    kind: MissionKind.Stack,
    macro: { name: 'NOT' },
    start(repository: Repository, game: MissionProgression) {
        return new StackNotMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new StackNotMissionState(this, repository, game, data);
    },
};

export const stackNegTests = [
    pureStackTest([0], [0]),
    pureStackTest([1], [0xFFFF])
];

export class StackNegMissionState extends StackMissionState {
    defaultCode = `# Artihmetic negation (twos-complement) \n`;
    tests = stackNegTests;
}

export const stackNegMission = {
    key: 'STACK_NEG',
    kind: MissionKind.Stack,
    macro: { name: 'NEG' },
    start(repository: Repository, game: MissionProgression) {
        return new StackNegMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new StackNegMissionState(this, repository, game, data);
    },
};
