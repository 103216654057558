import './edge-node.css';
import { InputConnectorComponent } from '../connector/InputConnectorComponent';
import { StateViewComponent } from '../stateview/StateViewComponent';
import { EdgeInputConnectorState, InputConnectorState } from '../../circuitState';
import { ComponentBuilder } from '../../componentBuilder';
import { StateView } from '../../componentType';
import { DiagramActions } from '../builder/DiagramBuilder';


class OutputStateView implements StateView {
    kind: string;
    constructor(readonly connector: InputConnectorState) {
        this.kind = connector.connector.pin.width === 16 ? 'Word' : 'Bit';
    }
    getState() { return this.connector.state; }
}

export function OutputNodeComponent(props: {
		connector: EdgeInputConnectorState;
		model: ComponentBuilder,
        selectedConnector: InputConnectorState | undefined;
        diagramActions: DiagramActions;
    }) { 

    const stateView = new OutputStateView(props.connector);
    const oscillating =  props.connector.oscillating;
    const floating =  props.connector.state === null;

    function deletePin() {
        props.diagramActions.deleteOutputPin(connector);
    }
    function editLabel() {
        const newLbl = window.prompt('label');
        if (newLbl !== null) {
            props.diagramActions.updateOutputPinLabel(connector, newLbl);
        }
    }

	const connector = props.connector;
	const model = props.model;

	return (<div className='outer'>

    <div className={'diagram-node output-node' + (oscillating ? ' oscillating' : '')}>
        <div className='node-box'>
            { !floating && (
                <StateViewComponent stateView={stateView} />
            )} 
        </div>

        <div className='connector-row'>
            <InputConnectorComponent connector={connector} isPalette={false} isFree={false}
                diagramActions={props.diagramActions}
                selectedConnector={props.selectedConnector}
            />
        </div>
    </div>

    { model.canModifyPins && (
        <div>
        <div className='dropdown' style={{position: 'relative'}}>
            <button type='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='btn btn-sm btn-light dropdown-toggle'>
            </button>
            <ul className='dropdown-menu'>
                <li><button onClick={()=>deletePin()} className='dropdown-item'><i className='bi bi-x-lg'></i> Delete</button></li>
                <li><button onClick={()=>editLabel()} className='dropdown-item'><i className='bi bi-tag'></i> Label</button></li>
            </ul>
        </div>
    </div>)} 

</div>
); 
}

