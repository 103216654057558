import { depends } from './dependency';
import { word, bit } from '../pins';
import { ComponentInstanceState } from '../circuitState';
import { DelegateStateView } from '../stateViews';
import { dff2Mission } from './dffMissions';
import { ComponentInternalState } from '../componentType';
import { BaseBuiltinComponentType } from './baseNodeType';


export class RegisterState implements ComponentInternalState {
    oldState = 0;
    newState = 0;
    readonly stateView = new DelegateStateView(() => this.oldState, 'Word');
    resolveOutputs(node: ComponentInstanceState) {
        const st = node.inputConnectorStates[0].bitState;
        const data = node.inputConnectorStates[1].numState;
        const clock = node.inputConnectorStates[2].bitState;
        return this.resolve(st, data, clock);
    }
    resolve(st: boolean, data: number, clock: boolean) {
        if (st && !clock) {
            this.newState = data;
        }
        if (clock) {
            this.oldState = this.newState;
        }
        const out = this.oldState;
        return [out];
    }

    reset() {
        this.oldState = 0;
        this.newState = 0;
    }
}

export const dff16NodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'register';
    readonly key = 'DFF16';
    readonly inputs = [bit('st'), word('X'), bit('cl')];
    readonly outputs = [word()];
    readonly hasInternalState = true;
    readonly depends = depends(dff2Mission, 8, false);
    readonly createInternalState = () => new RegisterState();
};
