import { VerificationResultSet } from './verificationResults';
import { Hint } from '../diagram/hint';
import { MissionProgression } from './missionProgression';
import { Repository } from './repository';


export enum MissionStatus {
    Locked,
    Unlocked,
    Started,
    Complete,
}

export enum MissionKind {
    ComponentBuilder,
    Opcodes,
    MachineCode,
    Assembler,
    Escape,
    Stack,
    Tokenize,
    Grammar,
    Compiler,
    Network,
} 


export interface Task {
    // persistent key used in serialization
    // cannot be changed!
    readonly key: string;
    readonly tag?: string;
    readonly kind: MissionKind;
    readonly unlock?: boolean;
    readonly hints?: readonly Hint[];
    start(levelRepository: Repository, missions: MissionProgression): MissionState;
    restore(levelRepository: Repository, missions: MissionProgression): MissionState;
}

export interface MissionState {
    verify(): VerificationResultSet;
    hasState: boolean;
    resetState(): void;
    readonly isCompleted: boolean;
}
