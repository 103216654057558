import { Task } from '../app/task';
import { opcodes1Mission } from './opcodes';
import { optionalMissionsCategory, diagramMission } from '../app/optionalTrack';
import { 
    cpuMission,
    inputOutputMission, 
    controlUnitMission, 
    unaryAluMission, conditionMission, aluMission, 
    instructionDecoderMission, 
    combinedStateMission, 
    ramMission, 
    latchMission, flipFlopMission, dff2Mission, counterMission, 
    demuxMission, invMission, andMission, orMission, xorMission, selectorMission,
    addMission, addMultiMission, subMission, addcMission, incMission,
    isZero4Mission, signMission, nandMission
} from '../diagram/missions';
import { getSoftwareTrack } from './softwareTrack';


export type MissionEpic = {
    readonly name: string;
    readonly primaryTrack?: boolean;
    readonly groups: readonly MissionGroup[];
};

export type MissionGroup = {
    readonly name: string;
    readonly missions: readonly Task[];
};

export const getMissions = (): MissionEpic[] =>
    [
        {
            name: 'Hardware',
            groups: getPrimaryTrack()
        },
        {
            name: 'Software',
            groups: getSoftwareTrack()
        },
        optionalMissionsCategory
    ];


const getPrimaryTrack = (): MissionGroup[] =>
    [
        {
            name: 'Logic Gates',
            missions: [
                diagramMission(nandMission),
                diagramMission(invMission),
                diagramMission(andMission),
                diagramMission(orMission),
                diagramMission(xorMission)
            ]
        },
        {
            name: 'Arithmetics',
            missions: [
                diagramMission(addMission),
                diagramMission(addcMission),
                diagramMission(addMultiMission),
                diagramMission(incMission),
                diagramMission(subMission),
                diagramMission(isZero4Mission),
                diagramMission(signMission)
            ]
        },
        {
            name: 'Plumbing',
            missions: [
                diagramMission(selectorMission),
                diagramMission(demuxMission)
            ]
        },
        {
            name: 'Memory',
            missions: [
                diagramMission(latchMission),
                diagramMission(flipFlopMission),
                diagramMission(dff2Mission),
                diagramMission(counterMission),
                diagramMission(ramMission)
            ]
        },
        {
            name: 'Arithmetic Logic Unit',
            missions: [
                diagramMission(unaryAluMission),
                diagramMission(aluMission),
                opcodes1Mission,
                diagramMission(conditionMission)
            ]
        },
        {
            name: 'Processor',
            missions: [
                diagramMission(combinedStateMission),
                diagramMission(instructionDecoderMission),
                diagramMission(controlUnitMission),
                diagramMission(cpuMission),
                diagramMission(inputOutputMission)
            ]
        },
    ];

