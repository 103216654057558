/*  Verification of a level.
 *  An VerificationResultSet may contain multiple VerificationResult's with context information.
 *  If just one VerificationResult is failed, the whole verification is failed.
 */


export interface VerificationResult {
    readonly succeeded: boolean;
}

export class VerificationOk implements VerificationResult {
    readonly succeeded = true;
}

export class VerificationError implements VerificationResult {
    readonly succeeded = false;
    constructor(public readonly errorMessage: string) { }
}

export interface VerificationResultSet {
    readonly succeeded: boolean;
    firstError: VerificationError;
}

/** A verification containing a single result */
export class UnitVerificationResultSet implements VerificationResultSet {
    constructor(public readonly result: VerificationResult) {}

    get succeeded() {
        return this.result.succeeded;
    }
    get firstError() {
        return this.result as VerificationError;
    }
}

export class CompositeVerificationResultSet implements VerificationResultSet {
    constructor(public readonly results: VerificationResult[]) {}

    get succeeded() {
        return this.results.every(r => r.succeeded);
    }
    get firstError() {
        return this.results.find(r => !r.succeeded) as VerificationError;
    }
}
