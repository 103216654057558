import { InputConnectorState, OutputConnectorState } from './circuitState';
import { ComponentBuilder } from './componentBuilder';
import { GameState } from '../app/gameState';
import { DiagramMissionState } from './diagramMissionState';


// Adapts a diagram mission to the builder UI (which is also used for custom components)
// Instantiated every time a mission is selected
export class DiagramMissionComponentBuilder implements ComponentBuilder {
    canModifyPins = false;
    constructor(readonly mission: DiagramMissionState, readonly gameState: GameState) { }
    get palette() { return this.mission.getPalette(this.gameState); }
    get diagram() { return this.mission.diagram; }
    clearCanvas() { this.diagram.clearCanvas(); }
    addInputPin() { }
    addOutputPin() { }
    deleteInputPin(_node: OutputConnectorState) {}
    deleteOutputPin(_node: InputConnectorState) {}
}
