import { Engine, StackFrame } from './engine';
import { Token } from './scanner';

/*
    add     Integer addition (2's complement) 
    sub     Integer subtraction (2's complement) 
    neg     Artihmetic negation (2's complement) 
    eq
    gt
    lt
    and
    or
    not

    push segment index
    pop segment index

    segments:
        argument
        local
        static
        constant
        this
        that
        pointer
        temp

    label symbol
    goto symbol
    if-goto symbol

    function functionName nLocals
    call functionName nArgs
    return
*/

export enum Segment {
    argument = 'argument',
    local = 'local',
    static = 'static',
    constant = 'constant',
    this = 'this',
    that = 'that',
    pointer = 'pointer',
    temp = 'temp',
}

export abstract class Instruction {
    constructor(public readonly token: Token) {}
    abstract execute(engine: Engine): void;
    get type() {
        return this.token.value;
    }
}

export class InitStackInstruction extends Instruction {
    execute(engine: Engine) {
        // resets stack
        engine.initStack();
    }
}

export class StopInstruction extends Instruction {
    execute(engine: Engine) {
        return 'exit';
    }
}

abstract class UnaryArthmeticLogicInstruction extends Instruction {
    execute(engine: Engine) {
        const y = engine.popStack();
        const r = this.eval(y);
        engine.pushStack(r);
    }
    abstract eval(y: number): number;
}

abstract class BinaryArithmeticLogicInstruction extends Instruction {
    execute(engine: Engine) {
        const y = engine.popStack();
        const x = engine.popStack();
        const r = this.eval(x, y);
        engine.pushStack(r);
    }
    abstract eval(x: number, y: number): number;
}

export class Add extends BinaryArithmeticLogicInstruction {
    eval = (x: number, y: number) => (x + y) & 0xffff;
}

export class Sub extends BinaryArithmeticLogicInstruction {
    eval = (x: number, y: number) => (0x10000 + x - y) & 0xffff;
}

export class Neg extends UnaryArthmeticLogicInstruction {
    eval = (y: number) => (0x10000 - y) & 0xffff;
}

const boolToValue = (b: boolean) => (b ? 0xffff : 0);

export class Eq extends BinaryArithmeticLogicInstruction {
    eval = (x: number, y: number) => boolToValue(x === y);
}

export class Gt extends BinaryArithmeticLogicInstruction {
    eval = (x: number, y: number) => boolToValue(x > y);
}

export class Lt extends BinaryArithmeticLogicInstruction {
    eval = (x: number, y: number) => boolToValue(x < y);
}

export class And extends BinaryArithmeticLogicInstruction {
    eval = (x: number, y: number) => x & y;
}

export class Or extends BinaryArithmeticLogicInstruction {
    eval = (x: number, y: number) => x | y;
}

export class Not extends UnaryArthmeticLogicInstruction {
    eval = (y: number) => ~y & 0xffff;
}

export class PushInstruction extends Instruction {
    constructor(public token: Token, public segment: Segment, public index: number) {
        super(token);
    }
    execute(engine: Engine) {
        const value = engine.getSegment(this.segment, this.index);
        engine.pushStack(value);
    }
}

export class PushValueInstruction extends Instruction {
    constructor(public token: Token, public value: number) {
        super(token);
    }
    execute(engine: Engine) {
        engine.pushStack(this.value);
    }
}

export class PopInstruction extends Instruction {
    constructor(public token: Token, public segment: Segment, public index: number) {
        super(token);
    }
    execute(engine: Engine) {
        const value = engine.popStack();
        engine.setSegment(this.segment, this.index, value);
    }
}

export class GotoInstruction extends Instruction {
    address?: number;
    constructor(public token: Token, public symbol: string) {
        super(token);
    }
    execute(engine: Engine) {
        engine.currentFrame.programCounter = this.address!;
    }
}

export class IfGotoInstruction extends GotoInstruction {
    execute(engine: Engine) {
        const value = engine.popStack();
        if (value !== 0) {
            engine.currentFrame.programCounter = this.address!;
        }
    }
}

export class ReturnInstruction extends Instruction {
    execute(engine: Engine) {
        if (engine.atTopFrame) {
            throw new Error(`Cannot return from top frame`);
        }
        const returnValue = engine.popStack();
        // TODO: Check stack empty
        engine.callStack.pop()!;
        engine.pushStack(returnValue);
    }
}

export class Label {
    constructor(public addr: number) {}
}

export class FunctionDefinition {
    isTop = false;
    instructions: Instruction[] = [];
    labels = new Map<string, Label>();
    constructor(public token: Token, public localsCount: number) {}
}

export class CallInstruction extends Instruction {
    functionDefinition?: FunctionDefinition;
    constructor(public token: Token, public functionName: string, public argumentCount: number) {
        super(token);
    }
    execute(engine: Engine) {
        const args: number[] = [];
        for (let i = 0; i < this.argumentCount; i++) {
            args.push(engine.popStack());
        }
        const frame = new StackFrame(this.functionDefinition!, args);
        engine.callStack.push(frame);
    }
}
