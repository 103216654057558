import React from 'react';
import './MissionsOverview.css';
import { GameStateContext } from '../gameState';
import { MissionStatus } from '../missionStatus';
import { MissionTextLoader } from './missionTextLoader';
import { TextContext } from '../../locale/text.service';
import { ActivatedMissionEpic, MissionItem } from '../missionProgression';
import { Tab, Tabs } from 'react-bootstrap';


export function MissionsOverview(props: {
		currentMission: MissionItem,
        selectMission: (item: MissionItem)=>void
    }) { 

    const gameState = React.useContext(GameStateContext);
    const history =  gameState.missionProgression;
    const epics =  history.activatedEpics;
    const currentMission = props.currentMission;

    const startedEpics = epics.filter(e => e.groups.some(g => g.missions.some(m => m.isStarted)));
    if (startedEpics.length <= 1) {
        // No tabs if we are still at the first epic
        return (
            <div className='epics'>
                <EpicOverview 
                    epic={epics[0]} 
                    currentMission={props.currentMission}
                    selectMission={props.selectMission}
                /></div>);
    }
    // Multiple epics started. Show as tabs.
    // Select the tab containing the current mission
    const currentEpic = startedEpics.find(e => e.groups
        .some(g => g.missions.some(m => m.mission === currentMission.mission)))
    const tabs = startedEpics.map(missionEpic => (
        <Tab 
            title={missionEpic.name} 
            eventKey={missionEpic.name} 
            key={missionEpic.name}
        >
            <EpicOverview 
                epic={missionEpic} 
                currentMission={props.currentMission}
                selectMission={props.selectMission}
            />
        </Tab>));
	return (<div className='epics'>
        <Tabs
            defaultActiveKey={currentEpic!.name}
            >{ tabs }</Tabs></div>); 
    }


export function EpicOverview(props: { 
    epic: ActivatedMissionEpic,
    currentMission: MissionItem,
    selectMission: (item: MissionItem)=>void
}) {
    const textService = React.useContext(TextContext);
    const epic = props.epic;
	const currentMission = props.currentMission;
    function getName(mission: MissionItem) {
        const texts = new MissionTextLoader(mission.mission, textService);
        return texts.title;
    }
    function missionLabel(mission: MissionItem) {
        return (<>
            <span>{getName(mission)} </span>
            { mission.tag && (
                <span className='badge bg-warning text-dark'>{mission.tag}</span>)} </>); 
    }
    function currentMissionAction(mission: MissionItem) {
        return (
            <button onClick={()=>props.selectMission(mission)} 
                    className='list-group-item list-group-item-action active'>
                <span className='marker'>
                    <i className='bi bi-play-fill'></i>
                </span>
                    {missionLabel(mission)}
                </button>
        );
    }
    function missionAction(mission: MissionItem) {
        switch (mission.status) {
            case MissionStatus.Complete: return (
                <button onClick={()=>props.selectMission(mission)} 
                    className='list-group-item list-group-item-action list-group-item-success mission-status-complete'>
                <span className='marker'>
                    <i className='bi bi-check-lg'></i>
                </span>
                {missionLabel(mission)}
                </button>); 

            case MissionStatus.Started: return (
                <button onClick={()=>props.selectMission(mission)} 
                    className='list-group-item list-group-item-action list-group-item-danger mission-status-started'>
                <span className='marker'>
                    <i className='bi bi-x-lg'></i>
                </span>
                {missionLabel(mission)}
                </button>); 

            case MissionStatus.Locked: return (
                <span className='list-group-item list-group-item-light'>
                <span className='marker locked'>
                    <i className='bi bi-lock-fill'></i>
                </span>
                {missionLabel(mission)}
                </span>); 

            case MissionStatus.Unlocked: return (
                <button onClick={()=>props.selectMission(mission)} 
                    className='list-group-item list-group-item-action mission-status-started'>
                <span className='marker'></span>
                {missionLabel(mission)}
                </button>); 
        }
    }
    return (<div className='groups'>
            {epic.groups.map(missionGroup => (
                <div key={missionGroup.name} className='mission-group'>
                <h6 className='mission-group-name'>{missionGroup.name}</h6>
                <div className='list-group'>
                    {missionGroup.missions.map(mission => (
                        <span className='mission-status' key={mission.mission.key}>
                        { mission===currentMission ? 
                            currentMissionAction(mission) : 
                            missionAction(mission) } 
                        </span>))} 
                </div>
            </div>))} 
        </div>);
}
