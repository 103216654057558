import { VerificationError, VerificationOk } from '../app/verificationResults';
import { ComponentInternalState, ComponentType } from './componentType';
import { TestCase, VerificationSubjectAdapter } from './verification';

type StepResult = string | null;

type TestStep = (st: State) => StepResult;

/** A SequentialTest test contains a number of Step's executed in sequence.
 *  A Step may either setup some conditions or verify a condition.
 * */
export class SequentialTest implements TestCase {
    constructor(private steps: TestStep[]) { }

    verify(adapter: VerificationSubjectAdapter) {
        const state = new State(adapter);
        for (const step of this.steps) {
            const result = step(state);
            if (result) {
                const text = state.toText(result);
                return new VerificationError(text);
            }
        }
        return new VerificationOk();
    }
}


/** A set of function which creates test steps */
export namespace Step {

    export function set(input: number[], message: string) {
        return (st: State): StepResult => {
            st.set(input, message);
            return null; // no error possible
        };
    }

    export function setInternalState(nodeType: ComponentType, action: (state: ComponentInternalState) => void) {
        return (st: State) => {
            const node = st.findNode(nodeType);
            if (node) {
                action(node.internalState);
            }
            return null;
        };
    }

    export function assertOutputs(expected: number[]) {
        return (st: State) => st.assertOutput(expected);
    }

    export function assertOutput(label: string, expected: number, message?: string) {
        return (st: State) =>
            st.assertSingleOutput(label, expected, message);
    }

    /* Check that a given component have a given input state. */
    export function internalInput(
            nodeType: ComponentType,
            connectorName: string,
            expected: number, text: string) {
        return (st: State) => {
            const node = st.findNode(nodeType);
            if (node) {
                const connector = node.inputConnectorStates.find(c => c.name === connectorName);
                if (!connector) {
                    throw new Error(`Connector '${connectorName}' not found.`);
                }
                if (connector.state !== expected) {
                    const nodeName = nodeType.name;
                    return `Expected <b>${connectorName}</b> input to <b>${nodeName}</b>
                        to be ${expected} ${text}
                        but was ${connector.state}. `;
                }
            }
            return null;
        };
    }
}

class State {
    log: string[] = [];
    constructor(private adapter: VerificationSubjectAdapter) {
        adapter.resetState();
    }
    set(input: number[], message: string) {
        this.log.push(message);
        this.adapter.setInputs(input);
    }
    assertOutput(expected: number[]): StepResult {
        const actuals = this.adapter.getOutputs();
        const outputLabels = this.adapter.getConnectorLabels()[1];
        if (expected.length !== actuals.length) {
            throw new Error('Output array size mismatch');
        }
        for (let ix = 0; ix < expected.length; ix++) {
            const expectedState = expected[ix];
            const actual = actuals[ix];
            if (actual !== expectedState) {
                console.log('expectedState', expectedState, 'actual', actual);
                // early exit at fist error, so the diagram state is at the error
                return `Expected output <b>${outputLabels[ix]}</b> to be ${expectedState} but was ${actual}`;
            }
        }
        return null;
    }
    assertSingleOutput(label: string, expected: number, message?: string): StepResult {
        const actual = this.adapter.getOutput(label);
        if (actual !== expected) {
            const msg = message ?? '';
            return `Expected output <b>${label}</b> to be ${expected} but was ${actual} ` + msg;
        }
        return null;
    }
    toText(result: string): string {
        if (this.log.length === 1) {
            const header = '<p>This verification was performed:</p>';
            const steps = `<p>${this.log[0]}</p>`;
            const footer = `<p>${result}</p>`;
            return header + steps + footer;
        } else {
            const header = '<p>These verification steps were performed:</p>';
            const steps = '<ol>' + this.log.map(line => `<li>${line}</li>`).join('\n') + '</ol>';
            const footer = `<p>${result}</p>`;
            return header + steps + footer;
        }
    }
    findNode(type: ComponentType) {
        return this.adapter.findInternalNode(type);
    }
}
