import './AppRoot.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { TextPage } from './textpage/TextPage';
import { TextManager } from '../texts/TextManager';
import { TranslateComponent } from '../translate/TranslateComponent';
import { TopLevel } from './game/TopLevel';


export function AppRoot() { 
    return (
<div className='body'>
    <div className='body-content'>
    <Router>
        <Routes>
            <Route path="/" element={<TopLevel />} />
            <Route path="/about" element={<TextPage page='about' />} />
            <Route path="/donate" element={<TextPage page='donate' />} />
            <Route path="/texts" element={<TextManager />} />
            <Route path="/translate/:lang" element={<TranslateComponent />} />
        </Routes>
    </Router>
    </div>
</div>); 
}

