import { bitToBool, boolToBit } from '../../common/bits';
import { ComponentInstanceState } from '../circuitState';


/* Specifies how a components resolves output based on input */
export interface OutputRule {
    resolve(node: ComponentInstanceState): number[];
    resolve1(inp: number[]): number[];
}

export class OutputRuleArray implements OutputRule {
    constructor(readonly rule: (a: number[]) => number[]) { }
    resolve(node: ComponentInstanceState) {
        const inputs = node.inputConnectorStates.map(i => i.numState);
        return this.rule(inputs);
    }
    resolve1(inp: number[]) {
        return this.rule(inp);
    }
}

export class OutputRuleBooleanArray implements OutputRule {
    constructor(readonly rule: (a: boolean[]) => boolean[]) { }
    resolve(node: ComponentInstanceState) {
        const inputs = node.inputConnectorStates.map(i => i.bitState);
        return this.rule(inputs).map(n => boolToBit(n));
    }
    resolve1(inp: number[]) {
        const results = this.rule(inp.map(i => bitToBool(i)));
        return results.map(n => boolToBit(n));
    }
}

// A rule for an output connector based on two input connectors
export class OutputRuleBinary implements OutputRule {
    constructor(readonly rule: (a: boolean, b: boolean) => boolean) { }
    resolve(node: ComponentInstanceState) {
        const a = node.inputConnectorStates[0].bitState;
        const b = node.inputConnectorStates[1].bitState;
        const result = this.rule(a, b);
        return [result ? 1 : 0];
    }
    resolve1(inp: number[]) {
        const result = this.rule(bitToBool(inp[0]), bitToBool(inp[1]));
        return [boolToBit(result)];
    }
}
