import { Machine } from '../../assembler/machine';
import { AssemblerPersistence } from '../assemblerMissions';
import { MissionKind } from '../../app/task';
import { Repository } from '../../app/repository';
import { MissionProgression } from '../../app/missionProgression';
import { VerificationError, VerificationOk } from '../../app/verificationResults';
import { CodeTester } from './codeTester';
import { StackMissionState } from './stackMissionState';


class PopDTest extends CodeTester {
    setup(machine: Machine) {
        // Setup - value 42 on stack
        this.push(machine, 42);
    }
    verify(machine: Machine) {
        // assert D is 42
        const d = machine.d.get();
        if (d !== 42) {
            return new VerificationError(`Expected D to be 42. (Was ${d.toString(16)})`);
        }
        const err = this.expectStack(machine, 0);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export const popDMissionTests = [PopDTest];

export class PopDMissionState extends StackMissionState {
    tests = popDMissionTests;
}

export const popDMission = {
    key: 'STACK_POP_D',
    kind: MissionKind.Stack,
    macro: { name: 'POP_D' },
    start(repository: Repository, game: MissionProgression) {
        return new PopDMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PopDMissionState(this, repository, game, data);
    },
};
