import { diagram } from './missions';
import { numericTest } from '../verification';
import { SequentialTest, Step } from '../sequentialTest';
import { bit, word, PortHints, Pin } from '../pins';
import { assembleInstruction } from '../../assembler/assembler';
import { ComponentInstanceState } from '../circuitState';
import { numberToBoolArray } from '../../common/bits';
import { ComponentInternalState, StateView } from '../componentType';
import { aluNodeType, conditionNodeType } from './aluMissions';
import { instructionDecoderNodeType } from './instructionDecoderMission';
import { cpuStateNodeType, CompositeState } from './combinedStateMission';
import { nandNodeType, selector16NodeType } from './logicMissions';
import { BaseBuiltinComponentType } from './baseNodeType';
import { depends } from './dependency';


export const controlUnitMission = diagram(<const>{
    key: 'EXECUTION_ENGINE',
    inputPins: [new Pin(16, 'I', PortHints.Instruction), bit('cl')],
    outputPins: [bit('j'), word('A')],
    palette: [
        nandNodeType, instructionDecoderNodeType, aluNodeType, selector16NodeType, cpuStateNodeType,
        conditionNodeType
    ],
    tests: [
        // -- stateless tests --
        // first execute the stateless tests, in order to find the easy bugs first
        // [I, cl] => [j, A]
        // should jump
        numericTest([assembleInstruction('A = 1;JMP').toWord(), 0], [1, 0]),
        numericTest([assembleInstruction('A = 0;JEQ').toWord(), 0], [1, 0]),
        // should not jump
        numericTest([assembleInstruction('A = 1;JEQ').toWord(), 0], [0, 0]),

        // -- stateful tests --
        // test computation instruction and A register write and read
        new SequentialTest([
            Step.set([assembleInstruction('A = 1').toWord(), 0], 'Set A to 1 and cl to 0.'),
            Step.internalInput(cpuStateNodeType, 'dst', 0b100, ''),
            Step.internalInput(cpuStateNodeType, 'X', 1, ''),
            Step.internalInput(aluNodeType, 'op', 0b111111, ''),
            Step.set([assembleInstruction('A = 1').toWord(), 1], 'Set cl to 1. A should now emit 1'),
            Step.internalInput(aluNodeType, 'X', 0, '(because register D=0)'),
            Step.internalInput(aluNodeType, 'Y', 1, '(because register A=0)'),
            Step.assertOutputs([0, 1]),
        ]),
        // test computation instruction and D register write and read
        new SequentialTest([
            Step.set([assembleInstruction('D = 1').toWord(), 0], 'Set register D to 1 and cl to 0.'),
            Step.internalInput(cpuStateNodeType, 'dst', 0b010, ''),
            Step.internalInput(cpuStateNodeType, 'X', 1, ''),
            Step.internalInput(aluNodeType, 'op', 0b111111, ''),
            Step.set([assembleInstruction('D = 1').toWord(), 1], 'Set cl to 1. register D should now emit 1'),
            Step.internalInput(aluNodeType, 'X', 1, '(because register D=1)'),
            Step.internalInput(aluNodeType, 'Y', 0, '(because register A=0)'),
            Step.assertOutputs([0, 0]),
        ]),
        // test computation instruction and *A register write and read
        new SequentialTest([
            Step.set([assembleInstruction('*A = 1').toWord(), 0], 'Set *A to 1 and cl to 0.'),
            Step.internalInput(cpuStateNodeType, 'dst', 0b001, ''),
            Step.internalInput(cpuStateNodeType, 'X', 1, ''),
            Step.internalInput(aluNodeType, 'op', 0b111111, ''),
            Step.set([assembleInstruction('*A = 1').toWord(), 1], 'Set cl to 1. *A should now emit 1'),
            Step.internalInput(aluNodeType, 'X', 0, '(because register D=0)'),

            // copy *A to A
            Step.set([assembleInstruction('A = *A').toWord(), 0], 'Set cl=0 and execute A = *A'),
            Step.internalInput(aluNodeType, 'Y', 1, '(because *A is 1)'),
            // tick
            Step.set([assembleInstruction('A = *A').toWord(), 1], 'Set cl=1'),
            // A should now be 1
            Step.assertOutputs([0, 1]),
        ]),
        // test value instruction and A register write and read
        new SequentialTest([
            Step.set([assembleInstruction('A = 42').toWord(), 0], 'Set cl=0 and execute A = 42'),
            Step.internalInput(cpuStateNodeType, 'dst', 0b100, ''),
            Step.internalInput(cpuStateNodeType, 'X', 42, ''),
            Step.set([assembleInstruction('A = 42').toWord(), 1], 'Set cl=1. Register A should now emit 42'),
            Step.assertOutputs([0, 42])
        ]),
        new SequentialTest([
            Step.set([assembleInstruction('A = 1234').toWord(), 0], 'Set register A to 1234 and cl to 0.'),
            Step.set([assembleInstruction('A = 1234').toWord(), 1], 'Set cl to 1. Register A should now emit 1234'),
            Step.assertOutputs([0, 1234]),
            Step.set([assembleInstruction('D = 1').toWord(), 0], 'Set register D to 1 and cl to 0.'),
            Step.set([assembleInstruction('D = 1').toWord(), 1], 'Set cl to 1. Register D should now emit 1234'),
            Step.set([assembleInstruction('A = D+A').toWord(), 0], 'Set A to A+D and cl to 0. A+D is 1235'),
            Step.set([assembleInstruction('A = D+A').toWord(), 1], 'Set cl to 1. A should now emit 1235'),
        ])
    ],
    score: { min: 6 }
});


export class ExecutionEngineState implements ComponentInternalState {
    decoder = instructionDecoderNodeType;
    alu = aluNodeType;
    cond = conditionNodeType;
    state: CompositeState;
    stateView: StateView;

    constructor(readonly node: ComponentInstanceState) {
        this.state = new CompositeState(node);
        this.stateView = this.state.stateView;
    }
    resolveOutputs(node: ComponentInstanceState) {
        const i = node.inputConnectorStates[0].numState;
        const clock = node.inputConnectorStates[1].bitState;
        return this.resolve(i, clock);
    }
    resolve(i: number, clock: boolean) {
        const [cInstr, addrFlag, op, dest, jmp, n] = this.decoder.rule.resolve1([i]);
        let [a, d, m] = this.state.resolve(false, false, false, 0, clock);
        const y = addrFlag ? m : a;
        const [aluResult] = this.alu.rule.resolve1([op, d, y]);
        const [j] = this.cond.rule.resolve1([jmp, aluResult]);
        const inpToState = cInstr ? aluResult : n;
        const [da, dd, dm] = cInstr ? numberToBoolArray(dest, 3) : [true, false, false];
        [a, d, m] = this.state.resolve(da, dd, dm, inpToState, clock);
        return [j, a];
    }

    reset() {
        this.state.reset();
    }
}

export const controlUnitNodeType = new class extends BaseBuiltinComponentType {
    name = 'control';
    key = 'EXECUTION_ENGINE';
    inputs = [word('I'), bit('cl')];
    outputs = [bit('j'), word('A')];
    hasInternalState = true;
    depends = depends(controlUnitMission);
    displayHint = undefined;
    createInternalState = (node: ComponentInstanceState) =>
        new ExecutionEngineState(node);
};
