import { useState } from 'react';
import { WithSimplePopupWindow } from '../../app/WithPopupWindow';
import './AssemblerHelp.css';

export function AssemblerHelp() {
    const [isVisibleState, setIsVisible] = useState(false);
    function onClose() {
        setIsVisible(false);
    }
    if (!isVisibleState) {
        return (
            <button className="btn btn-secondary btn-sm" onClick={() => setIsVisible(true)}>
                Show Assembler Help
            </button>
        );
    }
    return (
        <>
            <button className="btn btn-secondary btn-sm" onClick={() => setIsVisible(false)}>
                Hide Assembler Help
            </button>
            <WithSimplePopupWindow visible title="Assembler Help" onClose={onClose}>
                <div className="quick-reference">
                    <p>
                        <b>Assembler quick reference</b>
                    </p>

                    <p>
                        Syntax is{' '}
                        <code>
                            <i>destination</i> = <i>calculation</i> ; <i>jump-condition </i>
                        </code>
                        Destination and jump-condition are optional.
                    </p>

                    <p>
                        <code>D</code> and <code>A</code> are the two registers.
                        <code> *A</code> indicate the value in RAM at the address contained in <code>A</code>.
                    </p>

                    <b>Examples:</b>

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <code>D = D + 1</code>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <code>D, A = D - *A</code>
                                </td>
                                <td>Multiple destinations, result of calculation is stored in both registers</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>D - 1 ; JGE</code>
                                </td>
                                <td>No destination - result is not stored but used in jump condition</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>A = 1234</code>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <code># Hello</code>
                                </td>
                                <td>Comment - ignored by assembler</td>
                            </tr>
                        </tbody>
                    </table>
                    <p></p>
                    <b>Calculations</b>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <code>D + A</code>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <code>D - A</code> <i> or </i> <code>A - D</code>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <code>D &amp; A</code>
                                </td>
                                <td>(Bitwise and)</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>D | A</code>
                                </td>
                                <td>(Bitwise or)</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>A+1</code> <i> or </i> <code>D+1</code>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <code>A-1</code> <i> or </i> <code>D-1</code>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <code>-A</code> <i> or </i> <code>-D</code>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <code>~A</code> <i> or </i> <code>~D</code>
                                </td>
                                <td>(Bitwise inversion)</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>1</code> <i> or </i> <code>0</code> <i> or </i> <code>-1</code>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        In all cases can <code>*A</code> be used instead of <code>A</code>.
                    </p>

                    <b>Destinations</b>
                    <p>
                        Any combination of <code>D</code>, <code>A</code> and <code>*A</code> can be used as destination. Multiple
                        destinations are comma separated, e.g. <code>D, A = 1</code>.
                    </p>

                    <b>Jump conditions</b>
                    <table>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Jump when result of calculation is:</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>JEQ</code>
                                </td>
                                <td>equal to 0</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>JNE</code>
                                </td>
                                <td>not equal to 0</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>JGT</code>
                                </td>
                                <td>greater than 0</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>JGE</code>
                                </td>
                                <td>greater than or equal to 0</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>JLT</code>
                                </td>
                                <td>less than 0</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>JLE</code>
                                </td>
                                <td>less than or equal to 0</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>JMP</code>
                                </td>
                                <td>Unconditional jump (regardless of the calculation result.)</td>
                            </tr>
                        </tbody>
                    </table>
                    <p></p>
                    <b>Number instructions</b>
                    <p>
                        <code>A</code> can be directly assigned a number. Example: <code>A = 47</code>.
                        <br />
                        Numbers in hexadecimal is prefixed with <code>0x</code>, e.g: <code>A = 0x7FFF</code>
                        <br />
                        Numbers in binary is prefixed with <code>0b</code>, e.g: <code>A = 0b010111</code>. Underscores can be used to
                        separate digit groups, e.g: <code>A = 0b_0101_1100</code>.
                    </p>

                    <p></p>
                    <b>Comments</b>
                    <p>
                        Lines staring with <code>#</code> are ignored by the assembler. They can be used for comments and documentation.
                    </p>

                    <b>Labels</b>
                    <p>
                        The keyword <code>LABEL</code> followed by a name makes the name represent the address of the following instruction.
                        This address can be assigned to <code>A</code> before a jump, e.g. <code>A = LOOP</code>.
                    </p>
                    <b>Defines</b>
                    <p>
                        The keyword <code>DEFINE</code> followed by a name and a number will cause the name to be replaced with the number when it occurs in other instructions.
                        For example the two lines:
                        
                        <br /><code>DEFINE foo 0x7FFF</code>
                            <br /><code>A = foo</code>
                        
                        <br />Is equivalent to: 
                        <code> A = 0x7FFF</code>
                    </p>
                </div>
            </WithSimplePopupWindow>
        </>
    );
}
