import { Machine } from '../../assembler/machine';
import { AssemblerPersistence } from '../assemblerMissions';
import { VerificationOk } from '../../app/verificationResults';
import { CodeTester } from './codeTester';
import { StackMissionState } from './stackMissionState';
import { MissionKind } from '../../app/task';
import { Repository } from '../../app/repository';
import { MissionProgression } from '../../app/missionProgression';


class PushDTest extends CodeTester {
    setup(machine: Machine) {
        this.setD(machine, 42);
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 1);
        if (err) {
            return err;
        }
        err = this.expectStacTop(machine, 42);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export class PushDMissionState extends StackMissionState {
    tests = [PushDTest];
}


export const pushDMission = {
    key: 'STACK_PUSH_D',
    kind: MissionKind.Stack,
    macro: { name: 'PUSH_D' },
    start(repository: Repository, game: MissionProgression) {
        return new PushDMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PushDMissionState(this, repository, game, data);
    },
};
