import { useState, useContext } from 'react';
import './WireComponent.css';
import { deleteItem } from '../../../common/utilities';
import { Waypoint } from '../../circuitStructure';
import { CanvasContext } from '../builder/DiagramBuilder';
import { InputConnectorLocation } from '../connector/InputConnectorComponent';
import { OutputConnectorLocation } from '../connector/OutputConnectorComponent';
import { DragDropProvider, DragEventHandler, EventPoint } from '../drag.service';
import { Wire } from './segments';

export function WireComponent(props: {
		inputConnectorComponent: InputConnectorLocation,
        outputConnectorComponent: OutputConnectorLocation
    }) { 

    const wire = new Wire();
	const [state, setState] = useState(()=>{
		return { wireHover: false }; 
	});
    const dragService = useContext(DragDropProvider);
    const canvasService = useContext(CanvasContext);
    const outputConnectorComponent = props.outputConnectorComponent;
    if (!outputConnectorComponent) {
        console.log('Wire output connector missing');
        return null;
    }
    const connection =  props.inputConnectorComponent.connection!;

    /* disable mouse events when a node or wire is dragged */
    const isNodeDragMode = dragService.draggingKind === 'node' ||
            dragService.draggingKind === 'waypoint';

    // used when connected:
    const wireState =  props.inputConnectorComponent.connector.state;
    const isWord =  props.inputConnectorComponent.connector.width === 16;
    const isBit =  props.inputConnectorComponent.connector.width === 1;
    const oscillating =  props.inputConnectorComponent.connector.oscillating;

    const hasConnection =  props.inputConnectorComponent && connection;
    if (!hasConnection) {
        return null;
    }
    const waypoints =  hasConnection ? connection.waypoints : [];
    wire.updateSegments(
        connection,
        props.inputConnectorComponent,
        outputConnectorComponent);
    const segments = wire.segments;

    function wireMouseEnter(_event: React.MouseEvent) {
        setState({wireHover: true});
    }
    function wireMouseLeave(_event: React.MouseEvent) {
        setState({wireHover: false});
    }

    /* dragging a wire - creates a waypoint */

    function wireMouseDown(event: React.MouseEvent) {
        lineDragStart(event, event.nativeEvent);
    }
    function wireTouchStart(event: React.TouchEvent) {
        if (event.touches.length === 1) {
            const touch = event.touches.item(0)!;
            lineDragStart(touch, event.nativeEvent);
        }
    }
    function lineDragStart(eventPoint: EventPoint, event: Event) {
        let wayPoint: Waypoint;

        const pathSegment = event.srcElement as SVGPathElement;
        const segmentIx = parseInt(pathSegment.getAttribute('data-segmentix')!, 10);

        /* mouse position on canvas */
        const startPos = canvasService.getRelativePos2(eventPoint);

        const handler: DragEventHandler = {
            onDragStart: (deltaX, deltaY) => {
                /* insert waypoint  */
                const pos = startPos.add(deltaX, deltaY);
                wayPoint = connection.addWaypoint(segmentIx, pos);
            },
            onDragMove: (deltaX, deltaY) => {
                const pos = startPos.add(deltaX, deltaY);
                wayPoint.x = pos.x;
                wayPoint.y = pos.y;
                setState({...state});
            },
            onDrop: (targetElement) => {
                // dragged over trash - remove the newly created waypoint again
                if (targetElement.classList.contains('trash-area')) {
                    // remove waypoint
                    deleteItem(waypoints, wayPoint);
                }
            },
            hasDropTarget(elem) {
                if (elem.classList.contains('node-droptarget')) {
                    return elem;
                }
                return null;
            },
            onDragEnd: () => {
                setState({wireHover: false});
                connection.sourceConnector.diagram.notifyLayoutChanged();
            }
        };
        dragService.startDrag('waypoint', eventPoint, event, handler);
    }
    function waypointTouchStart(event: React.TouchEvent) {
        if (event.touches.length === 1) {
            const touch = event.touches.item(0)!;
            waypointDragStart(touch, event.nativeEvent);
            event.stopPropagation();
        }
    }
    function waypointMouseDown(event: React.MouseEvent) {
        waypointDragStart(event, event.nativeEvent);
        event.stopPropagation();
    }
    function waypointDragStart(eventPoint: EventPoint, event: Event) {
        const pathSegment = event.srcElement as SVGPathElement;
        const segmentIx = parseInt(pathSegment.getAttribute('data-segmentix')!, 10);
        const wayPoint = waypoints[segmentIx]!;

        /* mouse position on canvas */
        const startPos = canvasService.getRelativePos2(eventPoint);

        const handler: DragEventHandler = {
            onDragMove: (deltaX, deltaY) => {
                const pos = startPos.add(deltaX, deltaY);
                wayPoint.x = pos.x;
                wayPoint.y = pos.y;
                setState({...state});
            },
            onDragEnd: () => {
                setState({wireHover: false});
                connection.sourceConnector.diagram.notifyLayoutChanged();
            },
            onDrop: (targetElement) => {
                // dropped on trashcan
                if (targetElement.classList.contains('trash-area')) {
                    // remove waypoint
                    deleteItem(waypoints, wayPoint);
                }
            },
            hasDropTarget(elem) {
                if (elem.classList.contains('node-droptarget')) {
                    return elem;
                }
                return null;
            },
        };
        dragService.startDrag('waypoint', eventPoint, event, handler);
    }
	const wireHover = state.wireHover;

    function keyvalue<T>(array: T[]) {
        return array.map((item, ix) => ({key: ix, value:item}));
    }

	return (
<g className={'' + (isNodeDragMode ? ' node-drag-mode' : '')}> 
    {(keyvalue(segments ?? [])).map(segment => (
        <path key={segment.key} d={segment.value.path} data-segmentix={segment.key} 
            onMouseDown={wireMouseDown} 
            onMouseEnter={wireMouseEnter} 
            onMouseLeave={wireMouseLeave} 
            onTouchStart={wireTouchStart} 
            className={'connection-line wire' + (wireState ? ' on' : '') + (wireHover ? ' wire-hover' : '') + (isWord ? ' word' : '') + (isBit ? ' bit' : '') + (oscillating ? ' oscillating' : '')} />
    ))} 
    {(keyvalue(waypoints)).map(waypoint => (
        <circle key={waypoint.key} r='4' data-segmentix={waypoint.key} cx={waypoint.value.x} cy={waypoint.value.y} 
            onMouseDown={waypointMouseDown} 
            onTouchStart={waypointTouchStart} 
            className='waypoint'></circle>
    ))} 
</g>); 
}

