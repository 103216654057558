import { tokenize } from '../../compiler/tokenize';
import { UnitVerificationResultSet, VerificationError, VerificationOk } from '../../app/verificationResults';
import { MissionState, Task } from '../../app/task';
import { Repository } from '../../app/repository';
import { MissionProgression } from '../../app/missionProgression';

/* 
    For compiler-state missions, 
    only the source code (used for testing) is persisted pr mission
    The actual configuration for tokenizer/parser/codegen is persisted globally
*/
export type TokenizerLevelPersistence = { source: string };

export type TokenizerTest = { text: string; expected: string[] };

export abstract class TokenizeMissionState implements MissionState {
    source;
    readonly initialSource: string = '2 + 2';
    isCompleted = false;
    abstract tests: TokenizerTest[];
    constructor(
        public readonly mission: Task,
        private readonly repository: Repository,
        protected readonly game: MissionProgression,
        data?: TokenizerLevelPersistence
    ) {
        if (data) {
            this.source = data.source;
        } else {
            this.source = this.initialSource;
        }
    }
    get compilerState() {
        return this.game.sharedCompiler;
    }
    verify1(tokenizerTests: TokenizerTest[]) {
        function error(msg: string) {
            return new VerificationError(msg);
        }
        for (const { text, expected } of tokenizerTests) {
            const actual = tokenize(text, this.compilerState.lexical);
            if (actual.isError) {
                const errorMessage = actual.error?.message ?? '';
                return error(`Attempted to tokenize input '${text}'. Error: ${errorMessage} `);
            }
            if (actual.tokens.length !== expected.length) {
                return error(`Tokenized input '${text}'. Expected ${expected.length} tokens but got ${actual.tokens.length}`);
            }
            for (let i = 0; i < expected.length; i++) {
                const expectedToken = expected[i];
                const actualToken = actual.tokens[i];
                if (expectedToken !== actualToken.type) {
                    return error(
                        `Tokenized input '${text}'. Token ${i + 1} has unexpected type. Expected '${expectedToken}' but was '${
                            actualToken.type
                        }' '`
                    );
                }
            }
        }
        return new VerificationOk();
    }
    verify() {
        const result1 = this.verify1(this.tests);
        const result = new UnitVerificationResultSet(result1);
        this.isCompleted = result.succeeded;
        return result;
    }
    hasState = false;
    resetState(): void {
        // TODO
    }
    save(): void {
        this.compilerState.save();
        this.repository.saveLevel(this.mission.key, { source: this.source });
    }
}
