import { AInstruction, Instruction } from './instructions';
import { NumericToken, Token } from './scanner';

// Represent a line of assembler source
export abstract class AsmSyntax {
    isValid = true;
    errorText: string | undefined;
    abstract lineType: string;
    abstract line: string;

    constructor(public readonly tokens: Token[]) {
        if (tokens.find(t => !t.isValid)) {
            this.setError('Syntax error');
        }
    }
    get lineOffset() {
        return this.tokens[0].location.lineOffset;
    }
    get lineNumber() {
        return this.tokens[0].location.lineNumber;
    }
    setError(msg: string) {
        this.errorText = msg;
        this.isValid = false;
    }
}

// An instruction occupies an address
// (as opposed to comments, lables etc. which are only assembler-level)
/* Need to be an abstract class since we check for instances at runtime */
export abstract class AsmInstructionSyntax extends AsmSyntax {
    address: number | undefined;
    abstract instruction: Instruction;
}

export class UnknownAsm extends AsmSyntax {
    readonly lineType = 'unknown';
    readonly content: string;
    constructor(readonly line: string, tokens: Token[], errorText: string) {
        super(tokens);
        this.setError(errorText);
        this.content = line;
    }
}

export class BlankLineAsm extends AsmSyntax {
    readonly lineType = 'blank';
    constructor(readonly line: string, tokens: Token[]) {
        super(tokens);
    }
}

export class AsmComment extends AsmSyntax {
    readonly lineType = 'comment';
    readonly content: string;
    constructor(readonly line: string, tokens: Token[]) {
        super(tokens);
        this.content = line;
    }
}

export class AsmComputation extends AsmInstructionSyntax {
    readonly lineType = 'computation';
    constructor(readonly line: string, tokens: Token[], public instruction: Instruction) {
        super(tokens);
    }
}

/* Identfier is either a label or a macro
 *
 * The labelReference is assigned in a second pass (since it could be defined)
 * and the reference is resolved to an address in a third pass
 */
export class AsmLoadLabelInstruction extends AsmInstructionSyntax {
    readonly lineType = 'load';
    labelReference: undefined | AsmLabel;
    instruction: Instruction = new AInstruction(0);
    readonly identifier: string;
    constructor(
        readonly line: string, 
        readonly identifierToken: Token, 
        tokens: Token[]
    ) {
        super(tokens);
        this.identifier = identifierToken.value;
    }
    resolveReference() {
        if (this.labelReference && this.labelReference.labelAddress !== undefined) {
            const lblAddr = this.labelReference.labelAddress;
            this.instruction = new AInstruction(lblAddr);
        } else {
            this.setError(`label '${this.identifier}' not found`);
        }
    }
    get referencedAddress() {
        return this.labelReference ? this.labelReference.labelAddress : undefined;
    }
    setIdentifierError(msg: string) {
        this.setError(msg);
        // mark identifier token as error
        this.identifierToken.isValid = false;
    }
}

/* Loads a definition or placeholder
    (A = <foo>)
 */
export class AsmLoadDefinitionInstruction extends AsmInstructionSyntax {
    readonly lineType = 'load';
    readonly identifier;
    readonly instruction;
    constructor(readonly line: string, readonly identifierToken: Token, readonly expansion: number, tokens: Token[]) {
        super(tokens);
        this.identifier = identifierToken.value;
        this.instruction = new AInstruction(expansion);
    }
}

export class AsmLabel extends AsmSyntax {
    readonly lineType = 'label';
    readonly identifier;
    labelAddress: number | undefined;
    constructor(readonly line: string, readonly identifierToken: Token, public tokens: Token[]) {
        super(tokens);
        this.identifier = identifierToken.value;
    }
    error(msg: string) {
        this.setError(msg);
        // mark identifier token as error
        this.identifierToken.isValid = false;
    }
}


export class AsmDefine extends AsmSyntax {
    readonly lineType = 'define';
    readonly identifier;
    readonly value;
    labelAddress: number | undefined;
    constructor(readonly line: string, readonly identifierToken: Token, readonly valueToken: NumericToken, public tokens: Token[]) {
        super(tokens);
        this.identifier = identifierToken.value;
        this.value = valueToken.value;
    }
    error(msg: string) {
        this.setError(msg);
        // mark identifier token as error
        this.identifierToken.isValid = false;
    }
}

export class AsmMacroInvocation extends AsmSyntax {
    readonly lineType = 'macroinv';
    // label definition in the inner scope
    definitions?: LabelMap;

    constructor(
        readonly line: string,
        readonly macroName: string,
        tokens: Token[],
        readonly expansion: AsmSyntax[],
        readonly labelArguments: {placeholder: string; token: Token}[]
    ) {
        super(tokens);
    }
}

export class LabelMap {
    labels: Map<string, AsmLabel> = new Map();
}
