import { getNandCount, CountError, CountNumber } from './componentCounter';
import { DiagramMissionsSet } from '../app/missionProgression';
import { DiagramMissionState } from './diagramMissionState';
import { DiagramType } from './diagramMissionType';


/*
 * Text describing score and gate count for a completed level
 */
export function getScoreText(mission: DiagramMissionState, h: DiagramMissionsSet) {
    // component count
    const gateCount = mission.diagram.nodes.filter(n => n.nodeType.countNode).length;
    const zeroCostNodeTypesUsed = new Set(mission.diagram.nodes
        .map(nts => nts.nodeType)
        .filter(nt => !nt.countNode)
        .map(nt => nt.name));
    let gateCountText = `${gateCount} components used. `;
    if (zeroCostNodeTypesUsed.size) {
        const names = Array.from(zeroCostNodeTypesUsed).map(name => `${name}`).join(' and ');
        gateCountText += `(Not counting ${names} which does not contain any logic.)`;
    }

    if (mission.missionType.diagramType === DiagramType.TransistorLevel) {
        // Simpler message since there is no nand-count at transistor level
        const analysis = getAnalysis(mission, gateCount, null);
        return `<p>${gateCountText}</p><p>${analysis}</p>`;
    }

    // Get nand count and text
    let nandCountText = '';
    let nandCount: number|null = null;
    const count = getNandCount(mission, h);
    if (count instanceof CountError) {
        nandCountText = count.message;
        nandCount = null;
    } else if (count instanceof CountNumber) {
        nandCountText = `${count.count} nand gates in total. ` + count.text;
        nandCount = count.count;
    }
    const analysis = getAnalysis(mission, gateCount, nandCount);
    return `<p>${gateCountText} ${nandCountText}</p><p>${analysis}</p>`;
}

function getAnalysis(mission: DiagramMissionState, componentCount: number, nandCount: number | null) {
    const levelHighScore = mission.missionType.score;
    if (!levelHighScore) {
        console.log(`high score not found for: ${mission.missionType.key} `);
        return '';
    }
    const minComponents = levelHighScore.min;
    const minNand = levelHighScore.nands;
    if (!nandCount || minNand === undefined) {
        // Total nand could not be counted, or minimal nand count is not specified
        // so we only count components
        if (componentCount <= minComponents) {
            return `This is the simplest possible solution!`;
        } else {
            return `(But it is possible to solve using fewer components.)`;
        }
    } else {
        // nand is countable
        if (nandCount <= minNand) {
            return `This is optimal!`;
        } else {
            // not optimal nand count
            if (componentCount <= minComponents) {
                return `This uses the fewest possible components. (But it is possible to solve with a lower total of nand-gates.) `;
            } else {
                return `(But it is possible to solve using fewer components.)`;
            }
        }
    }
}
