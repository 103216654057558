import { ComponentType } from './componentType';
import { ComponentInstanceState } from './circuitState';
import { VerificationResultSet, VerificationResult, VerificationOk, VerificationError } from '../app/verificationResults';
import { ConnectorState } from './connectorState';


class Comparison {
    constructor(readonly expected: number, readonly actual: ConnectorState) { }
    get isSame() { return this.expected === this.actual; }
    get actualText() { return this.actual == null ? '?' : this.actual.toString(); }
}

class StatelessVerificationResult implements VerificationResult {
    succeeded: boolean;
    constructor(readonly inputs: readonly number[], readonly outputs: readonly Comparison[]) {
        this.succeeded = outputs.every(o => o.isSame);
    }
}


/* Verification result containing multiple results */
export class DiagramVerificationResultSet implements VerificationResultSet {
    readonly inputLabels: readonly string[];
    readonly outputLabels: readonly string[];
    readonly ioResults: StatelessVerificationResult[] = [];
    readonly results: VerificationResult[] = [];
    constructor(adapter: VerificationSubjectAdapter) {
        [this.inputLabels, this.outputLabels] = adapter.getConnectorLabels();
    }
    add(result: VerificationResult) {
        if (result instanceof StatelessVerificationResult) {
            this.ioResults.push(result);
        } else {
            this.results.push(result);
        }
    }
    get succeeded() {
        return this.ioResults.every(t => t.succeeded)
            && this.results.every(t => t.succeeded);
    }
    get firstError() {
        return this.results.find(r => !r.succeeded) as VerificationError;
    }
}


// Can wrap a diagram or a node
export interface VerificationSubjectAdapter {
    setInputs(input: readonly number[]): void;
    resetState(): void;
    getOutputs(): readonly ConnectorState[];
    getOutput(label: string): ConnectorState;
    findInternalNode(type: ComponentType): ComponentInstanceState | undefined;
    findInternalNodes(type: ComponentType): readonly ComponentInstanceState[];
    getConnectorLabels(): readonly [string[], string[]];
}

export interface TestCase {
    verify(adapter: VerificationSubjectAdapter): VerificationResult;
}

export abstract class BaseTestCase implements TestCase {
    assertOutputs(adapter: VerificationSubjectAdapter, inputs: readonly number[], expected: readonly number[]) {
        adapter.setInputs(inputs);
        const actuals = adapter.getOutputs();
        if (expected.length !== actuals.length) {
            throw new Error('Output array size mismatch');
        }
        const outputs: Comparison[] = [];
        for (let ix = 0; ix < expected.length; ix++) {
            const expectedState = expected[ix];
            const actual = actuals[ix];
            outputs.push(new Comparison(expectedState, actual));
        }
        return new StatelessVerificationResult(inputs, outputs);
    }
    abstract verify(diagram: VerificationSubjectAdapter): VerificationResult;
}

export class IOTestCase extends BaseTestCase {
    constructor(readonly input: readonly number[], readonly expected: readonly number[]) { super(); }
    verify(adapter: VerificationSubjectAdapter): VerificationResult  {
        return this.assertOutputs(adapter, this.input, this.expected);
    }
}

/* Check that a given component exist in the diagram */
export class RequiredComponentTestCase implements TestCase {
    constructor(private readonly nodeType: ComponentType,
        private readonly componentText: string) {
    }
    verify(adapter: VerificationSubjectAdapter): VerificationResult {
        const nodes = adapter.findInternalNodes(this.nodeType);
        if (nodes.length === 0) {
            return new VerificationError(`A ${this.componentText} is required in the solution.`);
        }
        if (nodes.length > 1) {
            return new VerificationError(`Only one ${this.componentText} is allowed in the solution.`);
        }
        return new VerificationOk();
    }
}

export const binaryTest = (input: boolean[], expected: boolean[]) =>
    new IOTestCase(input.map(b => b ? 1 : 0), expected.map(b => b ? 1 : 0));

export const numericTest = (input: number[], expected: number[]) =>
    new IOTestCase(input, expected);

export abstract class TestWrapper implements TestCase {
    constructor(readonly test: TestCase) { }
    verify(adapter: VerificationSubjectAdapter) {
        this.setup(adapter);
        try {
            return this.test.verify(adapter);
        } finally {
            this.restore(adapter);
        }
    }
    abstract setup(adapter: VerificationSubjectAdapter): void;
    abstract restore(adapter: VerificationSubjectAdapter): void;
}
