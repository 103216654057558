import { diagram } from './missions';
import { nandNodeType, invNodeType, selector16NodeType } from '../missions/logicMissions';
import { incNodeType, zeroNodeType } from '../missions/arithmeticMissions';
import { SequentialTest, Step } from '../sequentialTest';
import { word, bit } from '../pins';
import { ComponentInstanceState } from '../circuitState';
import { dff16NodeType, RegisterState } from './registerMission';
import * as Word16 from '../../common/arithmetics';
import { ComponentInternalState } from '../componentType';
import { BaseBuiltinComponentType } from './baseNodeType';
import { depends } from './dependency';


export const counterMission = diagram(<const>{
    key: 'COUNTER',
    inputPins: [bit('st'), word('X'), bit('cl')],
    outputPins: [word('')],
    palette: [nandNodeType, invNodeType, dff16NodeType, incNodeType, selector16NodeType, zeroNodeType],
    tests: [
        // store, X, clock
        new SequentialTest([
            Step.set([1, 7, 0],
                'Set <b>st</b> to 1, <b>X</b> to 7 and <b>cl</b> to 0. This should set <b>in</b> to 7'),
            Step.set([1, 7, 1],
                'Change <b>cl</b> to 1 (clock tick). Should set <b>out</b> to <b>in</b>. Output should be <b>out</b>'),
            Step.assertOutput('', 7),
            Step.set([0, 7, 1], 'Set <b>st</b> to 0'),
            Step.set([0, 7, 0], 'Set <b>cl</b> to 0'),
            Step.set([0, 7, 1], 'Set <b>cl</b> to 1'),
            Step.assertOutput('', 8),
        ]),
        new SequentialTest([
            Step.set([0, 0, 0],
                'Set <b>st</b> to 0 and <b>cl</b> to 0. We expect <b>out</b> to be 0 and <b>in</b> to be <b>out</b> + 1, which should be 1. Output should be <b>out</b>'),
            Step.assertOutput('', 0),
            Step.set([0, 0, 1],
                'Change <b>cl</b> to 1. Should set <b>out</b> to <b>in</b>. Output should be <b>out</b>'),
            Step.assertOutput('', 1),
            Step.set([0, 0, 0],
                'Change <b>cl</b> back to 0. We expect <b>in</b> to be <b>out</b> + 1 which should be 2'),
            Step.set([0, 0, 1],
                'Change <b>cl</b> to 1. Should set <b>out</b> to <b>in</b>. Output should be <b>out</b>'),
            Step.assertOutput('', 2)
        ]),
        /*
        // store, data, clock
        // store number
        numericTest([1, 17, 0], [0]),
        // emit number 17
        numericTest([1, 17, 1], [17]),
        // no write - (should increment internally)
        numericTest([0, 17, 0], [17]),
        // emit the incremented value
        numericTest([0, 17, 1], [18]),
        numericTest([0, 17, 0], [18]),
        numericTest([0, 17, 1], [19]),
        // reset to a new number
        numericTest([1, 126, 0], [19]),
        numericTest([1, 126, 1], [126]),
        // data is ignored when st=0
        numericTest([0, 12, 1], [126]),
        numericTest([0, 13, 0], [126]),
        numericTest([0, 14, 1], [127]),
        */
    ],
    score: { min: 4 },
});

class CounterState implements ComponentInternalState {
    readonly register = new RegisterState();
    readonly stateView = this.register.stateView;
    resolveOutputs(node: ComponentInstanceState) {
        const st = node.inputConnectorStates[0].bitState;
        const data = node.inputConnectorStates[1].numState;
        const clock = node.inputConnectorStates[2].bitState;
        return this.resolve(st, data, clock);
    }
    resolve(st: boolean, data: number, clock: boolean) {
        if (!st) {
            data = Word16.add(this.register.oldState, 1);
            st = true;
        }
        return this.register.resolve(st, data, clock);
    }

    reset() {
        this.register.reset();
    }
}

export const counterNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'counter';
    readonly key = 'COUNTER';
    readonly inputs = [bit('st'), word('X'), bit('cl')];
    readonly outputs = [word()];
    readonly hasInternalState = true;
    readonly depends = depends(counterMission);
    readonly createInternalState = () => new CounterState();
};

