import './edge-group.css';
import { InputNodeComponent } from './InputNodeComponent';
import { InputConnectorState, InputStateGroup } from '../../circuitState';
import { ComponentBuilder } from '../../componentBuilder';
import { DiagramActions } from '../builder/DiagramBuilder';

export function InputGroupComponent(props: {
        isPalette: boolean;
        isFree: boolean;
		group: InputStateGroup;
		model: ComponentBuilder;
        selectedConnector: InputConnectorState | undefined;
        diagramActions: DiagramActions;
    }) { 

    const inputNodes =  props.group.nodes;
    const multi =  props.group.nodes.length > 1;
    const hasLabel =  props.group.label !== undefined;
    const label =  props.group.label;
	const model = props.model;

	return (<div className={'diagram-input-group' + (multi ? ' multi' : '')}>
    <div className='component-row'>
        { hasLabel && (
            <div className='group-label'>{label}</div>)} 
        {(inputNodes).map((connector, ix) => (
            <InputNodeComponent key={ix} connector={connector} model={model} isPalette={props.isPalette} isFree={props.isFree}
            selectedConnector={props.selectedConnector}
            diagramActions={props.diagramActions}
             />))} 
    </div>
</div>); 
}

