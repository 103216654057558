import { ComponentCounter, MissionDependency } from '../componentType';
import { DiagramMissionType } from '../diagramMissionType';


class Dependency implements MissionDependency {
    /** Whether the component counts against 'components used' */
    get countNode() { return this.factor > 0; }
    /* By default, a node can be expanded to the corresponding diagram */
    constructor(
        public readonly mission: DiagramMissionType,
        public readonly factor: number = 1,
        public readonly canExpand = true) { }
    componentCount(counter: ComponentCounter) {
        if (this.factor === 0) {
            return counter.empty();
        }
        return counter.countForMission(this.mission).mul(this.factor);
    }
}

export const depends = (mission: DiagramMissionType, factor = 1, canExpand = true): MissionDependency =>
    new Dependency(mission, factor, canExpand);

/* A node which does not depend on any missions */
class NoDependency implements MissionDependency {
    countNode = true;
    canExpand = false;
    constructor(readonly count: number) {}
    componentCount = (count: ComponentCounter) => count.fresh(this.count);
}

/** A component no dependencies and a cost of 1 nandgate */
export const atomic = () => new NoDependency(1);


class NoCostNoDependency implements MissionDependency {
    countNode = false;
    canExpand = false;
    componentCount = (counter: ComponentCounter) => counter.empty();
}

/** A component with no dependencies and no cost */
export const transparent = () => new NoCostNoDependency();
