import './Verification.css';
import { DiagramVerificationResultSet } from '../../diagram/verification';
import { VerificationResultSet, UnitVerificationResultSet, VerificationError, CompositeVerificationResultSet } from '../verificationResults';

export function VerificationTable(props: {
		results: DiagramVerificationResultSet}) { 

    const table =  props.results;
    let resultList;
    // TODO: create something like takeWhile
    // we take all test up to and including the first error
    const firstErr = table.ioResults.findIndex(r => !r.succeeded);
    if (firstErr === -1) {
        // no errors found.
        // Only show some of the tests
        resultList = table.ioResults.slice(0, 10);
    } else {
        resultList = table.ioResults.slice(0, firstErr + 1);
    }
    // If there is only one input and one output, we don't show the additional row with connector labels.
    const showLabels =  table.inputLabels.length + table.outputLabels.length > 2;

	return (
        <table className='test-results'>
            <colgroup className='input'>
                {(table.inputLabels).map((o, ix) => <col key={ix} />)} 
            </colgroup>
            <colgroup className='output'>
                {(table.outputLabels).map((o, ix) => <col key={ix} />)} 
            </colgroup>
            <thead>
                <tr>
                    <th colSpan={table.inputLabels.length} className='input'>Input</th>
                    <th colSpan={table.outputLabels.length} className='output'>Output</th>
                </tr>
                { showLabels && (
                    <tr>
                    {(table.inputLabels).map(label => <th key={label}>{label}</th>)} 
                    {(table.outputLabels).map(label => <th key={label}>{label}</th>)} 
                    <th></th>
                </tr>)} 
            </thead>
            <tbody>
                {(resultList).map((result, ix) => (
                    <>
                    { result.succeeded && (
                        <tr className='ok'>
                            {(result.inputs).map((val, ix) => <td key={ix}>{val}</td>)} 
                            {(result.outputs).map((comp, ix) => <td key={ix}>{comp.actualText}</td>)} 
                            <td>✔</td>
                        </tr>
                    )} 
                    { !result.succeeded && (
                        <>
                        <tr className='error'>
                            {(result.inputs).map(val => (
                                <td>{val}</td>))} 
                            {(result.outputs).map((comp, ix) => (
                                <td key={ix}>
                                { comp.isSame && (<>{comp.actual}</>)} 
                                { !comp.isSame && (
                                    <b style={{color: 'red'}}>{comp.actualText}</b> )} 
                                </td>))} 
                            <td>✗</td>
                        </tr>
                        <tr className='expected'>
                            <th colSpan={table.inputLabels.length} style={{textAlign: 'left', fontStyle: 'italic'}}>Expected:</th>
                            {result.outputs.map((comp, ix) => (
                                <td key={ix}>
                                { comp.isSame && (<>{comp.expected}</>) } 
                                { !comp.isSame && <b>{comp.expected}</b> } 
                            </td>))} 
                            <td></td>
                        </tr>
                    </>)} 
                </>))} 
            </tbody>
        </table>); 
}


export function Verification(props: {results: VerificationResultSet}) { 
    let statefulResults;
    /* UnitVerificationResultSet has a single result and CompositeVerificationResultSet has multiple */
    if (props.results instanceof UnitVerificationResultSet) {
        statefulResults = [props.results.result];
    } else if (props.results instanceof DiagramVerificationResultSet) {
        statefulResults = props.results.results;
    } else if (props.results instanceof CompositeVerificationResultSet) {
        statefulResults = props.results.results;
    } else {
        console.error(props.results)
        throw new Error();
    }
    const statefulError =  statefulResults.find(t => !t.succeeded);
    const showStatelessErrors =  statefulError === undefined &&
            props.results instanceof DiagramVerificationResultSet &&
            props.results.ioResults.length > 0;
	const results = props.results;

	return (<>
{ showStatelessErrors && results instanceof DiagramVerificationResultSet && (
    <VerificationTable results={results} />)} 
{ statefulError && statefulError instanceof VerificationError  && (
    <div dangerouslySetInnerHTML={{__html: statefulError.errorMessage}}></div>)} 
</>); 
}

