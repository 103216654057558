import { DiagramMission } from '../diagram/diagramMission';
import { DiagramMissionType } from '../diagram/diagramMissionType';
import { cmosInvMission, cmosNandMission, cmosNorMission } from '../diagram/optionalMissions/transistorLevel';
import { barrelShlMission, maxMission, mulMission, shlMission, xnorMission } from '../diagram/optionalMissions/optionalMissions';
import { Task } from './task';
import { MissionEpic } from '../missions/track';
import { floatAlignMission, floatMultiplyPackedMission, floatNormalizeUnderflowMission, floatUnpackMission, floatNormalizeOverflowMission, floatPackMission, floatMultiplyUnpackedMission, floatVerifyExponentMission, floatAddMission, floatAddSignedMagnitudeMission } from '../diagram/optionalMissions/floatMission';
import { interruptProgramCounterMission, interruptTriggerMission, memoryProtectionMission, swapMission } from '../diagram/optionalMissions/interruptMission';

export const diagramMission = (m: DiagramMissionType): Task => new DiagramMission(m);

export const optionalMissionsCategory: MissionEpic =
{
    name: 'Optional Levels',
    groups: [
        {
            name: 'Transistor level',
            missions: [
                diagramMission(cmosNandMission),
                diagramMission(cmosInvMission),
                diagramMission(cmosNorMission),
            ]
        },
        {
            name: 'Logic',
            missions: [
                // diagramMission(norMission),
                diagramMission(xnorMission),
                diagramMission(shlMission),
                diagramMission(barrelShlMission),
            ]
        },
        {
            name: 'Arithmetics',
            missions: [
                diagramMission(maxMission),
                diagramMission(mulMission)
            ]
        },
        {
            name: 'Floating point',
            missions: [
                diagramMission(floatUnpackMission),
                diagramMission(floatMultiplyUnpackedMission),
                diagramMission(floatNormalizeOverflowMission),
                diagramMission(floatVerifyExponentMission),
                diagramMission(floatAlignMission),
                diagramMission(floatAddSignedMagnitudeMission),
                diagramMission(floatNormalizeUnderflowMission),
                diagramMission(floatPackMission),
                diagramMission(floatMultiplyPackedMission),
                diagramMission(floatAddMission),
            ]
        },
        /*
        {
            name: 'Interrupts',
            missions: [
                diagramMission(swapMission),
                diagramMission(interruptTriggerMission),
                diagramMission(interruptProgramCounterMission),
                diagramMission(memoryProtectionMission),
            ]
        }
        */
        /*
        {
            name: 'Networking',
            missions: [
                diagramMission(syncNetwork)
            ]
        }*/
    ]
};
