import { createPortal } from "react-dom";

/* Renders content into the canvas overlay layer */
export function CanvasOverlay(props: {children: React.ReactNode}) {
    const domNode = document.getElementById('tool-overlays')!;
    if (domNode) {
        return createPortal(props.children, domNode);
    } else {
        return null;
    }
}