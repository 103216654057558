import { MissionProgression } from '../../app/missionProgression';
import { Repository } from '../../app/repository';
import { MissionKind } from '../../app/task';
import { expressionTests } from '../grammarTests';
import { TokenizerLevelPersistence, TokenizeMissionState } from './tokenizeMissionState';


class TokenizeExpressionState extends TokenizeMissionState {
    readonly initialSource = '2 + 2';
    tests = expressionTests;
}

export const tokenizeExpressionMission = {
    key: 'TOKENIZE_EXPRESSION',
    kind: MissionKind.Tokenize,
    start(repository: Repository, game: MissionProgression) {
        return new TokenizeExpressionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as TokenizerLevelPersistence;
        return new TokenizeExpressionState(this, repository, game, data);
    },
};
