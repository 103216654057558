import { UiText } from "../../locale/UiText";
import { WithPopupWindow } from "../WithPopupWindow";

export function PopupMessage(props: {html: string; close: ()=>void}) {
    return (       
        <WithPopupWindow visible onClose={props.close}>      
        <div className='popup-message'>
            <div className='content'>
                <div dangerouslySetInnerHTML={{__html: props.html}}></div>
            </div>

            <div className='buttons'>
                <button onClick={props.close} className='btn btn-primary'><UiText id='btn_ok' /></button>
            </div>
        </div>
        </WithPopupWindow>);
    }