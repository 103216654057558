import React from 'react';
import { Help } from './Help';
import { ErrorDisplay } from './ErrorDisplay';
import { PatternType, TokenAction, TokenSet } from './tokenize';
import { CloseButton, OverlayTrigger, Popover } from 'react-bootstrap';
import './LexerRules.css';


function HelpPopup() {
    const [visible, setVisible] = React.useState(false);
    return (
    <OverlayTrigger
        show={visible}
        onToggle={()=>setVisible(!visible)}
        trigger="click"
        placement='right'
        overlay={
            <Popover id={`popover-positioned-right`} >
            <Popover.Body>
                <CloseButton onClick={() => setVisible(false)} />

                <p>Patterns specify a sequence of characters to match.</p>
                <p>Brackets indicate a set of of posible characters, e.g. <code>[0-9]</code> match any digit <code>[a-zA-Z]</code> match any english letter.</p>
                <p>A trailing + means the pattern matches one or more times, e.g. <code>[0-9]+</code> will match a number with one or more digits </p>
                <p>The special code <code>\r\n</code> represent a line break.</p>
            </Popover.Body>
            </Popover>
        }
        >
        <i className="bi bi-question-circle"></i>
    </OverlayTrigger>)
}

function LexerRule(props: {
    token: TokenSpecification;
    onTokenDelete: (id: number) => void;
    onTokenChange: (value: TokenSpecification) => void;
}) {
    const token = props.token;
    function handlePatternChange(event: React.ChangeEvent<HTMLInputElement>) {
        token.pattern = event.target.value;
        props.onTokenChange(token);
    }
    function handleActionChange(event: React.ChangeEvent<HTMLSelectElement>) {
        token.action = parseInt(event.target.value);
        props.onTokenChange(token);
    }
    function handlePatternTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        token.type = parseInt(event.target.value);
        props.onTokenChange(token);
    }
    function handleLabelChange(event: React.ChangeEvent<HTMLInputElement>) {
        token.label = event.target.value;
        props.onTokenChange(token);
    }
    const name =
        token.action === TokenAction.Name ? (
            <input type="text" value={token.label} onChange={handleLabelChange} className="form-control" />
        ) : (
            ''
        );
    return (
        <tr>
            <td>
                <select value={token.type} onChange={handlePatternTypeChange} className="form-select">
                    <option value={PatternType.Exact}>Exact</option>
                    <option value={PatternType.Pattern}>Pattern</option>
                </select>
            </td>
            <td>
                <input
                    type="text"
                    className={`match form-control ${token.isValid ? '' : 'is-invalid'}`}
                    value={token.pattern}
                    onChange={handlePatternChange}
                />
                <div className="invalid-feedback">{token.error}</div>
            </td>
            <td>
            {token.type === PatternType.Pattern &&
                <HelpPopup />
            }
            </td>
            <td>
                <select value={token.action} onChange={handleActionChange} className="form-select">
                    {token.type !== PatternType.Pattern && <option value={TokenAction.Literal}>Literal</option>}
                    <option value={TokenAction.Name}>Name</option>
                    <option value={TokenAction.Ignore}>Ignore</option>
                </select>
            </td>
            <td>{name}</td>
            <td>
                <button className="btn btn-secondary" onClick={() => props.onTokenDelete(token.id)}>
                    <i className="bi bi-trash"></i>
                </button>
            </td>
        </tr>
    );
}

export function Tokenized(props: { tokenSet: TokenSet }) {
    const tokenSet = props.tokenSet;
    const tokens = tokenSet.tokens;
    if (tokens.length === 0) {
        return <div>No tokens generated</div>;
    }
    const displayTokens = tokens.map((t, ix) =>
        t.spec.action === TokenAction.Literal ? (
            <span className="token" key={ix}>
                {t.value}
            </span>
        ) : t.spec.action === TokenAction.Error ? (
            <span className="token error" key={ix}>
                Error: {t.value}
            </span>
        ) : (
            <span className="token" key={ix} title={t.value}>
                {t.type}
            </span>
        )
    );
    return (
        <div>
            <Help>Tokens generated by the token definitions.</Help>
            <div className="token-stream">{displayTokens}</div>
            <ErrorDisplay message={tokenSet.error?.message} />
        </div>
    );
}

export function LexerRulesComponent(props: {
    onTokenAdd: () => void;
    onTokenDelete: (id: number) => void;
    onTokenChange: (t: TokenSpecification) => void;
    tokens: TokenSpecification[];
}) {
    const rows = props.tokens.map((t, ix) => (
        <LexerRule key={t.id} token={t} 
        onTokenChange={props.onTokenChange} 
        onTokenDelete={props.onTokenDelete} />
    ));
    return (
        <div>
            <Help>
                <p>Specify the rules for seperating the source code characters into tokens.</p>
                <p>
                    <b>Exact</b> matches match the exact text specified under Match. 
                    Multiple exact matches can be specified in the same box, seperated by whitespace.
                </p>
                <p>
                    <b>Pattern</b> can use character groups in brackets and quantifiers <code>*</code> and <code>+</code>. 
                     Examples: <code>[0-9]</code> matches a decimal digit, <code>[0-9]+</code> matches one or more digits.
                </p>
                <p>The Grammar property specifies how the tokens are referenced in the grammar later:</p>
                <ul>
                    <li><b>Ignore</b> patterns are skipped by the tokenizer. Use e.g. for whitespace and comments.</li>
                    <li><b>Name</b> patterns are represented with the specified name in the grammar.</li>
                    <li><b>Literal</b> matches are represented with the literal text in the grammar.</li>
                </ul>
                <p>Any character in the source code which does not match any of the rules will generate an error.</p>
            </Help>
            <table>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Match</th>
                        <th></th>
                        <th>Grammar</th>
                        <th>Token name</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
            <div>
                <button className="btn btn-secondary" onClick={props.onTokenAdd}>
                    Add
                </button>
            </div>
        </div>
    );
}

export class TokenSpecification {
    error?: string;
    constructor(
        public id: number,
        public type: PatternType,
        public pattern: string,
        public action: TokenAction,
        public label?: string
    ) {
        const _valid = this.isValid;
    }
    get isValid() {
        this.error = undefined;
        if (this.pattern === '') {
            this.error = 'Empty pattern';
            return false;
        }

        if (this.type === PatternType.Pattern) {
            try {
                const p = new RegExp(this.pattern, 'msuy');
                if (p.test('')) {
                    this.error = 'Pattern matches empty string';
                    return false;
                }
            } catch (e) {
                if (e instanceof Error) {
                    this.error = 'Invalid pattern: ' + e.message;
                } else {
                    this.error = 'Invalid pattern';
                }
                console.log(e);
                return false;
            }
        }
        if (this.action === TokenAction.Name && !this.label) {
            this.error = 'Name should be specified';
            return false;
        }
        return true;
    }
    static nextId(tokens: TokenSpecification[]) {
        return tokens.length === 0 ? 1 :
            Math.max(...tokens.map(l => l.id)) + 1
    }
}
