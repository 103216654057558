import './lamp.css';
import './relays.css';
import './node.css';

import { IComponentInstanceState } from '../../componentType';
import { LampInternalState } from '../../missions/lamp';

export function DiagramNodeRelayOn(props: {
		node: IComponentInstanceState}) { 

    const inputs =  props.node.inputConnectorStates;
    const outputs =  props.node.outputConnectorStates;

	return (
    <div className='relay-box'>
        <svg width='66' height='60'>
            <line x1='50' y1='0' x2='53' y2='10' strokeWidth='4' className={'wire' + (outputs[0].state ? ' on' : '')}></line>
            <line x1='53' y1='10' x2='53' y2='16' strokeWidth='4' className={'wire' + (outputs[0].state ? ' on' : '')}></line>
            <circle cx='53' cy='18' r='2' className={'wire' + (outputs[0].state ? ' on' : '')}></circle>
            <circle cx='40' cy='18' r='2' className='wire-dot'></circle>
            { !inputs[0].state && (
                <line x1='51' y1='14' x2='53' y2='52' strokeWidth='4' className={'wire' + (inputs[1].state ? ' on' : '')}></line>)} 
            { inputs[0].state && (
                <line x1='42' y1='16' x2='53' y2='52' strokeWidth='4' className={'wire' + (inputs[1].state ? ' on' : '')}></line>)} 

            <circle cx='53' cy='52' r='3' className={'wire' + (inputs[1].state ? ' on' : '')}></circle>
            <line x1='53' y1='52' x2='53' y2='58' strokeWidth='4' className={'wire' + (inputs[1].state ? ' on' : '')}></line>
            <rect x='14' y='29' width='26' height='12' className={'magnet' + (inputs[0].state ? ' on' : '')}></rect>
            <path d='M 24 35
                    a 4 14, 0, 0, 0, -8 0
                    a 6 14, 0, 0, 0, 12 0
                    a 4 14, 0, 0, 0, -8 0
                    a 6 14, 0, 0, 0, 12 0
                    a 4 14, 0, 0, 0, -8 0
                    a 6 14, 0, 0, 0, 12 0
                    a 4 14, 0, 0, 0, -8 0' className={'coil' + (inputs[0].state ? ' on' : '')}></path>
            <line x1='20' y1='40' x2='20' y2='58' className={'coil' + (inputs[0].state ? ' on' : '')}></line>
        </svg>
    </div>); 
}


export function DiagramNodeRelayOff(props: {node: IComponentInstanceState}) { 

    const inputs =  props.node.inputConnectorStates;
    const outputs =  props.node.outputConnectorStates;

	return (
        <div className='relay-box'>
            <svg width='66' height='60'>
                <line x1='35' y1='0' x2='40' y2='18' className={'wire' + (outputs[0].state ? ' on' : '')}></line>
                <circle cx='53' cy='18' r='2' className='wire-dot'></circle>
                <circle cx='40' cy='18' r='2' className={'wire' + (outputs[0].state ? ' on' : '')}></circle>
                { !inputs[0].state && (
                    <line x1='51' y1='14' x2='53' y2='52' strokeWidth='4' className={'wire' + (inputs[1].state ? ' on' : '')}></line>)} 
                { inputs[0].state && (
                    <line x1='42' y1='16' x2='53' y2='52' strokeWidth='4' className={'wire' + (inputs[1].state ? ' on' : '')}></line>)} 
                <circle cx='53' cy='52' r='3' className={'wire' + (inputs[1].state ? ' on' : '')}></circle>
                <line x1='53' y1='52' x2='53' y2='58' strokeWidth='4' className={'wire' + (inputs[1].state ? ' on' : '')}></line>
                <rect x='14' y='29' width='26' height='12' className={'magnet' + (inputs[0].state ? ' on' : '')}></rect>
                <path d='M 24 35
                        a 4 14, 0, 0, 0, -8 0
                        a 6 14, 0, 0, 0, 12 0
                        a 4 14, 0, 0, 0, -8 0
                        a 6 14, 0, 0, 0, 12 0
                        a 4 14, 0, 0, 0, -8 0
                        a 6 14, 0, 0, 0, 12 0
                        a 4 14, 0, 0, 0, -8 0' className={'coil' + (inputs[0].state ? ' on' : '')}></path>
                <line x1='20' y1='40' x2='20' y2='58' className={'coil' + (inputs[0].state ? ' on' : '')}></line>
            </svg>
        </div>); 
}


export function DiagramNodeWireJunction(props: {
		node: IComponentInstanceState}) { 

    const inputs =  props.node.inputConnectorStates;
    const outputs =  props.node.outputConnectorStates;

	return (
            <svg width='80' height='40' className='wire-junction'>
                <circle cx='40' cy='21' r='4' className={'wire' + (outputs[0].state ? ' on' : '')}></circle>
                <line x1='40' y1='0' x2='40' y2='20' className={'wire' + (outputs[0].state ? ' on' : '')}></line>
                <line x1='40' y1='20' x2='20' y2='40' className={'wire downleg' + (inputs[0].state ? ' on' : '')}></line>
                <line x1='40' y1='20' x2='60' y2='40' className={'wire downleg' + (inputs[1].state ? ' on' : '')}></line>
            </svg>); 
}


export function LampComponent(props: {node: IComponentInstanceState}) { 
    const internalState =  props.node.internalState as LampInternalState;
    const isOn =  internalState.isOn;

	return (
            <div className={'lamp-node-box' + (isOn ? ' on' : '')}>
                <div className='lamp-outer'>
                    <div className='lamp'></div>
                </div>
            </div>); 
}

