/* eslint-disable no-bitwise */

/*  16-bit arithmetics
 *  We use numbers under the hood, but have to be careful with negative numbers
 *  Since number is 32 bit so sign bit is in a different place.
 *
 */

export const neg = (x: number) => (~x + 1) & 0xffff;

// inverts all bits in a 16-bit number
export const inv = (a: number) => ~a & 0xffff;

export const isNeg = (a: number) => (a & 0x8000) !== 0;

export const add = (a: number, b: number) => (a + b) & 0xffff;
export const sub = (a: number, b: number) => add(a, neg(b));
export const bitwiseOr = (a: number, b: number) => a | b;

// adds two 16-bit numbers and a carry and returns [carry, low 16 bit] of sum
export function addc(a: number, b: number, c: number): readonly [number, number] {
    const sum = (a & 0xffff) + (b & 0xffff) + (c & 1);
    return [sum > 0xffff ? 1 : 0, sum & 0xffff];
}
export const and = (a: number, b: number) => (a & b) & 0xffff;

const toDecimal = (num: number) => num >= 0x8000 ? -(0x10000 - num) : num;

export const toDecimalString = (num: number) => toDecimal(num).toString(10);

export function fromDecimalString(decimalStr: string) {
    const numeric = parseInt(decimalStr, 10);
    if (isNaN(numeric)) {
        return 0;
    }
    return numeric & 0xffff;
}




