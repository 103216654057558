import { Machine } from '../../assembler/machine';
import { AssemblerPersistence } from '../assemblerMissions';
import { MissionKind } from '../../app/task';
import { Repository } from '../../app/repository';
import { MissionProgression } from '../../app/missionProgression';
import { VerificationOk } from '../../app/verificationResults';
import { CodeTester } from './codeTester';
import { StackMissionState } from './stackMissionState';

class InitTest extends CodeTester {
    init(machine: Machine) {
        // defualt init() sets up SP, so we just ignore that
        machine.ram.pokeImmediately(0, 0);
    }
    setup(_machine: Machine) {
        // No other setup
    }
    verify(machine: Machine) {
        const err = this.expectMemory(machine, 0, 0x100);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export class InitMissionState extends StackMissionState {
    tests = [InitTest];
    utils = ['SetA', 'SetD']
}

export const initMission = {
    key: 'STACK_INIT',
    kind: MissionKind.Stack,
    macro: { name: 'INIT_STACK' },
    start(repository: Repository, game: MissionProgression) {
        return new InitMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new InitMissionState(this, repository, game, data);
    },
};
