import Editor from '@monaco-editor/react';
import { useRef } from 'react';
import { showContext } from './errorContext';
import { ErrorResult, SourcedErrorResult } from './shared';
import { ErrorDisplay } from './ErrorDisplay';

export function CodeEditor(props: { source: string; onCodeChange: (value: string) => void; error?: ErrorResult }) {
    const error = props.error;
    const errText =
        error === undefined
            ? undefined
            : error instanceof SourcedErrorResult
            ? error.message + '\n' + showContext(props.source, error.characterIndex)
            : error.message;
    function handleEditorChange(value: string | undefined, _event: monaco.editor.IModelContentChangedEvent) {
        if (value !== undefined) {
            console.log('here is the current model value:', value);
            props.onCodeChange(value);
        }
    }
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

    function handleEditorDidMount(editor: unknown, _monaco: unknown) {
        // here is the editor instance
        // you can store it in `useRef` for further usage
        editorRef.current = editor as monaco.editor.IStandaloneCodeEditor;
    }
    return (
        <div>
            <Editor
                height="100px"
                width="600px"
                value={props.source}
                onChange={handleEditorChange}
                onMount={handleEditorDidMount}
                theme="vs-dark"
                options={{ lineNumbers: 'off' }}
            />
            <ErrorDisplay message={errText} />
        </div>
    );
}
