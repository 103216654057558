import { getPositionRelativeTo, Pos } from '../../position';
import './NodeHelp.css';

export function DiagramNodeHelp(props: { 
    nodeHelp: string, 
    close: ()=>void;
    elementRef: React.RefObject<HTMLDivElement>;
}) { 
    if (!props.elementRef.current) {
        return null;
    }
    const htmlText =  props.nodeHelp;

    function getPos() {
        const node = props.elementRef.current!;
        const toolElement = node.ownerDocument.getElementById('tool')!;
        const pos = getPositionRelativeTo(node, toolElement);
        // position help to the right of the node
        return new Pos(pos.x + node.offsetWidth, pos.y);
    }
    const pos = getPos();

    function close(event: React.MouseEvent) {
        props.close();
        event.stopPropagation();
    }
    function onClick(event: React.MouseEvent) {
        event.stopPropagation();
    }
    function onMouseDown(event: React.MouseEvent) {
        // Prevent a click from propagate to the node and initiate a drag.
        event.stopPropagation();
    }

	return (
    <div className='node-help tip-container' 
        style={{left: pos.x, top: pos.y}} 
        onMouseDown={onMouseDown}
        onClick={onClick}
        >
    <div className='tip'>
        <svg className='tip-svg'>
            <polyline points='11 0.5 11 19.5' className='poly3' />
            <polyline points='10.5 0 0 10 10.5 20' className='poly1' />
        </svg>

        <div className='inner'>
            <button type='button' aria-label='Close' onClick={close} className='btn-close close'></button>
            <div dangerouslySetInnerHTML={{__html: htmlText}} className='content'></div>
        </div>
    </div>
</div>); 
}