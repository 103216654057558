import { CodeProvider } from './controller';
import { AssemblerProgram, MacroAssembler } from './assembler';
import { SourceFile } from '../ide/sourceFile';
import { AsmInstructionSyntax, AsmMacroInvocation, AsmSyntax } from './syntax';
import { EventEmitter2 } from '../common/eventEmitter';

/* Expose parsed code for editor AND expose current instruction value for engine.
 */
export class EditorBackend implements CodeProvider {
    program: AssemblerProgram;
    private file!: SourceFile;
    currentInstruction: AsmInstructionSyntax | undefined;
    currentLineIx: number | undefined;
    currentAddr = 0;
    currentValue = 0;
    // onValueChanged is fired if the value of the currently selected ROM address changes
    // (due to editing the assembler)
    currentValueChanged = new EventEmitter2<number>();
    currentAddressChanged = new EventEmitter2<number>();
    addrToInstruction!: Map<number, AsmInstructionSyntax>;
    addrToSyntaxLine!: Map<number, number>;

    constructor(private assembler: MacroAssembler) {
        this.program = this.assembler.assemble('');
    }
    setFile(sourceFile: SourceFile) {
        this.file = sourceFile;
        this.parse(this.file.load());
    }
    /* called to initilize editor with content */
    getText() {
        return this.file.load();
    }
    /* called when code changed in editor */
    updateCode(code: string) {
        this.parse(code);
        this.file.save(code);
    }
    reparse() {
        this.parse(this.file.load());
    }
    // When the engine fetches an instruction.
    // Marks the line as 'current'
    fetch(addr: number) {
        const isChanged = this.currentAddr !== addr;
        this.currentAddr = addr;
        this.refreshCurrentValue();
        if (isChanged) {
            this.currentAddressChanged.fire(this.currentAddr);
        }
        return this.currentValue;
    }
    /* update current value */
    private refreshCurrentValue() {
        if (this.currentAddr >= this.program.instructions.length) {
            // clear current
            this.currentInstruction = undefined;
            this.currentLineIx = undefined;
            this.currentValue = 0;
        } else {
            const instruction = this.addrToInstruction.get(this.currentAddr);
            if (instruction) {
                this.currentInstruction = instruction;
                this.currentValue = instruction.instruction.toWord();
            }
            this.currentLineIx = this.addrToSyntaxLine.get(this.currentAddr);
        }
    }
    /* update current value and fire event if it has changed */
    private recheckCurrent() {
        const prevValue = this.currentValue;
        this.refreshCurrentValue();
        if (prevValue !== this.currentValue) {
            this.currentValueChanged.fire(this.currentValue);
        }
    }

    private parse(code: string) {
        this.program = this.assembler.assemble(code);
        this.addrToInstruction = new Map<number, AsmInstructionSyntax>();
        for (const instruction of this.program.instructions) {
            if (instruction instanceof AsmInstructionSyntax && instruction.address !== undefined) {
                this.addrToInstruction.set(instruction.address, instruction);
            }
        }

        // map instruction addresses to top-level code lines
        // (i.e. multiple adresses would map to the same macro instruction)
        this.addrToSyntaxLine = new Map<number, number>();
        const mapInstructionToLineNr = (lines: AsmSyntax[], lineIx: number) => {
            for (const line of lines) {
                if (line instanceof AsmInstructionSyntax && line.address !== undefined) {
                    this.addrToSyntaxLine.set(line.address, lineIx);
                }
                if (line instanceof AsmMacroInvocation) {
                    mapInstructionToLineNr(line.expansion, lineIx);
                }
            }
        }
        this.program.lines.forEach((line, lineIx) => {
            mapInstructionToLineNr([line], lineIx);
        });

        // load and mark the current address - this might be a different line after editing
        this.recheckCurrent();
    }
    get size() {
        return this.program.instructions.length;
    }
    get lines() {
        return this.program.lines;
    }
}
