import './SuccessDialog.css';
import './popup-dialog.css';
import { Verification } from './Verification';
import { VerificationResultSet } from '../verificationResults';
import { UiText } from '../../locale/UiText';
import Draggable from 'react-draggable';

export function ErrorDialog(props: {
    closeTestDialog: ()=>void,
	testResults: VerificationResultSet}) { 

	const testResults = props.testResults;

	return (
        <Draggable onStart={ev=>ev.stopPropagation()} cancel='button'>
        <div className='popup-dialog error-dialog'>
            <div className='content'>
                <div className='alert alert-danger'>
                    <i className='bi bi-exclamation-triangle'></i>
                    <button onClick={props.closeTestDialog} className='btn-close'></button>
                    <UiText id='level_error_header' />
                </div>
                <p><UiText id='level_error' /></p>
                <Verification results={testResults} />
            </div>
            <div className='buttons'>
                <button onClick={props.closeTestDialog} className='btn btn-primary'><UiText id='btn_close' /></button>
            </div>
        </div>
        </Draggable>
); 
}
