import React from 'react';
import './RomView.css';
import { MemoryView } from './MemoryView';
import { IComponentInstanceState } from '../../componentType';
import { RomState } from '../../missions/romNodeType';
import { RomEditor } from './RomEditor';

export function DiagramRomview(props: {
		node: IComponentInstanceState}) { 

	const [state, setState] = React.useState({displayEditor: false});

    function toggleEditor() {  setState({displayEditor: !state.displayEditor}); }
    function onClosed() { setState({displayEditor: false}); }
    const stateView =  (props.node.internalState as RomState).stateView;

	return (<div className='rom-view'>
    <div>
        <button type='button' onClick={toggleEditor} className='btn btn-sm btn-outline-secondary'>Edit ROM</button>
        { state.displayEditor && (
            <div className='editor'>
            <RomEditor stateView={stateView} closed={onClosed} />
        </div>)} 
    </div>
    <MemoryView stateView={stateView} />
    </div>); 
}

