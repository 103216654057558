import { ComponentType } from '../componentType';
import { Hint } from '../hint';
import { binaryTest, TestCase, VerificationSubjectAdapter } from '../verification';
import { TruthTable } from '../truthTable';
import { CircuitState, ComponentInstanceState } from '../circuitState';
import { DiagramMissionType, DiagramType, Score } from '../diagramMissionType';
import { InputPinDefinition, OutputPinDefinition } from '../pins';


export class DiagramAdapter implements VerificationSubjectAdapter {
    constructor(public readonly diagram: CircuitState) { }
    setInputs = (input: number[]) => this.diagram.setInputs(input);
    resetState() {
        this.diagram.clearInputs();
        this.diagram.resetState();
    }
    getOutput(label: string) {
        const connector = this.diagram.outputPins.find(n => n.name === label);
        if (!connector) {
            throw new Error(`Connector '${label}' not found.`);
        }
        return connector.state;
    }
    getOutputs = () => this.diagram.getOutputs();

    findInternalNode = (type: ComponentType): ComponentInstanceState | undefined =>
        this.diagram.findInternalNode(type);

    findInternalNodes = (type: ComponentType): ComponentInstanceState[] =>
        this.diagram.findInternalNodes(type);

    getConnectorLabels(): [string[], string[]] {
        return [
            this.diagram.inputPins.map(n => n.name),
            this.diagram.outputPins.map(n => n.name),
        ];
    }
}

export const truthTable = (missionType: DiagramMissionType, truths: readonly [boolean[], boolean[]][]): TruthTable =>
    new TruthTable(
        missionType.inputPins,
        missionType.outputPins,
        truths
    );


// Declarative specification of a component-mission
interface DiagramSpecification {
    readonly key: string;
    readonly tag?: string;
    readonly unlock?: boolean;
    readonly inputPins: readonly OutputPinDefinition[];
    readonly outputPins: readonly InputPinDefinition[];
    readonly palette: readonly ComponentType[];
    readonly tests?: readonly TestCase[];
    readonly hints?: Hint[];
    readonly truth?: readonly [boolean[], boolean[]][];
    readonly score?: Score;
    readonly diagramType?: DiagramType;
}

export type Truth = readonly [boolean[], boolean[]][];

class MissionFromSpec implements DiagramMissionType {
    readonly key: string;
    readonly tag?: string;
    readonly unlock?: boolean;
    readonly inputPins: readonly OutputPinDefinition[];
    readonly outputPins: readonly InputPinDefinition[];
    readonly palette: readonly ComponentType[];
    readonly tests: readonly TestCase[];
    readonly hints?: readonly Hint[];
    readonly truth?: Truth;
    readonly score?: Score;
    readonly diagramType?: DiagramType;
    readonly truthTable?: TruthTable;
    constructor(spec: DiagramSpecification) {
        this.key = spec.key;
        this.tag = spec.tag;
        this.unlock = spec.unlock;
        this.inputPins = spec.inputPins;
        this.outputPins = spec.outputPins;
        this.palette = spec.palette;
        this.hints = spec.hints;
        this.score = spec.score;
        this.diagramType = spec.diagramType;

        if (spec.truth) {
            this.truthTable = truthTable(this, spec.truth);
            this.tests = spec.truth.map(([inp, out]) => binaryTest(inp, out));
        } else {
            this.tests = spec.tests!;
        }
    }
}

// Creates a mission from a declarative specification
export const diagram = (spec: DiagramSpecification) => new MissionFromSpec(spec);

