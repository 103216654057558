import { useState } from 'react';
import { fromHex, toHex } from '../../../common/hex';


export function HexInput(props: {value: number, update: (value: number) => void}) {
	const [state, setState] = useState(()=>{
		return { 
            initialValue: props.value,
            value: props.value, 
            hexString: toHexString(props.value), 
            hexError: false}; 
	});
    if (props.value !== state.initialValue) {
        reset(props.value);
    }
    function reset(value: number) {
        setState({
            initialValue: props.value,
            value: props.value, 
            hexString: toHexString(value), 
            hexError: false
        });
    }
    function toHexString(word: number) {
        return toHex(word, 1);
    }
    function mousedown(ev: React.MouseEvent) {
        // avoid drag start if in component
        ev.stopPropagation();
    }
    function blur() {
        if (state.hexError) {
            reset(props.value);
        }
    }
    function setHexValue(hex: string) {
        if (!/^[a-fA-F0-9]{1,4}$/.test(hex)) {
            setState({...state, hexString: hex, hexError: true});
        } else {
            const numeric = fromHex(hex);
            setState({...state, value: numeric, hexString: hex, hexError: false});
            props.update(numeric);
        }
    }
    return (<div className='input-row'>
            <label>hex<> </>
                <input type='text' minLength={0} maxLength={4} pattern='[a-fA-F0-9]{0,4}' 
                        value={state.hexString} 
                        onChange={(ev)=>setHexValue(ev.target.value)} 
                        onMouseDown={mousedown} 
                        onBlur={blur}
                        className={'hex-input ' + (state.hexError ? 'is-invalid' : '')} />
            </label>     
    </div>);
}
