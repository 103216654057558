import { expressionTests } from '../grammarTests';
import { Repository } from '../../app/repository';
import { MissionKind } from '../../app/task';
import { GrammarState } from './grammarState';
import { MissionProgression } from '../../app/missionProgression';

/* 
    For compiler-state missions, 
    only the source code (used for testing) is persisted pr mission
    The actual configuration for tokenizer/parser/codegen is persisted globally
*/
type CompilerLevelPersistence = { source: string };

export class ExpressionGrammarState extends GrammarState {
    tests = expressionTests;
    startSymbol = 'Expression';
    getInitialCode() {
        return '2 + 2';
    }
}

export const expressionGrammarMission = {
    key: 'GRAMMAR_EXPRESSION',
    kind: MissionKind.Grammar,
    start(repository: Repository, game: MissionProgression) {
        return new ExpressionGrammarState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as CompilerLevelPersistence;
        return new ExpressionGrammarState(this, repository, game, data);
    },
};
