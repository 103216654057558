import { diagram } from './missions';
import { nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, selectorNodeType } from './logicMissions';
import { SequentialTest, Step } from '../sequentialTest';
import { PinGroup, bit } from '../pins';
import { ComponentInstanceState } from '../circuitState';
import { DelegateStateView } from '../stateViews';
import { ComponentInternalState } from '../componentType';
import { BaseBuiltinComponentType } from './baseNodeType';
import { depends } from './dependency';


class LatchState implements ComponentInternalState {
    state = 0;
    stateView = new DelegateStateView(() => this.state, 'Bit');
    resolveOutputs(node: ComponentInstanceState) {
        if (node.inputConnectorStates[0].bitState) {
            this.state = node.inputConnectorStates[1].numState;
        }
        return [this.state];
    }
    reset() { this.state = 0; }
}

class DffState implements ComponentInternalState {
    oldState = false;
    newState = false;
    stateView = new DelegateStateView(() => this.oldState ? 1 : 0, 'Bit');
    resolveOutputs(node: ComponentInstanceState) {
        const st = node.inputConnectorStates[0].bitState;
        const data = node.inputConnectorStates[1].bitState;
        const clock = node.inputConnectorStates[2].bitState;
        const out = this.resolve(st, data, clock);
        return [out ? 1 : 0];
    }
    resolve(st: boolean, data: boolean, clock: boolean) {
        if (st && !clock) {
            this.newState = data;
        }
        if (clock) {
            this.oldState = this.newState;
        }
        const out = this.oldState;
        return out;
    }

    reset() {
        this.oldState = false;
        this.newState = false;
    }
}

export const latchMission = diagram(<const>{
    key: 'LATCH',
    inputPins: [bit('st'), bit('d')],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, selectorNodeType],
    tests: [
        // store, data
        new SequentialTest([
            Step.set([1, 1], 'Set <b>st</b>=1 and <b>d</b>=1. A 1 should be stored and emitted.'),
            Step.assertOutput('', 1, ''),
            Step.set([0, 1], 'Change <b>st</b> to 0. Output should not change.'),
            Step.assertOutput('', 1, '1 should still be emitted'),
            Step.set([0, 0], 'Change <b>d</b> to 0.  Output should not change.'),
            Step.assertOutput('', 1,
                '1 should still be emitted, since <b>d</b> is ignored when <b>st</b>=0'),
            Step.set([1, 0], 'Set <b>st</b> to 1. A 0 should be stored and emitted.'),
            Step.assertOutput('', 0, ''),
        ]),
        new SequentialTest([
            Step.set([1, 0], 'Set <b>st</b>=1 and <b>d</b>=0. A 0 should be stored and emitted.'),
            Step.assertOutputs([0]),
            Step.set([0, 0], 'Change <b>st</b> to 0. 0 should still be emitted.'),
            Step.assertOutputs([0]),
            Step.set([0, 1],
                'Change <b>d</b> to 1. 0 should still be emitted, since <b>d</b> is ignored when <b>st</b>=0.'),
            Step.assertOutputs([0]),
        ]),
        new SequentialTest([
            Step.set([1, 1], 'Set <b>st</b>=1 and <b>d</b>=1. A 1 should be stored and emitted.'),
            Step.assertOutput('', 1, '1 should be emitted.'),
            Step.set([1, 0], 'Change <b>d</b> to 0. A 0 should be stored and emitted.'),
            Step.assertOutput('', 0, '0 should be emitted'),
            Step.set([0, 0], 'Change <b>st</b> to 0.  Output should not change.'),
            Step.assertOutput('', 0, 'Stored 0 should still be emitted.'),
            Step.set([0, 1],
                'Change <b>d</b> to 1. 0 should still be emitted, since <b>d</b> is ignored when <b>st</b>=0.'),
            Step.assertOutput('',
                0,
                '0 should still be emitted, since <b>d</b> is ignored when <b>st</b>=0'),
        ]),
    ],
    score: { min: 1, nands: 4 },
});

export const latchNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'latch';
    readonly key = 'LATCH';
    readonly inputs = [bit('st'), bit('d')];
    readonly outputs = [bit()];
    readonly hasInternalState = true;
    readonly depends = depends(latchMission);
    readonly createInternalState = () => new LatchState();
};

export const flipFlopMission = diagram(<const>{
    key: 'DFF',
    inputPins: [bit('st'), bit('d'), bit('cl')],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, latchNodeType],
    tests: [
        // inputs: store, data, clock
        new SequentialTest([
            Step.set([1, 1, 0], 'Set <b>st</b>=1 and <b>d</b>=1. Should store 1.'),
            Step.assertOutput('', 0, 'The stored 1 should not be output yet.'),
            Step.set([1, 1, 1], 'Set <b>cl</b>=1 (Clock tick). The stored 1 should now be output'),
            Step.assertOutput('', 1),
            Step.set([1, 1, 0], 'Set <b>cl</b>=0 (Clock tock).'),
            Step.assertOutput('', 1, 'The stored 1 should still be output'),
            // change back to 0
            Step.set([1, 0, 0], 'Set <b>d</b>=0. Should store 0.'),
            Step.set([1, 0, 1], 'Set <b>cl</b>=1 (Clock tick). The stored 0 should now be output'),
            Step.assertOutput('', 0),
        ]),
        new SequentialTest([
            Step.set([1, 0, 0],
                'Set <b>st</b>=1 and <b>d</b>=0. Should store 0. Output should not reflect it yet'),
            Step.set([1, 0, 1], 'Set <b>cl</b>=1 (Clock tick). The stored 0 should now be output'),
            Step.assertOutput('', 0),
            Step.set([1, 1, 1], 'Set <b>d</b>=1 Should have no effect when <b>cl</b>=1'),
            Step.assertOutput('', 0, 'The stored 0 should still be output'),
        ]),
        new SequentialTest([
            Step.set([0, 1, 0], 'Set <b>d</b>=1. Should not store anything since <b>st</b> is 0.'),
            Step.set([0, 1, 1], 'Set <b>cl</b>=1 (Clock tick). '),
            Step.assertOutput('', 0, 'Output should still be 0.'),
            Step.set([0, 1, 0], 'Set <b>cl</b>=0 (Clock tick). '),
            Step.assertOutput('', 0, 'Output should still be 0.'),
        ]),
        new SequentialTest([
            Step.set([0, 1, 0], 'Set <b>d</b>=1. Should not store anything since <b>st</b> is 0.'),
            Step.set([0, 1, 1], 'Set <b>cl</b>=1 (Clock tick). '),
            Step.assertOutput('', 0, 'Output should still be 0.'),
            Step.set([1, 1, 1], 'Set <b>st</b>=1. Should not change state since <b>st</b> does not have effect when <b>cl</b> = 1 '),
            Step.assertOutput('', 0, 'Output should still be 0.'),
        ]),
        new SequentialTest([
            Step.set([1, 1, 0], 'Set <b>st</b>=1 and <b>d</b>=1. Should store 1.'),
            Step.assertOutput('', 0, 'The stored 1 should not be output yet.'),
            Step.set([1, 1, 1], 'Set <b>cl</b>=1 (Clock tick). The stored 1 should now be output'),
            Step.assertOutput('', 1),
            Step.set([1, 1, 0], 'Set <b>cl</b>=0 (Clock tock).'),
            Step.set([0, 0, 0],
                'Set <b>st</b>=0  and <b>d</b>=0. The stored value should not change when <b>st</b>=0'),
            Step.set([0, 0, 1], 'Set <b>cl</b>=1 (Clock tick).'),
            Step.assertOutput('', 1, 'The stored 1 should still be output'),
        ]),
    ],
    score: { min: 4, nands: 9 },
});

export const dffNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'dff';
    readonly key = 'DFF';
    readonly inputs = [bit('st'), bit('d'), bit('cl')];
    readonly outputs = [bit()];
    readonly hasInternalState = true;
    readonly depends = depends(flipFlopMission);
    readonly createInternalState = () => new DffState();
};

export const dff2Mission = diagram(<const>{
    key: 'DFF2',
    inputPins: [bit('st'), new PinGroup('', [bit('d1'), bit('d0')]), bit('cl')],
    outputPins: [new PinGroup('', [bit('d1'), bit('d0')])],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, dffNodeType],
    tests: [
        // inputs: store, data1, data0, clock
        new SequentialTest([
            Step.set([1, 0, 1, 0], 'Set <b>st</b>=1, <b>d1</b>=0 and <b>d0</b>=1. Should store binary 01.'),
            Step.set([1, 0, 1, 1], 'Set <b>cl</b>=1 (Clock tick). The stored 01 should now be output'),
            Step.assertOutputs([0, 1])
        ]),
        new SequentialTest([
            Step.set([1, 1, 0, 0], 'Set <b>st</b>=1, <b>d1</b>=1 and <b>d0</b>=0. Should store binary 10.'),
            Step.set([1, 1, 0, 1], 'Set <b>cl</b>=1 (Clock tick). The stored 10 should now be output'),
            Step.assertOutputs([1, 0])
        ]),
        // when st = 0, data should not be stored
        new SequentialTest([
            Step.set([0, 1, 1, 0], 'Set <b>st</b>=0, <b>d1</b>=0 and <b>d0</b>=1. Should not store data.'),
            Step.set([0, 1, 1, 1], 'Set <b>cl</b>=1 (Clock tick).'),
            Step.assertOutputs([0, 0])
        ]),
        // set output to 11, then change input when st=0 - this should not affect output
        new SequentialTest([
            Step.set([1, 1, 1, 0], 'Set <b>st</b>=1, <b>d1</b>=1 and <b>d0</b>=1. Should store binary 11.'),
            Step.set([1, 1, 1, 1], 'Set <b>cl</b>=1 (Clock tick). The stored 11 should now be output'),
            Step.assertOutputs([1, 1]),
            Step.set([0, 0, 0, 0], 'Set <b>st</b>=0, <b>d1</b>=0 and <b>d0</b>=0. Should not change data when <b>st</b> is 0.'),
            Step.assertOutputs([1, 1]),
            Step.set([0, 0, 0, 1], 'Set <b>cl</b>=1 (Clock tick). Should not change data.'),
            Step.assertOutputs([1, 1]),
        ]),
    ],
    score: { min: 2 },
});
