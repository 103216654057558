import { AssemblerPersistence } from '../assemblerMissions';
import { pureStackTest } from './codeTester';
import { StackMissionState } from './stackMissionState';
import { MissionKind } from '../../app/task';
import { Repository } from '../../app/repository';
import { MissionProgression } from '../../app/missionProgression';


export const stackEqMissionTests =  [
    pureStackTest([9, 9], [0xFFFF]),
    pureStackTest([9, 8], [0]),
];

export class StackEqMissionState extends StackMissionState {
    tests = stackEqMissionTests
}

export const stackEqMission = {
    key: 'STACK_EQ',
    kind: MissionKind.Stack,
    macro: { name: 'EQ' },
    start(repository: Repository, game: MissionProgression) {
        return new StackEqMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new StackEqMissionState(this, repository, game, data);
    },
};

export const stackGtMissionTests = [
    pureStackTest([15, 7], [0xFFFF]),
    pureStackTest([7, 15], [0]),
    pureStackTest([0xFFF4, 0xFFF4], [0]),
];

export class StackGtMissionState extends StackMissionState {
    tests = stackGtMissionTests;
}

export const stackGtMission = {
    key: 'STACK_GT',
    kind: MissionKind.Stack,
    macro: { name: 'GT' },
    start(repository: Repository, game: MissionProgression) {
        return new StackGtMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new StackGtMissionState(this, repository, game, data);
    },
};

export const stackLtMissionTests = [
    pureStackTest([15, 7], [0]),
    pureStackTest([7, 15], [0xFFFF]),
    pureStackTest([0xFFF4, 0xFFF4], [0]),
];

export class StackLtMissionState extends StackMissionState {
    tests = stackLtMissionTests;
}

export const stackLtMission = {
    key: 'STACK_LT',
    kind: MissionKind.Stack,
    macro: { name: 'LT' },
    start(repository: Repository, game: MissionProgression) {
        return new StackLtMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new StackLtMissionState(this, repository, game, data);
    },
};
