import {
    nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, inv16NodeType, and16NodeType,
    bundlerNodeType,
    selectorNodeType,
    decoderNodeType,
    selector16NodeType,
    demuxNodeType,
    splitterNodeType,
    or16NodeType
} from '../missions/logicMissions';
import {
    addNodeType, addcNodeType, add16NodeType, add16cNodeType, incNodeType, sub16NodeType,
    zeroNodeType, zero16NodeType, isZero16NodeType, isNegNodeType
} from '../missions/arithmeticMissions';
import { buttonNodeType, lampNodeType } from './inputOutputMission';
import { controlUnitNodeType } from './controlUnitMission';
import { clockNodeType } from './clockNodeType';
import { romNodeType } from './romNodeType';
import { aluNodeType, conditionNodeType, aluUnaryModifierNodeType } from './aluMissions';
import { instructionDecoderNodeType } from './instructionDecoderMission';
import { cpuStateNodeType } from './combinedStateMission';
import { ram2NodeType, ram64kbNodeType } from './ramMissions';
import { latchNodeType, dffNodeType } from './dffMissions';
import { dff16NodeType } from './registerMission';
import { counterNodeType } from './counterMission';
import { BuiltinComponentType } from '../componentType';
import { barrelShlNodeType, barrelShr22NodeType, barrelShrNodeType, maxNodeType, shlNodeType, shrNodeType } from '../optionalMissions/optionalMissions';
import { cmosWireJunctionNodeType, nmosTransistorType, pmosTransistorType } from '../optionalMissions/transistorLevel';
import { relayOffType, relayOnType } from './nandMission';
import { bitSelectNodeType, constNodeType } from './constNodeType';
import { mul22NodeType, floatNormalizeOverflowNodeType, packFloatNodeType, unpackFloatNodeType, floatMulUnpackedNodeType, floatVerifyExponentNodeType, floatNormalizeUnderflowNodeType, floatAlignNodeType, floatAddSignedMagnitudeNodeType } from '../optionalMissions/floatMission';

/* All node types need to be registered here to be located when restoring diagrams */
export const nodeTypes = <const>{
    nand: nandNodeType,
    inv: invNodeType,
    and: andNodeType,
    or: orNodeType,
    xor: xorNodeType,
    inv16: inv16NodeType,
    and16: and16NodeType,
    or16: or16NodeType,
    add: addNodeType,
    addc: addcNodeType,
    add16: add16NodeType,
    add16c: add16cNodeType,
    inc: incNodeType,
    sub16: sub16NodeType,
    zero: zeroNodeType,
    zero16: zero16NodeType,
    isZero: isZero16NodeType,
    isNeg: isNegNodeType,
    splitter: splitterNodeType,
    bundler: bundlerNodeType,
    selector: selectorNodeType,
    decoder: decoderNodeType,
    selector16: selector16NodeType,
    demux: demuxNodeType,
    aluUnaryModifier: aluUnaryModifierNodeType,
    alu: aluNodeType,
    condition: conditionNodeType,
    latch: latchNodeType,
    dff: dffNodeType,
    dff16: dff16NodeType,
    ram2: ram2NodeType,
    counter: counterNodeType,
    ram64kb: ram64kbNodeType,
    cpuState: cpuStateNodeType,
    rom: romNodeType,
    instructionDecoder: instructionDecoderNodeType,
    controlUnit: controlUnitNodeType,
    lamp: lampNodeType,
    button: buttonNodeType,
    clock: clockNodeType,
    // from optional levels:
    const: constNodeType,
    bitSelect: bitSelectNodeType,
    shl: shlNodeType,
    shr: shrNodeType,
    barrelShl: barrelShlNodeType,
    barrelShr22: barrelShr22NodeType,
    barrelShr: barrelShrNodeType,
    max: maxNodeType,
    floatMulUnpacked: floatMulUnpackedNodeType,
    floatNormalizeOverflow: floatNormalizeOverflowNodeType,
    floatNormalizeUnderflow: floatNormalizeUnderflowNodeType,
    floatVerifyExponent: floatVerifyExponentNodeType,
    floatAlign: floatAlignNodeType,
    floatAddSignedMagnitude: floatAddSignedMagnitudeNodeType,
    packFloat: packFloatNodeType,
    unpackFloat: unpackFloatNodeType,
    mul22: mul22NodeType,
    // from transistor level:
    pmos: pmosTransistorType,
    nmos: nmosTransistorType,
    cmosWireJunction: cmosWireJunctionNodeType,
    relayOn: relayOnType,
    relayOff: relayOffType
};

type NodeTypeLookup = { [k: string]: BuiltinComponentType };
export namespace ComponentTypes {
    /** Array of all node types */
    export function getAll() {
        const all: NodeTypeLookup = nodeTypes;
        return Object.values(all);
    }
    /**  A map from NodeType keys to NodeType object.
     *   Used by deserializer.
     */
    export function getNodeTypeMap() {
        return new Map(getAll().map(nodeType => [nodeType.key, nodeType]));
    }
}

