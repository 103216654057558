import { DiagramMissionType } from '../diagram/diagramMissionType';
import { DiagramDeserializer } from '../diagram/diagramPersistence';
import { DiagramMission } from '../diagram/diagramMission';
import { MissionStatus } from './missionStatus';
import { MissionState, Task } from './task';
import { MissionEpic, MissionGroup } from '../missions/track';
import { DiagramSet } from '../diagram/diagramSet';
import { DiagramMissionState } from '../diagram/diagramMissionState';
import { BuiltinComponentType, ComponentType } from '../diagram/componentType';
import { CustomComponentType } from '../diagram/customComponent';
import { Repository } from './repository';
import { SharedConstants } from '../missions/stack/sharedConstants';
import { SharedCompilerState } from '../compiler/sharedCompilerState';


export class MissionItem {
    constructor(readonly mission: Task, public state?: MissionState) { }
    start(repository: Repository, missions: MissionProgression) {
        this.state = this.mission.start(repository, missions);
    }
    get isStarted() { return this.state !== undefined; }
    get isCompleted() { return this.state && this.state.isCompleted; }
    get tag() { return this.mission.tag; }
    get status() {
        if (this.isStarted) {
            return this.isCompleted ? MissionStatus.Complete : MissionStatus.Started;
        } else {
            // mission is not started
            return this.mission.unlock === true ? MissionStatus.Unlocked : MissionStatus.Locked;
        }
    }
}

export class ActivatedMissionGroup {
    constructor(public group: MissionGroup, public missions: MissionItem[]) { }
    get name() { return this.group.name; }
}

export class ActivatedMissionEpic {
    constructor(public category: MissionEpic, public groups: ActivatedMissionGroup[]) { }
    get primaryTrack() { return this.category.primaryTrack; }
    get name() { return this.category.name; }
}


export class MissionNavigator {
    private items = this.trackMissions();

    constructor(readonly missionProgression: MissionProgression) { }

    private trackMissions() {
        return this.missionProgression.activatedEpics
            .flatMap<ActivatedMissionGroup>(e => e.groups)
            .flatMap<MissionItem>(g => g.missions);
    }
    getFirstMission() {
        return this.items[0];
    }
    getLastMission() {
        return this.items[this.items.length - 1];
    }
    getNextMission(current: MissionItem): MissionItem | null {
        const nextIx = this.items.indexOf(current) + 1;
        if (nextIx === this.items.length) {
            return null;
        }
        return this.items[nextIx];
    }
    getNextIncompleteMission(current: MissionItem): MissionItem | null {
        const ix = this.items.indexOf(current);
        return this.getNextIncomplete(ix + 1);
    }
    getFirstIncomplete(): MissionItem | null {
        return this.getNextIncomplete(0);
    }

    getNextIncomplete(fromIx: number): MissionItem | null {
        let i = fromIx;
        while (i < this.items.length) {
            const item = this.items[i];
            if (item.status !== MissionStatus.Complete) {
                return item;
            }
            i++;
        }
        return null;
    }

    getGroup(current: MissionItem) {
        return this.missionProgression.activatedEpics
            .flatMap(c => c.groups.filter(g => g.missions.includes(current)))[0];
    }
}


export class DiagramMissionsSet implements DiagramSet {
    private diagramItems: MissionItem[];
    constructor(readonly missionHistory: MissionProgression) {
        this.diagramItems = this.diagramMissions();
    }
    private diagramMissions() {
        return this.missionHistory.activatedItems.filter(i => i.mission instanceof DiagramMission);
    }
    get diagrams() {
        return this.diagramItems
            .filter(d => d.isStarted)
            .map(d => d.state! as DiagramMissionState)
            .map(s => s.diagram);
    }
    isCompleted(missionType: DiagramMissionType) {
        const item = this.getMissionItemByType(missionType);
        return item !== undefined && item.status === MissionStatus.Complete;
    }
    getDiagramMissionStateByType(missionType: DiagramMissionType): DiagramMissionState | undefined {
        const item = this.getMissionItemByType(missionType);
        if (!item) { return undefined; }
        return item.state as DiagramMissionState;
    }
    getMissionItemByType(missionType: DiagramMissionType) {
        return this.diagramItems
            .find(m => m.mission instanceof DiagramMission && m.mission.missionType === missionType);
    }
    // True is the given node depends (directly or indirectly) on the given mission
    nodeDependsOnMission(nodeType: ComponentType, missionType: DiagramMissionType): boolean {
        if (nodeType instanceof CustomComponentType) {
            return nodeType.diagram.nodes.some(
                node => this.nodeDependsOnMission(node.nodeType, missionType));
        } else {
            const nodeType1 = nodeType as BuiltinComponentType;
            const depends = nodeType1.depends;
            if (!depends.mission) {
                return false;
            }
            const mission = depends.mission;
            if (mission === missionType) {
                return true;
            }
            const missionState = this.getDiagramMissionStateByType(mission);
            if (missionState) {
                return missionState.diagram.nodes.some(
                    node => this.nodeDependsOnMission(node.nodeType, missionType));
            }
            return false;
        }
    }
}




/*
 * Keeps track of missions and their current state for the player
 */
export class MissionProgression {
    activatedEpics: ActivatedMissionEpic[] = [];
    activatedItems: MissionItem[] = [];

    sharedConstants = new SharedConstants(this.repository);
    sharedCompiler = new SharedCompilerState(this.repository);

    constructor(readonly repository: Repository,
        readonly diagramDeserializer: DiagramDeserializer,
        readonly categories: MissionEpic[]) {
            
        this.activatedEpics = this.activate(categories);
        this.activatedItems = this.activatedEpics.flatMap(c => c.groups.flatMap(g => g.missions));
    }
    save() {
        const levels = this.activatedItems
            .filter(m => m.isStarted)
            .map(m => m.mission.key);
        this.repository.saveLevels(levels);
    }
    private activate(missionCategories: MissionEpic[]) {
        return missionCategories.map(mc => new ActivatedMissionEpic(mc, this.activateGroups(mc.groups)));
    }
    private activateGroups(missionGroups: readonly MissionGroup[]) {
        return missionGroups.map(mg => new ActivatedMissionGroup(mg, this.activateMissions(mg.missions)));
    }
    private activateMissions(missions: readonly Task[]) {
        return missions.map(m => this.activateMission(m));
    }
    private activateMission(mission: Task) {
        const missionKey = mission.key;
        const data = this.repository.getLevelData(mission.key);
        if (data === null) {
            return new MissionItem(mission);
        }
        try {
            const state = mission.restore(this.repository, this);
            // We postpone verification until all missions are loaded 
            state.resetState();
            return new MissionItem(mission, state);
        } catch (err) {
            console.error(`Error while restoring level ${missionKey}`, err);
            // for whatever reason, the level was not restored correctly.
            // we reset the level.
            const state = mission.start(this.repository, this);
            return new MissionItem(mission, state);
        }
    }
    testAll(missions: MissionItem[]) {
        missions
            .filter(m => m.isStarted)
            .forEach(m => {
                const state = m.state!;
                // verify to update completed state
                try {
                    state.verify();
                } catch (e) {
                    /* Should never happen, but we dont want a bug in a single test to 
                    stop the whole game from loading */
                    console.error(e);
                }
                // reset state after test run
                // (when explicity running test, we want the keep the state of last failed test,
                // but here the test is run automatically it would be confuing to keep state)
                state.resetState();
            });
    }

}
