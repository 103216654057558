/* eslint no-bitwise: 0 */

export function numberToBoolArray(val: number, bits: number) {
    const values: boolean[] = [];
    for (let nr = 0; nr < bits; nr++) {
        const bit = val % 2;
        values.unshift(bit === 1);
        val = (val - bit) / 2;
    }
    return values;
}
export const numberToBitArray = (val: number, bits: number) =>
    numberToBoolArray(val, bits).map(n => boolToBit(n));

export const boolArrayToNumber = (bits: boolean[]) => bitArrayToNumber(bits.map(boolToBit));
export const bitArrayToNumber = (bits: number[]) => bits.reduce(((acc, v) => acc * 2 + v), 0);

export const boolToBit = (flag: boolean): number => flag ? 1 : 0;
export const bitToBool = (bit: number): boolean => bit !== 0;

export const getBit = (value: number, bitNr: number) => (value & (1 << bitNr)) !== 0;
export function setBit(value: number, bitNr: number, val: boolean) {
    const flag = (1 << bitNr);
    if (val) {
        return value | flag;
    } else {
        return value & (~flag);
    }
}

