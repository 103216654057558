import './TextPage.css';
import { NavLink } from 'react-router-dom';
import { UiText } from '../../locale/UiText';


export function TextPage(props: {page: string}) { 
    const page = props.page;

	return (<>
<nav className='navbar navbar-dark bg-dark'>
    <div className='nav nav-pills'>
        <NavLink to='/' className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
            <i className='bi bi-chevron-left'></i> Back
        </NavLink>
        <NavLink to='/donate' className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}><UiText id='donate' /></NavLink>
        <NavLink to='/about' className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}><UiText id='about' /></NavLink>
    </div>
</nav>

<div className='textpage'>

    { page==='about' && (
        <>

        <p><UiText id='about_translation' /></p>

        <p><b>The Nand Game</b> is inspired by the amazing course <a href='http://nand2tetris.org/'>From NAND to Tetris - Building a Modern Computer From First Principles</a> which is highly recommended.</p>

        <p>
            This game only covers a small part of the material in the above course,
            and is mostly intended as a fun exercise.
        </p>

        <p>If you are interested in the fundamentals of computing, the book <a href='https://www.amazon.com/Code-Language-Computer-Hardware-Software/dp/0735611319'>Code</a> by Charles Petzold is also highly recommended.</p>

        <p>For any feedback, complaints or questions contact <a href='mailto:olav@olav.dk'>olav@olav.dk</a>.</p>

        <p>Hope you have fun with the game!</p>
        <p style={{textAlign: 'right'}}>- Olav Junker Kjær</p>

    </>)} 

    { page==='donate' && (
        <>

        <p>If you feel this game has improved the world, consider donating to support it:</p>

        <h3>Donate via Paypal</h3>

        <form action='https://www.paypal.com/cgi-bin/webscr' method='post' target='_top'>
            <input type='hidden' name='cmd' value='_s-xclick' />
            <input type='hidden' name='hosted_button_id' value='VCWN66BV8CP26' />
            <input type='image' src='https://www.paypalobjects.com/en_US/DK/i/btn/btn_donateCC_LG.gif' name='submit' alt='PayPal - The safer, easier way to pay online!' />
            <img alt='' src='https://www.paypalobjects.com/en_US/i/scr/pixel.gif' width='1' height='1' />
        </form>

        <h3>Donate via Bitcoin</h3>

        Address: 3HNcyFcbAALBnPtvUKHFRVfgCJR5PN9SvU

    </>)} 

</div>
</>); 
}

