import { EventEmitter2 } from '../common/eventEmitter';
import { MacroAssembler, AssemblerProgram } from './assembler';
import { CodeProvider } from './controller';


/*
 * Headless code container (no editor) used by tests
*/
export class HeadlessCodeContainer implements CodeProvider {
    program: AssemblerProgram;
    currentValueChanged = new EventEmitter2<number>();
    constructor(private assembler: MacroAssembler) {
        this.program = this.assembler.assemble('');
    }
    setCode(raw: string) {
        this.program = this.assembler.assemble(raw);
    }
    get size() {
        return this.program.instructions.length;
    }
    fetch(addr: number) {
        if (addr >= this.size) {
            return 0;
        }
        return this.program.instructions[addr].instruction.toWord();
    }
}
