import { diagram } from '../../diagram/missions/missions';
import {
    nandNodeType, invNodeType, andNodeType, xorNodeType, orNodeType, inv16NodeType, splitterNodeType, bundlerNodeType, selector16NodeType, and16NodeType
} from '../../diagram/missions/logicMissions';
import { add16cNodeType, add16NodeType, incNodeType, isNegNodeType, isZero16NodeType, sub16NodeType, zeroNodeType } from '../../diagram/missions/arithmeticMissions';
import { barrelShrNodeType, barrelShr22NodeType, maxNodeType, shlNodeType, shrNodeType } from './optionalMissions';
import { numericTest } from '../../diagram/verification';
import { bit, Pin, PinGroup, word } from '../../diagram/pins';
import { component } from '../missions/baseNodeType';
import { OutputRuleArray } from '../missions/outputRules';
import { depends } from '../missions/dependency';
import { addSignedMagnitudes, align, Bit, unpack, fromJs, jsToSignedMagnitude, mulUnpacked, normalizeAndPack, normalizeOverflow, normalizeUnderflow, verifyExponent } from './float';
import { bitSelectNodeType, constNodeType } from '../missions/constNodeType';


export const floatUnpackMission = diagram(<const>{
    key: 'FLOAT_UNPACK',
    tag: 'preview',
    unlock: true,
    inputPins: [word('fp')],
    outputPins: [bit('sgn'), new Pin(16, 'exp'), new Pin(16, 'sf')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType, incNodeType, add16cNodeType,
        inv16NodeType, shlNodeType, isZero16NodeType, isNegNodeType,
        bundlerNodeType, splitterNodeType, selector16NodeType
    ],
    tests: [
        numericTest([0b0_00001_00000_00000], [0, 0b00001, 0b100000_00000]),
        numericTest([0b1_11110_11111_11111], [1, 0b11110, 0b111111_11111]),
        // sub-normal zero - singificand should not be prefixed :
        numericTest([0b0_00000_00000_00000], [0, 0, 0])
    ],
    score: undefined, // TODO
});


export const floatNormalizeOverflowMission = diagram(<const>{
    key: 'FLOAT_NORMALIZE_OVERFLOW',
    tag: 'preview',
    unlock: true,
    inputPins: [
        new Pin(16, 'exp'),
        new Pin(16, 'sf')],
    outputPins: [new Pin(16, 'exp'), new Pin(16, 'sf')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        bitSelectNodeType, shrNodeType, shlNodeType,
        incNodeType, add16NodeType,
        inv16NodeType, isZero16NodeType, isNegNodeType,
        bundlerNodeType, splitterNodeType, selector16NodeType
    ],
    tests: [
        numericTest([15, 0], [0b01111, 0b00000_00000]),
        numericTest([0b00001, 0b1_00000_00000], [0b00001, 0b1_00000_00000]),
        numericTest([0b11110, 0b1_11111_11111], [0b11110, 0b1_11111_11111]),
        // significand overflow
        numericTest([17, 0b11_10111_11011], [18, 0b1_11011_11101]),
        // special case: 0
        numericTest([0, 0], [0, 0]),
    ],
    score: undefined, // TODO
});

export const floatNormalizeOverflowNodeType = component('normalize overflow',
    'FLOAT_NORMALIZE_OVERFLOW',
    [new Pin(16, 'exp'), new Pin(16, 'sf')],
    [new Pin(16, 'exp'), new Pin(16, 'sf')],
    new OutputRuleArray(([exponent, significand]) =>
        normalizeOverflow(exponent, significand)
    ),
    depends(floatNormalizeOverflowMission)
);


export const unpackFloatNodeType = component('f.unpack',
    'FLOAT_UNPACK',
    [word()],
    [bit('sgn'), new Pin(5, 'exp'), new Pin(11, 'sf')],
    new OutputRuleArray(([a]) =>
        unpack(a),
    ),
    depends(floatUnpackMission)
);

export const mul22NodeType = component('mul',
    'MUL32',
    [new Pin(11, 'A'), new Pin(11, 'B')],
    [new Pin(22)],
    new OutputRuleArray(([a, b]) =>
        [(a * b)]
    ),
    depends(floatUnpackMission)
);

function internalMulTestCase(a: number, b: number) {
    const [aSgn, aExp, aSignificand] = unpack(fromJs(a));
    const [bSgn, bExp, bSignificand] = unpack(fromJs(b));
    const [sgn, exp, significand] = unpack(fromJs(a * b));
    return numericTest([aSgn, aExp, aSignificand, bSgn, bExp, bSignificand], [sgn, exp, significand]);
}


export const floatMultiplyUnpackedMission = diagram(<const>{
    key: 'FLOAT_MUL_UNPACKED',
    tag: 'preview',
    unlock: true,
    inputPins: [
        new PinGroup('A', [bit('sg'), word('exp'), word('sf')]),
        new PinGroup('B', [bit('sg'), word('exp'), word('sf')])],
    outputPins: [bit('sg'), word('exp'), word('sf')],
    palette: [
        mul22NodeType, constNodeType,
        shrNodeType, barrelShr22NodeType, bitSelectNodeType,
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType, incNodeType,
        add16NodeType, sub16NodeType,
        inv16NodeType, shlNodeType, isZero16NodeType, isNegNodeType,
        bundlerNodeType, splitterNodeType, selector16NodeType
    ],
    tests: [
        internalMulTestCase(1, 1),
        internalMulTestCase(2, 3),
        internalMulTestCase(0.5, 0.5)
        /*
        numericTest([0, 0, , fromJs(1)], [fromJs(1)]),
        numericTest([fromJs(1), fromJs(27)], [fromJs(27)]),
        numericTest([fromJs(9), fromJs(27)], [fromJs(9 * 27)]),
        numericTest([fromJs(2048), fromJs(4)], [fromJs(2048 * 4)]),
        numericTest([fromJs(2047), fromJs(32)], [fromJs(2047 * 32)]),
        // sign
        numericTest([fromJs(-2047), fromJs(32)], [fromJs(-2047 * 32)]),
        numericTest([fromJs(-2047), fromJs(-32)], [fromJs(2047 * 32)]),
        // exponent overflow
        numericTest([fromJs(2047), fromJs(2047)], [0b0_11111_00000_00000]),
        // special case: 0
        numericTest([fromJs(0), fromJs(27)], [0b0_00000_00000_00000]),
        */
    ],
    score: undefined, // TODO
});


export const floatMulUnpackedNodeType = component('mul',
    'FLOAT_MUL_UNPACKED',
    [bit('sg'), word('exp'), word('sf'), bit('sg'), word('exp'), word('sf')],
    [bit('sg'), word('exp'), word('sf')],
    new OutputRuleArray(([sign, exponent, significand, bSign, bExponent, bSignificand]) =>
        mulUnpacked([sign as Bit, exponent, significand], [bSign as Bit, bExponent, bSignificand])
    ),
    depends(floatMultiplyUnpackedMission)
);

export const floatAlignMission = diagram(<const>{
    key: 'FLOAT_ALIGN',
    tag: 'preview',
    unlock: true,
    inputPins: [new PinGroup('A', [word('exp'), word('sf')]),
                new PinGroup('B', [word('exp'), word('sf')])],
    outputPins: [new Pin(16, 'exp'), new Pin(16, 'asf'), new Pin(16, 'bsf')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType, incNodeType,
        maxNodeType, add16NodeType, sub16NodeType,
        barrelShrNodeType,
        inv16NodeType, isZero16NodeType, isNegNodeType,
        bundlerNodeType, splitterNodeType, selector16NodeType
    ],
    tests: [
        numericTest([16, 1024, 16, 1025], [16, 1024, 1025]),
        numericTest([16, 1024, 18, 1024], [18, 256, 1024]),
    ],
    score: undefined, // TODO
});

export const floatAlignNodeType = component('align',
    'FLOAT_ALIGN',
    [new Pin(16, 'exp'), new Pin(16, 'sf'),
        new Pin(16, 'exp'), new Pin(16, 'sf')],
    [new Pin(16, 'exp'), new Pin(16, 'asf'), new Pin(16, 'bsf')],
    new OutputRuleArray(([aExp, aSig, bExp, bSig]) =>
        align([aExp, aSig], [bExp, bSig])
    ),
    depends(floatAlignMission)
);

function addSignedTestCase(a: number, b: number) {
    const [aSign, aSignificand] = jsToSignedMagnitude(a);
    const [bSign, bSignificand] = jsToSignedMagnitude(b);
    const [rSign, rSignificand] = addSignedMagnitudes([aSign, aSignificand], [bSign, bSignificand]);
    return numericTest([aSign, aSignificand, bSign, bSignificand], [rSign, rSignificand]);
}

export const floatAddSignedMagnitudeMission = diagram(<const>{
    key: 'ADD_SIGNED_MAGNITUDE',
    tag: 'preview',
    unlock: true,
    inputPins: [
        new PinGroup('A', [bit('sg'), word('m')]),
        new PinGroup('B', [bit('sg'), word('m')])],
    outputPins: [bit('sg'), word('m')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType, incNodeType,
        add16NodeType, sub16NodeType,
        inv16NodeType, isZero16NodeType, isNegNodeType,
        selector16NodeType
    ],
    tests: [
        addSignedTestCase(1, 1),
        addSignedTestCase(2, 3),
        addSignedTestCase(3, -2),
        addSignedTestCase(3, -7),
        addSignedTestCase(-15, 7),
        addSignedTestCase(-8, -7),
        addSignedTestCase(-17, -7),
    ],
    score: undefined, // TODO
});

export const floatAddSignedMagnitudeNodeType = component('add.sgm',
    'ADD_SIGNED_MAGNITUDE',
    [bit('sg'), word('m'),
       bit('sg'), word('m')],
    [bit('sg'), word('m')],
    new OutputRuleArray(([aSgn, aNum, bSgn, bNum]) =>
        addSignedMagnitudes([aSgn, aNum], [bSgn, bNum])
    ),
    depends(floatAlignMission)
);


/*
function internalAddTestCase(a: number, b: number) {
    const [aSgn, aExp, aSignificand] = unpack(fromJs(a));
    const [bSgn, bExp, bSignificand] = unpack(fromJs(b));
    const [sgn, exp, significand] = unpack(fromJs(a + b));
    return numericTest([aSgn, aExp, aSignificand, bSgn, bExp, bSignificand], [sgn, exp, significand]);
}
*/


export const floatNormalizeUnderflowMission = diagram(<const>{
    key: 'FLOAT_NORMALIZE_UNDERFLOW',
    tag: 'preview',
    unlock: true,
    inputPins: [
        new Pin(16, 'exp'),
        new Pin(16, 'sf')],
    outputPins: [new Pin(16, 'exp'), new Pin(16, 'sf')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        bitSelectNodeType, shrNodeType, shlNodeType,
        incNodeType, add16cNodeType,
        inv16NodeType, isZero16NodeType, isNegNodeType,
        bundlerNodeType, splitterNodeType, selector16NodeType
    ],
    tests: [
        numericTest([15, 0b1_00000_00000], [15, 0b1_00000_00000]),
        numericTest([15, 0b1_00000_0000], [14, 0b1_00000_00000]),
        numericTest([15, 0b1_00000_000], [13, 0b1_00000_00000]),
        numericTest([15, 0b1_00000_00], [12, 0b1_00000_00000]),
        numericTest([0xf, 0x200], [0xe, 0x400]),
        // special case: 0 (TODO)
        //numericTest([0, 0], [0, 0]),
        //numericTest([15, 0], [0b01111, 0b00000_00000]),
    ],
    score: undefined, // TODO
});

export const floatNormalizeUnderflowNodeType = component('normalize underflow',
    'FLOAT_NORMALIZE_UNDERFLOW',
    [new Pin(16, 'exp'), new Pin(16, 'sf')],
    [new Pin(16, 'exp'), new Pin(16, 'sf')],
    new OutputRuleArray(([exponent, significand]) =>
        normalizeUnderflow(exponent, significand)
    ),
    depends(floatNormalizeUnderflowMission)
);


export const floatVerifyExponentMission = diagram(<const>{
    key: 'FLOAT_VERIFY_EXPONENT',
    tag: 'preview',
    unlock: true,
    inputPins: [new Pin(16, 'exp'), new Pin(16, 'sf')],
    outputPins: [new Pin(16, 'exp'), new Pin(16, 'sf')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        bitSelectNodeType, constNodeType, and16NodeType,
        inv16NodeType, selector16NodeType, isZero16NodeType, isNegNodeType,
        bundlerNodeType, splitterNodeType
    ],
    tests: [
        numericTest([7, 0b1_00000_00000], [7, 0b1_00000_00000]),
        numericTest([32, 0b1_00000_00000], [0b11111, 0b1_00000_00000]),
        numericTest([0b10000000, 0b1_00000_00000], [0b11111, 0b1_00000_00000]),
        numericTest([0xFFFF, 0b1_00000_00000], [0b11111, 0b1_00000_00000]),
    ],
    score: undefined, // TODO
});

export const floatVerifyExponentNodeType = component('verify exponent',
    'FLOAT_VERIFY_EXPONENT',
    [new Pin(16, 'exp'), new Pin(16, 'sf')],
    [new Pin(16, 'exp'), new Pin(16, 'sf')],
    new OutputRuleArray(([exponent, significand]) =>
        verifyExponent(exponent, significand)
    ),
    depends(floatVerifyExponentMission)
);

export const floatPackMission = diagram(<const>{
    key: 'FLOAT_PACK',
    tag: 'preview',
    unlock: true,
    inputPins: [bit('sgn'),
    new Pin(16, 'exp'),
    new Pin(16, 'sf')],
    outputPins: [new Pin(16, 'fp')],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType,
        floatNormalizeOverflowNodeType, floatNormalizeUnderflowNodeType, floatVerifyExponentNodeType,
        incNodeType, add16NodeType,
        inv16NodeType, shlNodeType, isZero16NodeType, isNegNodeType,
        bundlerNodeType, splitterNodeType, selector16NodeType
    ],
    tests: [
        //numericTest([0, 15, 0], [0b0_01111_00000_00000]),
        numericTest([0, 0b00001, 0b1_00000_00000], [0b0_00001_00000_00000]),
        numericTest([1, 0b11110, 0b1_11111_11111], [0b1_11110_11111_11111]),
        // special case: 0
        numericTest([0, 0, 0], [0]),
        // special case: exponent overflow
        numericTest([0, 0b100000, 0b1_00000_00000], [0b0_11111_00000_00000]),
        numericTest([1, 0b1000000, 0b1_00000_00000], [0b1_11111_00000_00000]),
    ],
    score: undefined, // TODO
});

// TODO: Rename to normalize?
export const packFloatNodeType = component('f.pack',
    'FLOAT_PACK',
    [bit('sgn'), new Pin(16, 'exp'), new Pin(16, 'sf')],
    [word('')],
    new OutputRuleArray(([sign, exp, significand]) =>
        [normalizeAndPack(sign as Bit, exp, significand)]
    ),
    depends(floatPackMission)
);

export const floatMultiplyPackedMission = diagram(<const>{
    key: 'FLOAT_MUL',
    tag: 'preview',
    unlock: true,
    inputPins: [new Pin(16, 'a'), new Pin(16, 'b')],
    outputPins: [new Pin(16)],
    palette: [
        floatMulUnpackedNodeType, unpackFloatNodeType, packFloatNodeType,
        shrNodeType, barrelShr22NodeType, bitSelectNodeType,
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType, incNodeType,
        add16NodeType, sub16NodeType,
        inv16NodeType, shlNodeType, isZero16NodeType, isNegNodeType,
        bundlerNodeType, splitterNodeType, selector16NodeType
    ],
    tests: [
        numericTest([fromJs(1), fromJs(1)], [fromJs(1)]),
        numericTest([fromJs(1), fromJs(27)], [fromJs(27)]),
        numericTest([fromJs(9), fromJs(27)], [fromJs(9 * 27)]),
        numericTest([fromJs(2048), fromJs(4)], [fromJs(2048 * 4)]),
        numericTest([fromJs(2047), fromJs(32)], [fromJs(2047 * 32)]),
        // sign
        numericTest([fromJs(-2047), fromJs(32)], [fromJs(-2047 * 32)]),
        numericTest([fromJs(-2047), fromJs(-32)], [fromJs(2047 * 32)]),
        // exponent overflow
        numericTest([fromJs(2047), fromJs(2047)], [0b0_11111_00000_00000]),
        // special case: 0
        numericTest([fromJs(0), fromJs(27)], [0b0_00000_00000_00000]),
    ],
    score: undefined, // TODO
});

export const floatAddMission = diagram(<const>{
    key: 'FLOAT_ADD',
    tag: 'preview',
    unlock: true,
    inputPins: [new Pin(16, 'a'), new Pin(16, 'b')],
    outputPins: [new Pin(16)],
    palette: [
        floatAlignNodeType,
        floatAddSignedMagnitudeNodeType,
        unpackFloatNodeType, packFloatNodeType,
        shrNodeType, barrelShr22NodeType, bitSelectNodeType,
        nandNodeType, invNodeType, zeroNodeType, orNodeType, andNodeType, xorNodeType, incNodeType,
        add16NodeType, sub16NodeType,
        inv16NodeType, shlNodeType, isZero16NodeType, isNegNodeType,
        bundlerNodeType, splitterNodeType, selector16NodeType
    ],
    tests: [
        numericTest([fromJs(1), fromJs(1)], [fromJs(2)]),
        numericTest([fromJs(2), fromJs(-3)], [fromJs(-1)]),
        numericTest([fromJs(2), fromJs(-0.5)], [fromJs(1.5)]),
    ],
    score: undefined, // TODO
});

