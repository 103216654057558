import './StateViewComponent.css';
import { ClockComponent } from './ClockComponent';
import { MemoryView } from './MemoryView';
import { BinaryComponent } from '../node/BinaryComponent';
import { toHexWord } from '../../../common/hex';
import { toDecimalString } from '../../../common/arithmetics';
import { CompositeStateView, MemoryStateView } from '../../stateViews';
import { StateView } from '../../componentType';
import { ClockStateView } from '../../missions/clockNodeType';

export function StateViewComponent(props: { stateView: StateView; }) { 
	const stateView = props.stateView;

	return (<span className='state-view'>
{ stateView instanceof CompositeStateView && (
    <>
    {(stateView.stateViews).map(tuple => (
        <div className='labelled-state'>
        <b className='state-component-label'>{tuple[0]}</b>
        <StateViewComponent stateView={tuple[1]} />
    </div>))} 
</>)} 
{ stateView.kind==='Bit' && (
    <span className='state'>{stateView.getState()}</span>
)} 
{ stateView.kind==='Word' && (
    <>
    <div>
        <BinaryComponent value={stateView.getState()} />
    </div>
    <div>
        <span className='state-type'>Hex</span>
        <span className='state'>{ toHexWord(stateView.getState())}</span>

        <span className='state-type'>Dec</span>
        <span className='state'>{toDecimalString(stateView.getState())}</span>
    </div>
</>)} 
{ stateView instanceof MemoryStateView && (
    <MemoryView stateView={stateView} />)} 
{ stateView.kind==='WordCompact' && (
    <BinaryComponent value={stateView.getState()} />)} 
{ stateView.kind==='InstructionCompact' && (
    <BinaryComponent value={stateView.getState()} />)} 
{ stateView instanceof ClockStateView && (
    <ClockComponent stateView={stateView} />)} 
</span>); 
}

