import { CircuitState, ComponentInstanceState } from './circuitState';
import { CircuitStructure, ComponentInstance, DiagramEvents, EdgeInputConnector, EdgeOutputConnector } from './circuitStructure';
import { ComponentCounter, ComponentInternalState, ComponentType, NodeTypeEvents, StateView } from './componentType';
import { CustomComponentPersistence, PinGroupPersistence, PinPersistence } from './customComponentPersistence';
import { CustomComponentRepositoryService } from './customComponentRepository.service';
import { DiagramSerializer } from './diagramPersistence';


class CustomComponentInternalState implements ComponentInternalState {
    readonly diagram: CircuitState;
    readonly stateView?: StateView;
    reset() {
        this.diagram.resetState();
    }
    constructor(diagramStructure: CircuitStructure) {
        this.diagram = new CircuitState(diagramStructure);
    }
    resolveOutputs(node: ComponentInstanceState) {
        const inputs = node.inputConnectorStates.map(c => c.numState);
        return this.resolve(inputs) as number[];
    }
    resolve(inputs: number[]) {
        return this.diagram.resolveInputs(inputs);
    }
}

export class CustomComponentType implements ComponentType, DiagramEvents {
    readonly eventListeners = new Array<NodeTypeEvents>();
    readonly hasPersistentState = false;
    readonly countNode = true;

    constructor(readonly key: string, public name: string, readonly diagram: CircuitStructure,
        readonly repository: CustomComponentRepositoryService) {
        this.diagram.eventListeners.push(this);
    }

    static create(key: string, name: string, repository: CustomComponentRepositoryService) {
        const diagram = new CircuitStructure([], []);
        return new CustomComponentType(key, name, diagram, repository);
    }

    get inputs() { return this.diagram.inputNodes.map(i => i.pin); }
    get outputs() { return this.diagram.outputNodes.map(i => i.pin); }

    createInternalState(_node: ComponentInstanceState) {
        return new CustomComponentInternalState(this.diagram);
    }
    /** All custom components this components contains (including itself) */
    containedCustomComponents(): string[] {
        const subComponents =
            this.diagram.nodes
            .map(n => n.nodeType)
            .filter((nt): nt is CustomComponentType => nt instanceof CustomComponentType)
            .flatMap(cnt => cnt.containedCustomComponents());
        return [this.key].concat(subComponents);
    }
    get hasInternalState() {
        return this.diagram.hasState;
    }
    save() {
        const data = this.serialize();
        this.repository.saveComponent(this.key, data);
    }

    serialize() {
        const diagramPersister = new DiagramSerializer();
        const diagram = diagramPersister.persistDiagram(this.diagram);
        const inputPins = this.diagram.inputGroups.map(g => ({
            pins: g.nodes.map(n => ({ label: n.pin.name, width: n.pin.width } as PinPersistence))
        } as PinGroupPersistence));
        const outputPins = this.diagram.outputGroups.map(g => ({
            pins: g.nodes.map(n => ({ label: n.name, width: n.width } as PinPersistence))
        } as PinGroupPersistence));
        return {
            key: this.key,
            name: this.name,
            inputs: inputPins,
            outputs: outputPins,
            diagram: diagram
        } as CustomComponentPersistence;
    }

    deleteAllComponentsOfType(nodeType: CustomComponentType) {
        this.diagram.deleteAllComponentsOfType(nodeType);
    }

    /* diagram events - fired from the underlying diagram builder */
    nodeAdded(_node: ComponentInstance) { }
    nodeDeleted(_node: ComponentInstance) { }
    inputPinAdded(cnn: EdgeOutputConnector) {
        this.eventListeners.forEach(el => el.inputPinAdded(cnn.pin));
        this.save();
    }
    outputPinAdded(cnn: EdgeInputConnector) {
        this.eventListeners.forEach(el => el.outputPinAdded(cnn.pin));
        this.save();
    }
    inputPinDeleted(cnn: EdgeOutputConnector) {
        this.eventListeners.forEach(el => el.inputPinDeleted(cnn.pin));
        this.save();
    }
    outputPinDeleted(cnn: EdgeInputConnector) {
        this.eventListeners.forEach(el => el.outputPinDeleted(cnn.pin));
        this.save();
    }
    componentCount(counter: ComponentCounter): ComponentCounter {
        return counter.countForNodes(this.diagram.nodes);
    }
    getPersistentState = (_node: ComponentInstance): unknown => undefined;
    initPersistentState = (): unknown => undefined;
    restorePersistentState = (_data: unknown): unknown => undefined;
}




