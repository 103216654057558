import { FunctionDefinition, Segment } from './instructions';
import { ErrorResult, Option } from '../compiler/shared';
import { CodeUnit } from './vmParse';


export class StackFrame {
    programCounter = 0;
    readonly locals: number[];
    readonly stack: number[] = [];
    constructor(public readonly func: FunctionDefinition, public readonly args: number[]) {
        const localsCount = func.localsCount;
        this.locals = new Array<number>(localsCount).fill(0);
    }
}

interface Instruction {
    type: string;
    execute(engine: Engine): void | 'Exit';
}

export class Engine {
    readonly static: number[] = [];
    readonly temps: number[] = new Array<number>(8).fill(0);
    readonly callStack: StackFrame[] = [];

    constructor(public readonly code: CodeUnit) {}

    getSegment(segment: Segment, index: number) {
        switch (segment) {
            case Segment.argument:
                return this.currentFrame.args[index];
            case Segment.local:
                return this.currentFrame.locals[index];
            case Segment.temp:
                return this.temps[index];
            case Segment.static:
                // unassigned address default to 0
                return this.static[index] ?? 0;
            case Segment.constant:
                return index;
            default:
                // Internal error, since the parser should already have verified segments
                throw new Error('segment: ' + segment);
        }
    }

    setSegment(segment: Segment, index: number, value: number) {
        switch (segment) {
            case Segment.argument:
                this.currentFrame.args[index] = value;
                break;
            case Segment.local:
                this.currentFrame.locals[index] = value;
                break;
            case Segment.temp:
                this.temps[index] = value;
                break;
            case Segment.static:
                this.static[index] = value;
                break;
            default:
                // Internal error, since the parser should already have verified segments
                throw new Error('segment: ' + segment);
        }
    }

    run(entryFunctionName: string) {
        let fun = this.code.functions.get('.toplevel');
        if (!fun) {
            fun = this.code.functions.get(entryFunctionName);
            if (!fun) {
                throw new ErrorResult(`Entry function '${entryFunctionName}' not found.`);
            }
        }
        this.callStack.push(new StackFrame(fun, []));
        while (this.step()) { /* run til end */ }
        const returnValue = this.popStack();
        return returnValue;
    }

    step() {
        const frame = this.currentFrame;
        const instructions = frame.func.instructions;
        if (frame.programCounter >= instructions.length && this.atTopFrame) {
            return false;
        }

        const instruction = instructions[frame.programCounter];
        if (instruction.type.toLowerCase() === 'return' && this.atTopFrame) {
            return false;
        }
        const status = this.execute1(instruction);
        if (status === 'Exit') {
            return false;
        }
        return true;
    }

    execute1(instruction: Instruction): void | 'Exit' {
        // increment program counter *before* executing command
        // so 'goto' can override the new address
        this.currentFrame.programCounter++;
        return instruction.execute(this);
    }

    get currentFrame() {
        return this.callStack[this.callStack.length - 1];
    }
    get atTopFrame() {
        return this.callStack.length === 1;
    }

    popStack() {
        if (this.currentFrame.stack.length === 0) {
            throw Error('Stack is empty');
        }
        return this.currentFrame.stack.pop()!;
    }
    pushStack(val: number) {
        if (val === undefined) {
            throw Error('Undefined value');
        }
        this.currentFrame.stack.push(val);
    }
    initStack() {
        
    }
}

export class RunResult {
    constructor(public result: number, public engine: Engine) {}
}

// TODO: move to engine, call runMain
export function runMain(unit: CodeUnit): Option<RunResult> {
    try {
        const engine = new Engine(unit);
        const returnValue = engine.run('main');
        return new RunResult(returnValue, engine);
    } catch (e) {
        if (e instanceof ErrorResult) {
            return e;
        }
        console.error(e);
        return new ErrorResult(`Internal execution error: ${(e as Error).message} `);
    }
}
