import { escapeMission } from './escape/escapeMission';
import { assemblerBlinkMission } from './assemblerBlinkMission';
import { initMission } from './stack/initMission';
import { pushDMission } from './stack/pushDMission';
import { popDMission } from './stack/popDMission';
import { popAMission } from './stack/popAMission';
import { popArgMission, popLocalMission, popMemoryMission, popStaticMission, pushArgMission, pushLocalMission, pushMemoryMission, pushStaticMission, pushValueMission } from './stack/memoryMissions';
import { stackAddMission, stackAndMission, stackOrMission, stackSubMission } from './stack/stackArithmeticMissions';
import { stackNegMission, stackNotMission } from './stack/notMission';
import { programMission1 } from './machineCodeMission';
import { tokenizeExpressionMission } from './tokenize/tokenizeExpressionMission';
import { expressionGrammarMission } from './grammar/expressionGrammarMission';
import { expressionCodegenMission } from './expressionCompileMission';
import { displayMission } from './displayMission';
import { MissionGroup } from './track';
import { stackEqMission, stackGtMission, stackLtMission } from './stack/stackComparisonsMission';
import { gotoMission, ifGotoMission } from './stack/gotoMission';
import { networkRecieveMission } from './networkMission';
import { callMission, functionMission, returnMission } from './stack/functionMission';

export const getSoftwareTrack = (): MissionGroup[] => [
    {
        name: 'Low level',
        missions: [
            programMission1,
            assemblerBlinkMission,
            escapeMission,
            displayMission,
            networkRecieveMission,
        ]
    },
    {
        name: 'Stack machine',
        missions: [
            initMission,
            pushDMission,
            popDMission,
            popAMission,
            pushValueMission,
            stackAddMission,
            stackSubMission,
            stackNegMission,
            stackAndMission,
            stackOrMission,
        ],
    },
    {
        name: 'High-level language',
        missions: [
            tokenizeExpressionMission,
            expressionGrammarMission,
            expressionCodegenMission,
        ],
    },
    {
        name: 'Conditionals',
        missions: [
            stackEqMission,
            stackGtMission,
            stackLtMission,
            stackNotMission,
            gotoMission,
            ifGotoMission,
            /*
            tokenizeControlMission,
            controlGrammarMission,
            controlCodegenMission
            */
        ],
    },
    {
        name: 'Memory',
        missions: [
            pushMemoryMission, 
            popMemoryMission, 
            pushStaticMission,
            popStaticMission,
            //pushSegmentOffsetMission,
            //popSegmentOffsetMission
        ],
    },
    /*
    {
        name: 'Calling conventions',
        missions: [
            callMission,
            functionMission,
            returnMission,
            pushArgMission,
            popArgMission,
            pushLocalMission,
            popLocalMission,
            functionGrammarMission,
            functionCodegenMission,
        ],
    },
    */
];