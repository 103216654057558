import { ComponentInstanceState, InputConnectorState, OutputConnectorState } from "../../diagram/circuitState";
import { DiagramMissionState } from "../../diagram/diagramMissionState";
import { Hint, HintPosition } from "../../diagram/hint";

/*
    Hint providers shows hints for levels.
    The provider for level shows hints based on events
*/

export class DiagramObserver {
    ready() { }
    nodeAdded(node: ComponentInstanceState) {}
    connectModeActivated(input: InputConnectorState) { }
    connectModeCancelled() { }
    connectionCreated(input: InputConnectorState, output: OutputConnectorState) {}
    connectionDeleted(connector: InputConnectorState) {}
}

/* default - just shows all hints when the canvas is ready
    and notifies when the diagram can be validated
 */
export class StandardDiagramObserver {
    hints;
    constructor(readonly mission: DiagramMissionState, 
        readonly setHints: (hints: Hint[]) => void,
        readonly verify: (connected: boolean) => void
    ) { 
        this.hints = mission.missionType.hints ?? [];
    }
    ready() {
        this.setHints(this.hints as Hint[]);
        this.connectionsChanged();
    }
    nodeAdded(node: ComponentInstanceState) {}
    connectModeActivated(input: InputConnectorState) { }
    connectModeCancelled() { }
    connectionCreated(input: InputConnectorState, output: OutputConnectorState) {
        this.connectionsChanged();
    }
    connectionsChanged() {
        this.verify(this.isConnected());
    }
    connectionDeleted(connector: InputConnectorState) {
        this.connectionsChanged();
    }
    /* If there at least one input and at least one output has a connection
    (Does not necessarily mean there is a connection from input to output,
        but it means the diagram is reasonably complete)
    */
    isConnected() {
        const diagram = this.mission.diagram;
        return diagram.outputGroups.some(g => g.nodes.every(n => n.connection))
            && diagram.inputGroups.some(g => g.nodes.every(n => n.connector.connections.length > 0));
    }
}

/* progressively displays hints */
export class FirstMissionHints extends StandardDiagramObserver {
    dragShown = false;
    tabShown = false;
    connectShown = false;
    toggleShown = false;
    verifyShown = false;
    ready() {
        // "Drag component to canvas"
        if (!this.dragShown) {
            const hint = new Hint('drag', '.palette',
                HintPosition.Right, -10, 80);
            this.setHint(hint);
            this.dragShown = true;
        }
    }
    tapHint =  new Hint('tap', '.canvas .free-node .input-connector .triangle-outline', HintPosition.Right, 20);
    nodeAdded(node: ComponentInstanceState) { 
        if (!this.tabShown) {
            // tap/drag connector
            this.setHint(this.tapHint);
            this.tabShown = true;
        }
    }
    connectModeActivated(input: InputConnectorState) { 
        if (!this.connectShown) {
            // tap/drop on target
            const hint = new Hint('tap-end', '.input-node .output-connector', HintPosition.Left, -20);
            this.setHint(hint);
            this.connectShown = true;
        }
    }
    partiallyConnected() {
        if (!this.verifyShown) {
            // All input/output connectors connected
            this.setHints([
                new Hint('verify', '#btnCompleted', HintPosition.Left),
                new Hint('truth', '.instructions table.data', HintPosition.Right),
            ]);
            this.verifyShown = true;
        }
    }
    connectModeCancelled() {
        /* hide hint for target, but show hint for tapping connector again */
        if (!this.toggleShown) {
            this.connectShown = false;
            this.setHint(this.tapHint);
        }
    }
    connectionCreated(input: InputConnectorState, output: OutputConnectorState) { 
        super.connectionCreated(input, output);
        if (!this.toggleShown) {
            const hint =  new Hint('toggle', '.input-node', HintPosition.Left, -20);
            this.setHint(hint);
            this.toggleShown = true;
        }
        if (this.isConnected()) {
            this.partiallyConnected();
        }
    }

    setHint(hint: Hint) {
        if (!hint) {
            throw new Error();
        }
        this.setHints([hint]);
    }
}