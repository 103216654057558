export class EventEmitter2<T> {
    readonly listeners = new Array<(t: T) => void>();
    fire(t: T) {
        for (const listener of this.listeners) {
            listener(t);
        }
    }
    addListener(listener: ((obj: T) => void)) {
        this.listeners.push(listener);
    }
}
