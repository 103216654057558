import { StorageService } from '../common/storage.service';


export class Repository {
    static readonly levelsKey = 'NandGame:Levels';
    static readonly levelKey = 'NandGame:Levels:';

    constructor(readonly storage: StorageService) { }

    getLevelKey(level: string) {
        return Repository.levelKey + level;
    }

    getLevels() {
        return this.storage.getItem(Repository.levelsKey) as string[] | null;
    }

    getLevelData<T>(levelKey: string) {
        return this.storage.getItem(this.getLevelKey(levelKey)) as T | null;
    }

    saveLevel(levelKey: string, data: unknown) {
        this.storage.setItem(this.getLevelKey(levelKey), data);
    }

    saveLevels(levels: string[]) {
        this.storage.setItem(Repository.levelsKey, levels);
    }
}
