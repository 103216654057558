import { Machine } from '../../assembler/machine';
import { AssemblerPersistence } from '../assemblerMissions';
import { MissionKind } from '../../app/task';
import { Repository } from '../../app/repository';
import { MissionProgression } from '../../app/missionProgression';
import { VerificationError, VerificationOk } from '../../app/verificationResults';
import { CodeTester } from './codeTester';
import { StackMissionState } from './stackMissionState';

class PopATest extends CodeTester {
    setup(machine: Machine) {
        // Setup - value 42 on stack
        this.push(machine, 42);
        // set D - this value should not be affected
        machine.d.setImmediately(13);
    }
    verify(machine: Machine) {
        // assert A is the value which was on the stack
        const a = machine.a.get();
        if (a !== 42) {
            return new VerificationError(`Expected A to be 42. (Was ${a.toString(16)})`);
        }
        const err = this.expectStack(machine, 0);
        if (err) {
            return err;
        }
        // assert D is not corrupted
        const d = machine.d.get();
        if (d !== 13) {
            return new VerificationError(`Expected D to be unaffected. (Was changed to ${d.toString(16)})`);
        }

        return new VerificationOk();
    }
}

export const popAMissionTests = [PopATest];

export class PopAMissionState extends StackMissionState {
    tests = popAMissionTests;
}

export const popAMission = {
    key: 'STACK_POP_A',
    kind: MissionKind.Stack,
    macro: { name: 'POP_A' },
    start(repository: Repository, game: MissionProgression) {
        return new PopAMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PopAMissionState(this, repository, game, data);
    },
};
