
export class PersistentStorageService {
    setItem(key: string, value: string) {
        window.localStorage.setItem(key, value);
    }
    getItem(key: string) {
        return window.localStorage.getItem(key);
    }
    removeItem(key: string) {
        window.localStorage.removeItem(key);
    }
}

export interface StorageService {
    setItem(key: string, data: unknown): void;
    getItem(key: string): unknown;
}


export class JsonStorageService implements StorageService {
    constructor(private readonly storage: PersistentStorageService) { }

    setItem(key: string, data: unknown) {
        const json = JSON.stringify(data);
        this.storage.setItem(key, json);
    }
    getItem(key: string) {
        const jsonString = this.storage.getItem(key);
        if (jsonString == null) {
            return null;
        }
        return JSON.parse(jsonString);
    }
    removeItem(key: string) {
        this.storage.removeItem(key);
    }
}


/** Mostly used for testing - stores data in a dictionary rather than LocalStorage */
export class InMemoryStorage implements StorageService {
    data = new Map<string, string>();
    setItem(key: string, value: string) {
        this.data.set(key, value);
    }
    getItem(key: string) {
        const result = this.data.get(key);
        if (result === undefined) {
            return null;
        } else {
            return result;
        }
    }
    removeItem(key: string) {
        this.data.delete(key);
    }
}
