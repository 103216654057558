import { useContext } from 'react';
import './SuccessDialog.css';
import './popup-dialog.css';
import { Verification } from './Verification';
import { DiagramMissionState } from '../../diagram/diagramMissionState';
import { TextContext } from '../../locale/text.service';
import { GameStateContext } from '../gameState';
import { MissionItem } from '../missionProgression';
import { MissionStatus } from '../missionStatus';
import { VerificationResultSet } from '../verificationResults';
import { MissionTextLoader } from './missionTextLoader';
import { UiText } from '../../locale/UiText';
import Draggable from 'react-draggable';
import { ActiveMission } from './TopLevel';


export function GroupProgression(props: {
		currentMission: MissionItem;
		onSelectMission: (x: MissionItem) => void}) { 

    const gameState = useContext(GameStateContext);
    const textService = useContext(TextContext);

    const missionGroup = gameState.missionNavigator.getGroup(props.currentMission);
    const completedInGroup = missionGroup.missions.filter(m => m.status === MissionStatus.Complete).length;
    const missions =  missionGroup.missions;

    function getName(mission: MissionItem) {
        const texts = new MissionTextLoader(mission.mission, textService);
        return texts.title;
    }
    function selectMission(mission: MissionItem) { props.onSelectMission(mission); }
    const allCompleted =  missions.length === completedInGroup;
    const groupName =  missionGroup.name;
	const currentMission = props.currentMission;

	return (<>

    <i>Levels in <b>{missionGroup.name}</b>:</i>

    {(missions).map(mission => (
        <span key={mission.mission.key} className='mission-status'>
        { mission === currentMission && (
            <span className='list-group-item list-group-item-success'>
            <span className='marker'>
                <i className='bi bi-check-lg'></i>
            </span>
            {getName(mission)}
        </span>)} 
        { mission!==currentMission && (
            <span>{(()=>{ switch (mission.status) {
                case MissionStatus.Complete: return (
                    <button onClick={()=>selectMission(mission)} className='list-group-item list-group-item-success list-group-item-action'>
                    <span className='marker'>
                        <i className='bi bi-check-lg'></i>
                    </span>
                    {getName(mission)}
                </button>); 

                case MissionStatus.Started: return (
                    <button onClick={()=>selectMission(mission)} className='list-group-item list-group-item-action mission-status-started'>
                    <span className='marker'>
                        <i className='bi bi-x-lg'></i>
                    </span>
                    {getName(mission)}
                </button>); 

                case MissionStatus.Locked: return (
                    <span className='list-group-item list-group-item-light'>
                    <span className='marker locked'>
                        <i className='bi bi-lock-fill'></i>
                    </span>
                    {getName(mission)}
                </span>); 

                case MissionStatus.Unlocked: return (
                    <button onClick={()=>selectMission(mission)} className='list-group-item list-group-item-action mission-status-started'>
                    <span className='marker'></span>
                    {getName(mission)}
                </button>); 
            
            }})()} </span>
        )} 
    </span>))} 

    { allCompleted && (
        <div className='alert alert-success'>
        All levels in <b>{groupName}</b> completed. Well done!
    </div>)} 
    { !allCompleted && (
        <p>
        {completedInGroup} / {missions.length} levels completed.
    </p>)} 
</>); 
}


export function SuccessDialog(props: {
		testResults: VerificationResultSet;
        completeDialog: ()=>void;
        closeTestDialog: ()=>void;
        selectMission: (item: MissionItem)=>void;
        activeMission: ActiveMission;
    }) { 

    const activeMission = props.activeMission;
    const gameState = useContext(GameStateContext);
    const message =  activeMission.texts.debriefing ?? '';
    const missionState =  activeMission.state;
    const scoreText =  getScoreText();
    function getScoreText() {
        if (missionState instanceof DiagramMissionState) {
            return missionState.getScoreText(gameState.diagramSet);
        } else {
            return '';
        }
    }
    function selectMission(mission: MissionItem) { props.selectMission(mission); }
	const testResults = props.testResults;

	return (
        <Draggable cancel='button'>
<div className='popup-dialog success-dialog'>
<div className='content'>
<div className='main'>

        <div className='alert alert-success'>
            <span className='level-completed-checkmark'>
                <i className='bi bi-check-lg'></i>
            </span>
            <UiText id='level_complete' />
        </div>

        <Verification results={testResults} />

        { scoreText && (
            
            <p dangerouslySetInnerHTML={{__html: scoreText}}></p>
        )} 

        <div dangerouslySetInnerHTML={{__html: message}} className='debriefing'></div>


</div>
<div className='progression'>
        <GroupProgression currentMission={activeMission.item} onSelectMission={selectMission} />
</div>
</div>
    <div className='btn-group'>
        <button onClick={props.completeDialog} className='btn btn-primary'><UiText id='btn_next_level' /></button>
        <button onClick={props.closeTestDialog} className='btn btn-secondary'><UiText id='stay' /></button>
    </div>
</div>
</Draggable>
); 
}

