import { ComponentBuilder } from './componentBuilder';
import { ComponentType, BuiltinComponentType } from './componentType';
import { CircuitState, EdgeInputConnectorState, EdgeOutputConnectorState } from './circuitState';
import { DiagramMissionType } from './diagramMissionType';
import { CustomComponentType } from './customComponent';
import { DiagramMissionsSet } from '../app/missionProgression';
import { CustomComponents } from './customComponents';
import { ComponentTypes } from './missions/nodetypes';


export class CustomComponentBuilderModel implements ComponentBuilder {
    palette!: ComponentType[];
    diagram: CircuitState;
    canModifyPins = true;

    constructor(readonly nodeType: CustomComponentType, private readonly customComponents: CustomComponents, readonly history: DiagramMissionsSet) {
        this.updatePalette();
        this.diagram = new CircuitState(nodeType.diagram);
        this.diagram.structure.onStructureChange.addListener(() => {
            this.nodeType.save();
        });
    }

    unlockedBuiltIns() {
        return ComponentTypes.getAll()
            .filter(n => this.isUnlocked(n));
    }
    isUnlocked(node: BuiltinComponentType) {
        return !node.depends.mission || this.missionIsUnlocked(node.depends.mission);
    }
    missionIsUnlocked(mission: DiagramMissionType) {
        return this.history.isCompleted(mission);
    }
    updatePalette() {
        // cort components by name
        this.palette = this.availableComponents().sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1);
    }

    // TODO: we exclude recursive nodes when then the model is created
    // but need to update when other components have changed
    availableComponents() {
        const allowed = this.customComponents.list.filter(ct => !ct.containedCustomComponents().includes(this.nodeType.key));
        // exclude TransistorLevel components
        const builtins = this.unlockedBuiltIns().filter(n => n.diagramType === undefined);
        return (builtins as ComponentType[]).concat(allowed);
    }

    get name() { return this.nodeType.name; }
    set name(nm: string) {
        this.nodeType.name = nm;
        this.nodeType.save();
    }
    // used for persistence, should not change
    get key() { return this.nodeType.key; }

    clearCanvas() { this.diagram.clearCanvas(); }

    addInputPin(label: string, width: number) {
        this.diagram.structure.addInputPin(label, width);
        this.updateAffectedDiagrams();
    }
    addOutputPin(label: string, width: number) {
        this.diagram.structure.addOutputPin(label, width);
        this.updateAffectedDiagrams();
    }
    deleteInputPin(connector: EdgeOutputConnectorState) {
        this.diagram.structure.deleteInputPin(connector.connector);
        this.updateAffectedDiagrams();
    }
    deleteOutputPin(connector: EdgeInputConnectorState) {
        this.diagram.structure.deleteOutputPin(connector.connector);
        this.updateAffectedDiagrams();
    }
    /* Update and save diagrams affected by a pin add/remove on a custom component */
    updateAffectedDiagrams() {
        // We just update all diagrams since it is easier!
        this.history.diagrams
            .forEach(d => d.structure.notifyStructureChanged());
    }

    // called when component editor is activate. Update palette with new / changed components
    activate() {
        this.updatePalette();
    }
}
