import { depends, atomic, transparent } from './dependency';
import { PinGroup, bit, word } from '../pins';
import { bitArrayToNumber, numberToBitArray } from '../../common/bits';
import { HintPosition, Hint } from '../hint';
import * as Word16 from '../../common/arithmetics';
import { logicalXor } from '../../common/logic';
import { component } from './baseNodeType';
import { OutputRuleArray, OutputRuleBinary, OutputRuleBooleanArray } from './outputRules';
import { diagram, Truth } from './missions';


export const nandNodeType = component('nand',
    'NAND',
    [bit('a'), bit('b')],
    [bit('')],
    new OutputRuleBinary((a, b) => !(a && b)),
    atomic()
);

export const invMission = diagram(<const>{
    key: 'INV',
    inputPins: [bit('')],
    outputPins: [bit('')],
    hints: [
        new Hint('0', '.palette',
            HintPosition.Right,
            0,
            80)
    ] as Hint[],
    palette: [nandNodeType],
    truth: [
        [[false], [true]],
        [[true], [false]],
    ] as Truth,
    score: { min: 1, nands: 1 }
});

export const invNodeType = component('inv',
    'INV',
    [bit()],
    [bit()],
    new OutputRuleBooleanArray(([a]) => [!a]),
    depends(invMission),
);

export const andMission = diagram(<const>{
    key: 'AND',
    inputPins: [bit('a'), bit('b')],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType],
    truth: [
        [[false, false], [false]],
        [[false, true], [false]],
        [[true, false], [false]],
        [[true, true], [true]],
    ] as Truth,
    score: { min: 2, nands: 2 }
});

export const andNodeType = component('and',
    'AND',
    [bit('a'), bit('b')],
    [bit()],
    new OutputRuleBinary((a, b) => a && b),
    depends(andMission)
);

export const orMission = diagram(<const>{
    key: 'OR',
    inputPins: [bit('a'), bit('b')],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType, andNodeType],
    truth: [
        [[false, false], [false]],
        [[false, true], [true]],
        [[true, false], [true]],
        [[true, true], [true]],
    ] as Truth,
    score: { min: 3, nands: 3 },
});

export const orNodeType = component('or',
    'OR',
    [bit('a'), bit('b')],
    [bit('')],
    new OutputRuleBinary((a, b) => a || b),
    depends(orMission)
);

export const xorMission = diagram(<const>{
    key: 'XOR',
    inputPins: [bit('a'), bit('b')],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType],
    truth: [
        [[false, false], [false]],
        [[false, true], [true]],
        [[true, false], [true]],
        [[true, true], [false]],
    ] as Truth,
    score: { min: 3, nands: 4 }
});

export const xorNodeType = component('xor',
    'XOR',
    [bit('a'), bit('b')],
    [bit('')],
    new OutputRuleBinary((a, b) => logicalXor(a, b)),
    depends(xorMission)
);


// not used
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
// @ts-ignore: unused
const and2Mission = diagram(<const>{
    key: 'AND2',
    inputPins: [
        new PinGroup('a', [bit('1'), bit('0')]),
        new PinGroup('b', [bit('1'), bit('0')])
    ],
    outputPins: [bit('q1'), bit('q0')],
    palette: [andNodeType],
    tests: [
        // todo: permutations
    ],
    score: { min: 2 /* TODO */ }
});

export const and16NodeType = component('and 16',
    'AND16',
    [word('A'), word('B')],
    [bit()],
    new OutputRuleArray(([a, b]) => [Word16.and(a, b)]),
    depends(andMission, 16, false)
);

export const or16NodeType = component('or 16',
    'OR16',
    [word('A'), word('B')],
    [bit()],
    new OutputRuleArray(([a, b]) => [Word16.bitwiseOr(a, b)]),
    depends(andMission, 16, false)
);

export const inv16NodeType = component('inv 16',
    'INV16',
    [word()],
    [word()],
    new OutputRuleArray(([a]) => [0xFFFF - a]),
    depends(invMission, 16, false)
);

export const splitterNodeType = component('splitter',
    'SPLIT16',
    [word()],
    [
        bit('15'), bit('14'), bit('13'), bit('12'),
        bit('11'), bit('10'), bit('9'), bit('8'),
        bit('7'), bit('6'), bit('5'), bit('4'),
        bit('3'), bit('2'), bit('1'), bit('0')
    ],
    new OutputRuleArray(([wrd]) => numberToBitArray(wrd, 16)),
    transparent(),
    'splitter'
);

export const bundlerNodeType = component('bundler',
    'BUNDLE16',
    [
        bit('15'), bit('14'), bit('13'), bit('12'),
        bit('11'), bit('10'), bit('9'), bit('8'),
        bit('7'), bit('6'), bit('5'), bit('4'),
        bit('3'), bit('2'), bit('1'), bit('0')
    ],
    [word()],
    new OutputRuleArray(bits => [bitArrayToNumber(bits)]),
    transparent(),
    'bundler'
);

export const demuxMission = diagram(<const>{
    key: 'DEMUX',
    inputPins: [bit('s'), bit('d')],
    outputPins: [bit('c1'), bit('c0')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType],
    // s, d - c1, c0
    truth: [
        [[false, false], [false, false]],
        [[false, true], [false, true]],
        [[true, false], [false, false]],
        [[true, true], [true, false]],
    ] as Truth,
    score: { min: 2, nands: 5 },
});

export const demuxNodeType = component('switch',
    'DEMUX',
    [bit('s'), bit('d')],
    [bit('c1'), bit('c0')],
    new OutputRuleBooleanArray(([s, d]) => [s && d, (!s) && d]),
    depends(demuxMission)
);

export const selectorMission = diagram(<const>{
    key: 'MULTIPLEXER',
    inputPins: [bit('s'), bit('d1'), bit('d0')],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType],
    truth: [
        [[false, false, false], [false]],
        [[false, true, false], [false]],
        [[false, false, true], [true]],
        [[false, true, true], [true]],
        [[true, false, false], [false]],
        [[true, false, true], [false]],
        [[true, true, false], [true]],
        [[true, true, true], [true]],
    ] as Truth,
    score: { min: 4, nands: 4 },
});

export const selectorNodeType = component('select',
    'SELECT',
    [bit('s'), bit('d1'), bit('d0')],
    [bit('')],
    new OutputRuleArray(arr => [arr[1 + 1 - arr[0]]]),
    depends(selectorMission)
);

export const selector16NodeType = component('select 16',
    'SELECT16',
    [bit('s'), word('D1'), word('D0')],
    [word('')],
    new OutputRuleArray(arr => [arr[1 + 1 - arr[0]]]),
    depends(selectorMission, 16, false)
);



// Not used
export const decoderMission = diagram(<const>{
    key: 'DECODER',
    inputPins: [bit('')],
    outputPins: [bit('c1'), bit('c0')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType],
    truth: [
        [[false], [false, true]],
        [[true], [true, false]],
    ] as Truth,
    score: { min: 2 }
});


// Not used
export const decoderNodeType = component('decoder',
    'DECODER',
    [bit('')],
    [bit('c1'), bit('c0')],
    new OutputRuleBooleanArray(([a]) => [a, !a]),
    depends(decoderMission)
);
