
export function toHex(num: number, padDigits: number) {
    const hex = num.toString(16);
    return zeroPad(hex, padDigits);
}

function zeroPad(numStr: string, padDigits: number) {
    while (numStr.length < padDigits) { 
        numStr = '0' + numStr;
    }
    return numStr;
}

export const toHexWord = (num: number) => toHex(num, 4);

export const fromHex = (hex: string) => parseInt(hex, 16);

export const toBinWord = (num: number) => toBin(num, 16);

function toBin(num: number, padDigits: number) {
    const bin = num.toString(2);
    return zeroPad(bin, padDigits);
}
