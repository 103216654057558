import { MissionProgression } from '../app/missionProgression';
import { Repository } from '../app/repository';
import { MissionKind } from '../app/task';
import { CodegenMissionPersistence, CodegenMissionState } from './codegenMissionState';
import { expressionTests } from './grammarTests';


class ExpressionCodegenMissionState extends CodegenMissionState {
    startSymbol = 'Expression'; 
    getInitialSource() {
        return '2 + 2';
    }
    tests = expressionTests;
}

export const expressionCodegenMission = {
    key: 'CODEGEN_EXPRESSION',
    kind: MissionKind.Compiler,
    start(repository: Repository, game: MissionProgression) {
        return new ExpressionCodegenMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as CodegenMissionPersistence;
        return new ExpressionCodegenMissionState(this, repository, game, data);
    },
};
