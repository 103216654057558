import { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Register } from '../assembler/machine';
import { toHexWord } from '../common/hex';

import './RegisterComponent.css';

export function IdeWord(props: { value: number }) {
    const [randomID, _setRandomID] = useState(String(nextId()))
    const value = props.value;
    const hex = toHexWord(value);
    const neg = value >= 0x8000 ? <>or {0x10000 - value}</> : null;
    const binary = value.toString(2);
    return (
        <span>
            <span className="state" data-tip data-for={randomID}>
                {hex}
            </span>
            <ReactTooltip id={randomID}>
                <pre>
                    <div>Hex: {hex} </div>
                    <div>
                        Decimal: {value} {neg}
                    </div>
                    <div>Binary: {binary}</div>
                </pre>
            </ReactTooltip>
        </span>
    );
}

// generate unique Id's for tootips 
let idCounter = 0;
const nextId = () => idCounter++;  

export function IdeAddress(props: { address: number }) {
    return (
        <div>
            <IdeWord value={props.address} />
        </div>
    );
}

export function IdeRegister(props: { register: Register }) {
    const change = props.register.hasPendingChange ? (
        <span className="will-change">
            <span className="will-change-text">Will change to:</span>
            <IdeWord value={props.register.nextState} />
        </span>
    ) : null;
    return (
        <span>
            <IdeWord value={props.register.get()} />
            {change}
        </span>
    );
}
