import { useState } from 'react';
import { GrammarSet } from '../../compiler/earley';
import { SyntaxNode } from '../../compiler/parse';
import { CodeEditor } from '../../compiler/SourceCodeEditor';
import { getTerminalSymbols, PatternType, TokenAction, tokenize, TokenSet } from '../../compiler/tokenize';
import { Tokenized, LexerRulesComponent, TokenSpecification } from '../../compiler/LexerRules';
import { UISegment } from '../../compiler/UiSection';
import { ExpressionGrammarState } from './expressionGrammarMission';
import { ErrorResult, Option } from '../../compiler/shared';
import { parse } from '../../compiler/parse';
import { SyntaxTreeComponent } from '../../compiler/SyntaxTreeComponent';
import { SyntaxRule, SyntaxRules, validateGrammarRules } from '../../compiler/SyntaxRules';

type GrammarState = {
    source: string;
    lexical: TokenSpecification[];
    tokenSet: TokenSet;
    rules: SyntaxRule[];
    parsedGrammar: Option<GrammarSet>;
    syntaxTree: Option<SyntaxNode>;
};

export function GrammarMissionComponent(props: { missionState: ExpressionGrammarState }) {
    const missionState = props.missionState;
    const compilerConfig = missionState.compilerState;
    const [state, setState] = useState(() => {
        const source = missionState.source;
        const lexical = compilerConfig.lexical;
        const rules = compilerConfig.rules;
        return getState({} as GrammarState, {
            source: source,
            lexical: lexical,
            rules: rules,
        });
    });
    /*
        Cascade state changes 
        (e.g a change to grammer updates syntax tree, a change to syntax tree updates codegen etc.)
    */
    function getState(st: Readonly<GrammarState>, updates: Partial<GrammarState>) {
        if (updates.lexical || updates.source) {
            const lexical = updates.lexical ?? st.lexical;
            const source = updates.source ?? st.source;
            const validatedLexical = lexical.filter(l => l.isValid);
            updates.tokenSet = tokenize(source, validatedLexical);
            //updates.source = source;
        }
        if (updates.lexical || updates.rules) {
            const lexical = updates.lexical ?? st.lexical;
            const grammar = updates.rules ?? st.rules;
            const validatedLexical = lexical.filter(l => l.isValid);
            const terminals = getTerminalSymbols(validatedLexical);
            updates.parsedGrammar = validateGrammarRules(grammar, terminals);
        }
        if (updates.tokenSet || updates.parsedGrammar) {
            const tokenSet = updates.tokenSet ?? st.tokenSet;
            const parsedGrammar = updates.parsedGrammar ?? st.parsedGrammar;
            if (tokenSet.isError) {
                updates.syntaxTree = new ErrorResult('Invalid input - token error.');
            } else if (parsedGrammar instanceof ErrorResult) {
                updates.syntaxTree = new ErrorResult('Invalid input - grammar error.');
            } else {
                updates.syntaxTree = parse(props.missionState.startSymbol, tokenSet.tokens, parsedGrammar);
            }
        }
        return updates as GrammarState;
    }
    function updateState(updates: Partial<GrammarState>) {
        missionState.save();
        setState(st => ({ ...st, ...getState(st, updates) }));
    }
    function onTokenAdd() {
        const nextId = TokenSpecification.nextId(compilerConfig.lexical);
        const newToken = new TokenSpecification(nextId, PatternType.Pattern, '', TokenAction.Literal, '');
        compilerConfig.lexical = [...compilerConfig.lexical, newToken];
        // does not call updateState since adding an empty rule does not affect tokenization
        setState(st => ({ ...st, lexical: compilerConfig.lexical }));
    }
    function onTokenChange(token: TokenSpecification) {
        compilerConfig.lexical = compilerConfig.lexical.map(l => (l.id === token.id ? token : l));
        updateState({ lexical: compilerConfig.lexical });
    }
    function onTokenDelete(id: number) {
        compilerConfig.lexical = compilerConfig.lexical.filter(item => item.id !== id);
        updateState({ lexical: compilerConfig.lexical });
    }
    function onSourceChange(value: string) {
        updateState({ source: value });
    }
    function onRuleChanged(index: number, newRule: SyntaxRule) {
        compilerConfig.rules = compilerConfig.rules.map((item, ix) => (ix === index ? newRule : item));
        // TODO: compilerConfig.save();
        updateState({ rules: compilerConfig.rules });
    }
    function onAddRule() {
        const newRule = new SyntaxRule('', '');
        compilerConfig.rules = [...compilerConfig.rules, newRule];
        // TODO: compilerConfig.save();

        // does not call updateState since adding an empty rule does not affect parsing
        setState(st => ({ ...st, rules: compilerConfig.rules }));
    }
    function onDeleteRule(index: number) {
        compilerConfig.rules = compilerConfig.rules.filter((_item, ix) => ix !== index);
        // TODO: compilerConfig.save();
        updateState({ rules: compilerConfig.rules });
    }
    function sourceCodeError() {
        return state.tokenSet.error ? state.tokenSet.error : state.syntaxTree instanceof ErrorResult ? state.syntaxTree : undefined;
    }
    return (
        <div>
            <UISegment title="Source code">
                <CodeEditor source={state.source} error={sourceCodeError()} onCodeChange={onSourceChange} />
            </UISegment>
            <UISegment title="Token definitions">
                <LexerRulesComponent
                    tokens={state.lexical}
                    onTokenAdd={onTokenAdd}
                    onTokenDelete={onTokenDelete}
                    onTokenChange={onTokenChange}
                />
            </UISegment>
            <UISegment title="Tokens">
                <Tokenized tokenSet={state.tokenSet} />
            </UISegment>

            <h3>Grammar</h3>
            <SyntaxRules
                rules={state.rules}
                showCodegen={false}
                onAddRule={onAddRule}
                onDeleteRule={onDeleteRule}
                onRuleChanged={onRuleChanged}
                parsedGrammar={state.parsedGrammar}
            />
            <h3>Syntax tree</h3>
            <SyntaxTreeComponent syntaxTree={state.syntaxTree} />
        </div>
    );
}
