import { VerificationError, VerificationOk } from '../../app/verificationResults';
import { diagram, DiagramAdapter } from './missions';
import { nandNodeType, invNodeType, andNodeType, orNodeType } from './logicMissions';
import { TestCase, VerificationSubjectAdapter, TestWrapper, RequiredComponentTestCase } from '../verification';
import { fibMachineCode } from '../../assembler/testcode';
import { controlUnitNodeType, ExecutionEngineState } from './controlUnitMission';
import { clockNodeType, ClockState } from './clockNodeType';
import { romNodeType, RomState } from './romNodeType';
import { counterNodeType } from './counterMission';
import { CompositeState, cpuStateNodeType } from './combinedStateMission';


/// Replaces and restores the ROM state
class CpuTestWrapper extends TestWrapper {
    savedUserProgram?: number[];
    getProgramState(adapter: VerificationSubjectAdapter) {
        const romNode = adapter.findInternalNode(romNodeType);
        if (romNode) {
            const romState = romNode.internalState as RomState;
            return romState.program;
        }
        return undefined;
    }
    setup(adapter: VerificationSubjectAdapter) {
        // Replace the state of the ROM component
        const rom = this.getProgramState(adapter);
        if (rom) {
            this.savedUserProgram = rom.words;
            rom.words = fibMachineCode();
        }
    }
    restore(adapter: VerificationSubjectAdapter) {
        const rom = this.getProgramState(adapter);
        if (rom) {
            rom.words = this.savedUserProgram!;
            this.savedUserProgram = undefined;
        }
    }
}

class CpuTest implements TestCase {
    verify(adapter: VerificationSubjectAdapter) {
        const d = (adapter as DiagramAdapter).diagram;

        /*
         * We locate the components 'clock' and 'execution engine'
         * (this is actually cheating since the user might not use those exact components)
         */

        const clockComponent = d.nodes.find(n => n.nodeType === clockNodeType)!;
        if (!clockComponent) {
            return new VerificationError(`A clock component is required in the solution.`);
        }
        const clockState = clockComponent.internalState as ClockState;

        /*  Diagram must have eiter a controller or memory unit, so we can test ram
         */
        const memNodes = d.nodes.filter(n => n.nodeType === controlUnitNodeType || n.nodeType === cpuStateNodeType);
        if (memNodes.length === 0) {
            return new VerificationError(`A memory unit is required in the solution.`);
        }
        if (memNodes.length > 1) {
            return new VerificationError(`Only one memory unit is allowed in the solution.`);
        }
        const memNode = memNodes[0];
        let ram;
        if (memNode.nodeType === controlUnitNodeType) {
            const exeState = memNode.internalState as ExecutionEngineState;
            ram = exeState.state.ramState;
        } else if (memNode.nodeType === cpuStateNodeType) {
            const memState = memNode.internalState as CompositeState;
            ram = memState.ramState;
        } else {
            throw new Error();
        }

        // Reset state of RAM and clock
        d.resetState();

        // run 1000 clock cycles
        for (let i = 0; i < 1000; i++) {
            clockState.tick();
        }

        // Check that Fibonacci sequence is generated in RAM
        const fib = [1, 1, 2, 3, 5, 8, 13];
        for (let i = 0; i < fib.length; i++) {
            const expected = fib[i];
            const actual = ram.peek(i);
            if (actual !== expected) {
                return new VerificationError(`Expected ram at ${i} to be ${expected} but was: ${actual} `);
            }
        }
        return new VerificationOk();
    }
}

export const cpuMission = diagram(<const>{
    key: 'CPU2',
    inputPins: [],
    outputPins: [],
    palette: [
        nandNodeType, invNodeType, andNodeType, orNodeType, romNodeType, counterNodeType, controlUnitNodeType, clockNodeType
    ],
    tests: [
        new RequiredComponentTestCase(romNodeType, 'ROM component'),
        new RequiredComponentTestCase(clockNodeType, 'clock component'),
        new CpuTestWrapper(new CpuTest())],
    score: { min: 4 }
});
