import { useState } from 'react';
import { AppControls } from '../../engine/controls/ControlsComponent';
import { EngineComponent } from '../../engine/EngineComponent';
import { AssemblerEditorComponent } from '../../ide/editor/EditorComponent';
import { StackMission, StackMissionState } from './stackMissionState';
import { MacroList } from './MacroList';
import './StackMissionComponent.css';
import { ConstantsComponent } from './Constants';
import { MissionItem } from '../../app/missionProgression';
import { GameState } from '../../app/gameState';
import { Machine } from '../../assembler/machine';
import { HexInput } from '../../diagram/components/node/HexInput';
import { initMission } from './initMission';
import { WithHelpBox } from './WithHelpBox';
import { PlaceholderValues } from '../../assembler/assembler';

function MacroParameters(props: { mission: StackMission }) {
    const params = props.mission.macro.parameters;
    if (params && params.length > 0) {
        const elems = params.map((p, ix) => <span key={ix}>{p.name} </span>);
        return <div>Placeholders: {elems}</div>;
    } else {
        return <></>;
    }
}

export function StackMissionComponent({missionState, gameState, selectMission}: {
    missionState: StackMissionState;
    gameState: GameState;
    selectMission: (m: MissionItem) => void;
}) {
    const [state, setState] = useState({});
    function onTick() {
        const complete = missionState.controller.tick();
        setState(st => ({ ...st }));
        return complete;
    }
    function onReset() {
        missionState.controller.reset();
        setState(st => ({ ...st }));
    }

    function updated() {
        setState(st => ({ ...st }));
    }

    function setArg(name: string, value: number) {
        missionState.placeholders.set(name, value);
        missionState.editorBackend.reparse();
        setState(st => ({ ...st }));
    }

    return (
        <div className="stack-ide">
            <div>
                <b>Available macros</b>
                <MacroList history={gameState.missionProgression} selectMission={selectMission} 
                    currentMission={gameState.currentMission} />
            </div>
            <div className="ide">
                <div>
                    <div>
                        <b>{missionState.mission.macro.name}</b>
                    </div>
                    <MacroParameters mission={missionState.mission} />
                    <div style={{ height: '300px' }}>
                        <AssemblerEditorComponent editorBackend={missionState.editorBackend} />
                    </div>
                </div>
                <div className="machine-column">
                    <ManualTestToolbox machine={missionState.machine} updated={updated} setArg={setArg}
                        placeholders={missionState.placeholders}
                        missionState={missionState}  />
                    <div className="machine">
                        <div className="machine-header">
                            <p>
                                <b>computer</b>
                            </p>
                            <AppControls 
                                onTick={onTick} 
                                onReset={onReset} 
                                isFinished={missionState.controller.isFinished}
                                />
                        </div>
                        <div className="engine">
                            <EngineComponent machine={missionState.machine} showRam showStack
                                constantsProvider={gameState.missionProgression.sharedConstants} />
                        </div>
                    </div>
                </div>
                <div>
                    <ConstantsComponent constants={gameState.missionProgression.sharedConstants} updated={updated} />
                </div>
            </div>
        </div>
    );
}


function ManualTestToolbox({machine, missionState, updated, placeholders, setArg}: {
        updated: () => void; 
        setArg: (name: string, value: number) => void;
        machine: Machine, 
        placeholders: PlaceholderValues,
        missionState: StackMissionState 
    }) {
        const mission = missionState.mission;

    function initStack() {
        machine.ram.pokeImmediately(0, 0x100);
        updated();
    }
    function setD(value: number) {
        machine.d.setImmediately(value);
        updated();
    }
    function setA(value: number) {
        machine.a.setImmediately(value);
        updated();
    }
    function push(value: number) {
        machine.ram.pokeImmediately(machine.ram.get(0), value);
        machine.ram.pokeImmediately(0, machine.ram.get(0) + 1);
        updated();
    }
    /* dont show tools on the init stack mission */
    const isInitMission = mission === initMission;
    if (isInitMission) {
        return null;
    }
    return (
    <>
    <div className='manual-test-toolbox'>

        <WithHelpBox>
            Useful functions when manually testing the code. 
            For example you can initialize the stack and set up registers before running the code.  
        </WithHelpBox>

        <div><b>Test tools</b></div>

        {placeholders.names.length > 0 && <MacroArguments placeholders={placeholders} setArg={setArg} />}
        <div><button onClick={initStack} className='btn btn-secondary'>Init Stack</button></div>
        <TestNum text='Set A register' defaultValue={17} execute={setA} />
        <TestNum text='Set D register' defaultValue={42} execute={setD} />
        <TestNum text='Push' defaultValue={42} execute={push} />
    </div>
    </>);
}

function MacroArguments({placeholders, setArg}: {placeholders: PlaceholderValues, setArg: (name: string, value: number) => void}) {
    if (placeholders.placeholders.length === 0) {
        return null;
    }
    const args = placeholders.placeholders.map(p => 
        <div key={p.name}>
            <b>{p.name}</b><>: </>  
            <HexInput 
                value={placeholders.get(p.name) ?? 0} 
                update={value=>setArg(p.name, value)} />
        </div>);
    return (<>
        <div>Macro replacements:</div>
        {args}
    </>);
}

function TestNum({text, defaultValue, execute}: {text: string, defaultValue: number, execute: (x: number) => void}) {
    const [state, setState] = useState(defaultValue);
    return <div className='test-operation'>
        <button onClick={()=>execute(state)} className='btn btn-secondary'>{text}</button>
        <HexInput value={defaultValue} update={val=>setState(val)} />
    </div>;
}