import { DiagramMissionsSet, MissionProgression, MissionItem } from './missionProgression';
import { CustomComponentBuilders } from '../diagram/customComponentBuilders';
import { DiagramRepository } from './diagramRepository';
import { DiagramDeserializer } from '../diagram/diagramPersistence';
import { CustomComponentType } from '../diagram/customComponent';
import { CircuitStructure } from '../diagram/circuitStructure';
import { CustomComponentPersistence } from '../diagram/customComponentPersistence';
import { CustomComponentRepositoryService } from '../diagram/customComponentRepository.service';
import { getMissions } from '../missions/track';
import { CustomComponents } from '../diagram/customComponents';
import { MissionNavigator } from './missionProgression';
import { addcMission } from '../diagram/missions/arithmeticMissions';
import { notNull } from '../common/utilities';
import { MissionStatus } from './missionStatus';
import { Task } from './task';
import React from 'react';
import { JsonStorageService, PersistentStorageService } from '../common/storage.service';
import { Repository } from './repository';


export class GameState {
    missionProgression!: MissionProgression;
    missionNavigator!: MissionNavigator;
    customComponents!: CustomComponentBuilders;
    diagramSet!: DiagramMissionsSet;
    currentMission!: MissionItem;
    levelRepository;

    constructor(readonly repo1: Repository, private readonly repository: CustomComponentRepositoryService) {
        this.levelRepository = new DiagramRepository(repo1);
        this.loadState();
        this.startGame();
    }
    loadState() {
        new GameLoader(this.repo1, this.repository, this);
    }
    startGame() {
        let mission: MissionItem;
        if (this.isNewGame()) {
            mission = this.missionNavigator.getFirstMission();
        } else {
            const maybeMission = this.missionNavigator.getFirstIncomplete();
            if (maybeMission) {
                mission = maybeMission;
            } else {
                mission = this.missionNavigator.getLastMission();

                // No incomplete missions in hardware epic.
                // we navigate to code epic UNLESS hardware epic have been explicitly selected
                /*
                const selectedEpic = SessionState.selectedEpic();
                if (selectedEpic === null || selectedEpic === 'code') {
                    window.location.assign('/code');
                    return;
                }
                */
            }
        }
        this.selectMission(mission);
    }
    isNewGame() {
        return this.levelRepository.isNewGame();
    }
    get hasUnlockedCustomComponents() {
        // after add 16, we allow custom components
        const addcitem = this.diagramSet.getDiagramMissionStateByType(addcMission);
        return addcitem && addcitem.isCompleted;
    }
    selectMission(item: MissionItem) {
        if (item.status === MissionStatus.Locked || item.status === MissionStatus.Unlocked) {
            item.start(this.repo1, this.missionProgression);
        }
        this.missionProgression.save();
        this.currentMission = item;
        // remember selected mission across page reloads
        SessionState.selectMission(item.mission);
    }
}

class SessionState {
    static selectedEpic() {
        return window.sessionStorage.getItem('Nandgame:selected-epic');
    }
    static selectMission(mission: Task) {
        window.sessionStorage.setItem('Nandgame:selected-mission', mission.key);
    }
}

class PartiallyLoadedNode {
    constructor(readonly nodeType: CustomComponentType, readonly data: CustomComponentPersistence) { }
}

class GameLoader {
    diagramPersister: DiagramDeserializer;

    constructor(readonly repo1: Repository,
        private readonly repository: CustomComponentRepositoryService,
        gameState: GameState) {

        // We need to be careful about loading state in the correct order
        // because of the inter-dependencies between components and missions

        // (1) load interfaces of custom components, but not the diagrams
        const customNodeInterfaces = this.restoreInterfaces();

        const customNodeTypes = customNodeInterfaces.map(pair => pair.nodeType);
        this.diagramPersister = new DiagramDeserializer(customNodeTypes);

        // Load definitions (diagrams) into custom components
        // depends on interfaces being loaded since a diagram may contain other components
        for (const item of customNodeInterfaces) {
            this.restoreDiagram(item, this.diagramPersister);
        }

        // Load history (depends on interfaces of custom components)
        const missions = getMissions();
        const progression = new MissionProgression(this.repo1, this.diagramPersister, missions);
        const diagramSet = new DiagramMissionsSet(progression);
        gameState.missionProgression = progression;
        gameState.diagramSet = diagramSet;
        gameState.missionNavigator = new MissionNavigator(progression);
        const customComponents = new CustomComponents(customNodeTypes);
        gameState.customComponents = new CustomComponentBuilders(this.repository, diagramSet, customComponents);

        // need to run tests after all the mission items have been loaded
        // sinde a mission may depend on macros on other missionItems
        progression.testAll(progression.activatedItems);
    }

    restoreInterfaces() {
        const comps = this.repository.getComponents() ?? [];
        return comps
            .map(key => this.restoreInterfaceSafe(key))
            // null if restore caused an error. Filter nulls out.
            .filter(notNull);
    }

    restoreInterfaceSafe(key: string) {
        try {
            const data = this.repository.getComponent(key);
            return this.restoreInterface(data, this.repository);
        } catch (e) {
            // errors in restore are skipped
            console.error('Restore error for ', key, e);
            return null;
        }
    }

    restoreInterface(data: CustomComponentPersistence, repository: CustomComponentRepositoryService): PartiallyLoadedNode {
        const diagram = new CircuitStructure([], []);
        for (const grp of data.inputs) {
            for (const inp of grp.pins) {
                diagram.addInputPin(inp.label, inp.width);
            }
        }
        for (const grp of data.outputs) {
            for (const inp of grp.pins) {
                diagram.addOutputPin(inp.label, inp.width);
            }
        }
        const node = new CustomComponentType(data.key, data.name, diagram, repository);
        return new PartiallyLoadedNode(node, data);
    }

    restoreDiagram(item: PartiallyLoadedNode, diagramPersister: DiagramDeserializer) {
        diagramPersister.restoreDiagram(item.data.diagram, item.nodeType.diagram);
    }
}

export const GameStateContext = React.createContext(null as unknown as GameState);

export function initGameState() {
    const storage = new JsonStorageService(new PersistentStorageService());
    const repository = new Repository(storage);
    const ccRepository = new CustomComponentRepositoryService(storage)
    const gameState = new GameState(repository, ccRepository);
    return gameState;
  }
