import { diagram } from './missions';
import { nandNodeType, andNodeType, invNodeType, orNodeType, selector16NodeType, splitterNodeType } from '../missions/logicMissions';
import { zeroNodeType, isNegNodeType } from '../missions/arithmeticMissions';
import { bit, word, PortHints, Pin, PinGroup } from '../pins';
import { numericTest } from '../verification';
import { numberToBoolArray, boolToBit, boolArrayToNumber } from '../../common/bits';
import { assembleInstruction } from '../../assembler/assembler';
import { component } from './baseNodeType';
import { OutputRuleArray } from './outputRules';
import { depends } from './dependency';


export const instructionDecoderMission = diagram(<const>{
    key: 'INSTRUCTON_DECODER', /* NOTE: This spelling error can never be fixed */
    inputPins: [new Pin(16, '', PortHints.Instruction)],
    outputPins: [
        bit('ci'),
        bit('sm'),
        new PinGroup('op',
            [bit('zx'), bit('nx'), bit('zy'), bit('ny'), bit('f'), bit('no')]),
        new PinGroup('dst', [bit('a'), bit('d'), bit('*a')]),
        new PinGroup('jmp', [bit('lt'), bit('eq'), bit('gt')]),
        word('W')
    ],
    palette: [
        selector16NodeType, splitterNodeType, zeroNodeType, nandNodeType, invNodeType, andNodeType,
        orNodeType, isNegNodeType
    ],
    tests: [
        // out: mode, m-src, target (a,d,*a), comp, jmp, W
        numericTest([assembleInstruction('A = 1').toWord()],
            [
                /* c-instr */ 1, /* m-src */ 0, /* comp */ 1, 1, 1, 1, 1, 1, /*dest: */ 1, 0, 0, /* jmp */ 0, 0,
                0, /* X */ 0
            ]),
        numericTest([assembleInstruction('D,*A = D&A').toWord()],
            [
                /* c-instr */ 1, /* m-src */ 0, /* comp */ 0, 0, 0, 0, 0, 0, /*dest: */ 0, 1, 1, /* jmp */ 0, 0,
                0, /* X */ 0
            ]),
        numericTest([assembleInstruction('A = *A;JLE').toWord()],
            [
                /* c-instr */ 1, /* m-src */ 1, /* comp */ 1, 1, 0, 0, 0, 0, /*dest: */ 1, 0, 0,
                /* jmp */ 1, 1, 0, /* X */ 0
            ]),
        numericTest([assembleInstruction('A = 1234').toWord()],
            [
                /* c-instr */ 0, /* m-src */ 0, /* comp */ 0, 0, 0, 0, 0, 0, /*dest: */ 1, 0, 0, /* jmp */ 0, 0,
                0, /* X */ 1234
            ])
    ],
    score: { min: 4 },
});


export const instructionDecoderNodeType = component('instruction',
    'INSTRUCTION_DECODER',
    [word('')],
    [
        bit('ci'),
        bit('sm'),
        new Pin(6, 'op', PortHints.AluOperation),
        new Pin(3, 'dst', PortHints.AluDestination),
        new Pin(3, 'cd', PortHints.ConditionFlags),
        word('W')
    ],
    new OutputRuleArray(([n]) => {
        // note: bits counted from left to right
        const bits = numberToBoolArray(n, 16);
        return bits[0]
            ?[
                1, boolToBit(bits[3]),
                boolArrayToNumber(bits.slice(4, 10)),
                boolArrayToNumber(bits.slice(10, 13)),
                boolArrayToNumber(bits.slice(13, 16)),
                0
            ]
            : [0, 0, 0b000000, 0b100, 0b000, n];
    }),
    // cannot be expanded due to different number of pins in diagram
    depends(instructionDecoderMission, 1, false)
);
