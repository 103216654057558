import { useRef } from 'react';
import { EditorBackend } from '../../assembler/editorBackend';
import Editor, { Monaco } from '@monaco-editor/react';
import { EditorConfigurator } from './editorConfiguration';

import './EditorComponent.css';
import { AssemblerHelp } from '../help/AssemblerHelp';

export function AssemblerEditorComponent(props: { editorBackend: EditorBackend }) {
    const monacoRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
    const editorConfigRef = useRef<EditorConfigurator | null>(null);

    if (editorConfigRef.current) {
        if (editorConfigRef.current.backend !== props.editorBackend) {
            editorConfigRef.current.setBackend(props.editorBackend);
        }
    }

    function handleEditorWillMount(monaco1: Monaco) {
        // theme need to be defined before editor is created
        EditorConfigurator.init(monaco1);
    }

    function handleEditorDidMount(editor1: unknown, monaco: Monaco) {
        const editor = editor1 as monaco.editor.IStandaloneCodeEditor;
        monacoRef.current = editor;
        props.editorBackend.reparse();
        editorConfigRef.current = new EditorConfigurator(monaco, editor, props.editorBackend);
    }

    return (
        <>
            <div className="quick-reference">
                <AssemblerHelp />
            </div>
            <Editor
                height="80vh"
                width="500px"
                options={{
                    glyphMargin: true,
                    fontSize: 16,
                    scrollBeyondLastLine: false,
                    // semantic tokens provider is disabled by default
                    'semanticHighlighting.enabled': true,
                    // disabled until better suggestions implemented!
                    acceptSuggestionOnEnter: 'off',
                    quickSuggestions: false,
                }}
                language="NandgameAssembler"
                theme="NandgameAssemblerTheme"
                beforeMount={handleEditorWillMount}
                onMount={handleEditorDidMount}
            />
        </>
    );
}
