import React from 'react';
import { ClientPos } from '../position';

/*      We are not using the Drag and Drop API, since
 *          (1) SVG does not support it
 *          (2) Touch devices does not implement it
 *
 *      So instead we emulate the API, but fire the drag/drop events
 * 
 *       
 * 
 */


/*  Service indicating the drag operation which is underway.
 *  This is global since we only can have one drag underway at the same time for the whole app.
 */
export class DragDropService {
    dragSession?: DragSession;

    startDrag(draggingKind: string, point: EventPoint, event: Event,
        handler: DragEventHandler) {
        this.dragSession = new DragSession(this, draggingKind, point, event, handler);
        return this.dragSession;
    }
    get draggingKind() { return this.dragSession?.draggingKind; }
    get draggingInProgress() { return this.dragSession !== undefined; }
    endDrag() {
        this.dragSession = undefined;
    }
}

export interface DragEventHandler {
    onDragStart?(deltaX: number, deltaY: number): void;
    onDragMove?(deltaX: number, deltaY: number): void;
    onCancel?(): void;
    onDragEnd?(event: EventPoint): void;
    onDrop?(targetElement: Element, target: unknown): void;
    hasDropTarget?(target: Element): Element | null;
}

// implemented by Touch and MouseEvent
export type EventPoint = {
    readonly clientX: number;
    readonly clientY: number;
};

/** passed in drag events */
export class DragDetails {
    constructor(private readonly session: DragSession) { }
    setTargetComponent(target: unknown) {
        this.session.targetComponent = target;
    }
}

class DragSession {
    /** the session starts when mouse is moved first time with button down.
     * A click does not itiate a drag session. */
    started = false;
    startClient: ClientPos;
    private currentTarget: Element | undefined;
    targetComponent?: unknown;

    // wrappers allowing to add and remove event handler
    bindMouseMove = (event: MouseEvent) => this.mouseMove(event);
    bindMouseUp = (event: MouseEvent) => this.mouseUp(event);
    bindMouseOver = (event: MouseEvent) => this.mouseOver(event);
    bindMouseEnter = (event: Event) => this.mouseEnter(event as MouseEvent);
    bindMouseLeave = (event: Event) => this.mouseLeave(event as MouseEvent);
    bindTouchMove = (event: TouchEvent) => this.touchMove(event);
    bindTouchEnd = (event: TouchEvent) => this.touchEnd(event);

    constructor(private readonly dragContext: DragDropService,
                readonly draggingKind: string,
                point: EventPoint,
                event: Event,
                private readonly handler: DragEventHandler) {
        this.startClient = { clientX: point.clientX, clientY: point.clientY };
        // attach to these event on the document level, since we want to capture
        // even when the drag is moving away from the dragged
        window.document.addEventListener('mousemove', this.bindMouseMove);
        window.document.addEventListener('mouseup', this.bindMouseUp);
        window.document.addEventListener('mouseover', this.bindMouseOver, true);
        window.document.addEventListener('mouseenter', this.bindMouseEnter, true);
        window.document.addEventListener('mouseleave', this.bindMouseLeave, true);
        window.document.addEventListener('touchmove', this.bindTouchMove, { passive: false, capture: true });
        window.document.addEventListener('touchend', this.bindTouchEnd, { passive: false, capture: true });

        // Cancel the event (mousedown or touch) which initiated the drag-session
        event.cancelBubble = true;
        event.preventDefault();
    }
    unbind() {
        window.document.removeEventListener('mousemove', this.bindMouseMove);
        window.document.removeEventListener('mouseup', this.bindMouseUp);
        window.document.removeEventListener('mouseover', this.bindMouseOver, true);
        window.document.removeEventListener('mouseenter', this.bindMouseEnter, true);
        window.document.removeEventListener('mouseleave', this.bindMouseLeave, true);
        window.document.removeEventListener('touchmove', this.bindTouchMove, true);
        window.document.removeEventListener('touchend', this.bindTouchEnd, true);
    }
    mouseOver(event: MouseEvent) {
        if (this.handler.hasDropTarget) {
            const target = this.asDropTarget(event);
            if (target) {
                this.dragOver(event.target as Element);
            }
        }
    }
    asDropTarget(event: MouseEvent): Element | null {
        if (event.target == null) {
            return null;
        }
        const node = event.target as Node;
        if (node.nodeType === Node.ELEMENT_NODE) {
            const elem = node as Element;
            if (this.handler.hasDropTarget) {
                return this.handler.hasDropTarget(elem);
            }
        }
        return null;
    }
    mouseEnter(event: MouseEvent) {
        const target = this.asDropTarget(event);
        if (target) {
            this.dragEnter(target);
        }
    }
    mouseLeave(event: MouseEvent) {
        const target = this.asDropTarget(event);
        if (target) {
            this.dragLeave(target);
        }
    }
    mouseMove(event: MouseEvent) {
        event.preventDefault();
        if (event.buttons !== 1) {
            this.unbind();
        } else {
            this.dragMove(event);
        }
    }
    mouseUp(event: MouseEvent) {
        // end the dragging operation
        this.unbind();
        // the drop target:
        const target = this.asDropTarget(event);
        if (target) {
            this.drop(target);
        } else {
            // not on a valid drop target
            if (this.handler.onCancel) { this.handler.onCancel(); }
        }
        // the dragged element:
        this.dragEnd(event);
    }

    /* touch */

    getTouch(event: TouchEvent) {
        return event.touches.item(0)!;
    }

    touchMove(event: TouchEvent) {
        const touch = event.touches.item(0)!;
        const t = this.getTouchTarget(touch);
        if (t) {
            if (this.currentTarget !== t) {
                if (this.currentTarget) {
                    this.dragLeave(this.currentTarget);
                }
                this.currentTarget = t;
                this.dragEnter(this.currentTarget);
            } else {
                this.dragOver(this.currentTarget);
            }
        }
        this.dragMove(touch);
        // prevent dragging of whole page in iPad
        event.preventDefault();
        event.stopPropagation();
    }

    getTouchTarget(point: EventPoint): Element | undefined {
        if (this.handler.hasDropTarget) {
            const elements = document.elementsFromPoint(point.clientX, point.clientY);
            for (const elem of elements) {
                const target = this.handler.hasDropTarget(elem);
                if (target) {
                    return target;
                }
            }
        }
        return undefined;
    }

    touchEnd(event: TouchEvent) {
        const touch = event.changedTouches.item(0)!;
        // end the dragging operation
        this.unbind();

        // get the element at a given touch event
        const target = this.getTouchTarget(touch);
        if (target) {
            this.drop(target);
        } else {
            // not on a valid drop taget
            if (this.handler.onCancel) { this.handler.onCancel(); }
        }
        // the dragged element:
        this.dragEnd(touch);
    }


    /* Events fired on the dragover/drop target element */

    dragEnter(target: Element) {
        target.dispatchEvent(new CustomEvent('dragenter', { bubbles: false, detail: new DragDetails(this) }));
        target.classList.add('drag-dragover');
    }
    dragOver(_target: Element) {
        //target.dispatchEvent(new CustomEvent('dragover', { bubbles: true, detail: new DragDetails(this) }));
    }
    dragLeave(target: Element) {
        target.dispatchEvent(new CustomEvent('dragleave', { bubbles: false, detail: new DragDetails(this) }));
        target.classList.remove('drag-dragover');
    }

    drop(targetElement: Element) {
        targetElement.classList.remove('drag-dragover');
        const ev = new CustomEvent('drop', { bubbles: true, detail: new DragDetails(this) });
        targetElement.dispatchEvent(ev);
        if (this.handler.onDrop) {
            this.handler.onDrop(targetElement, this.targetComponent);
        }
    }
    dragEnd(point: EventPoint) {
        // clear drag session before we call onDragEnd
        // so components can update state appropriately
        this.dragContext.endDrag();
        if (this.handler.onDragEnd) { this.handler.onDragEnd(point); }
    }
    dragMove(point: EventPoint) {
        const deltaX = point.clientX - this.startClient.clientX;
        const deltaY = point.clientY - this.startClient.clientY;
        if (!this.started) {
            if (this.handler.onDragStart) {
                this.handler.onDragStart(deltaX, deltaY);
            }
            this.started = true;
        }
        if (this.handler.onDragMove) {
            this.handler.onDragMove(deltaX, deltaY);
        }
    }
}

export const DragDropProvider = React.createContext(null as unknown as DragDropService);
