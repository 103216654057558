import { ComponentInstanceState } from '../circuitState';
import { bit } from '../pins';
import { ComponentInternalState } from '../componentType';
import { BaseBuiltinComponentType } from './baseNodeType';
import { transparent } from './dependency';


export class ClockStateView {
    kind = 'Clock';
    constructor(readonly state: ClockState) { }
    getState() { return this.state.state; }
}

export class ClockState implements ComponentInternalState {
    state = 0;
    stateView = new ClockStateView(this);
    constructor(private node: ComponentInstanceState) { }
    resolveOutputs(_node: ComponentInstanceState) {
        return [this.state];
    }
    reset() {
        this.state = 0;
    }
    flip() {
        this.state = 1 - this.state;
        this.node.stateChanged();
    }
    tick() {
        this.flip();
        this.flip();
    }
}

export const clockNodeType = new class extends BaseBuiltinComponentType {
    name = 'clock';
    key = 'CLOCK';
    inputs = [];
    outputs = [bit('cl')];
    hasInternalState = false;
    depends = transparent();
    createInternalState = (node: ComponentInstanceState) =>
        new ClockState(node);
};

