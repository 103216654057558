import { useState } from 'react';
import { Help } from '../../compiler/Help';
import { CodeEditor } from '../../compiler/SourceCodeEditor';
import { PatternType, TokenAction, tokenize } from '../../compiler/tokenize';
import { Tokenized, LexerRulesComponent, TokenSpecification } from '../../compiler/LexerRules';
import { TokenizeMissionState } from './tokenizeMissionState';

export function TokenizeMissionComponent(props: { missionState: TokenizeMissionState }) {
    const missionState = props.missionState;
    const compilerConfig = missionState.compilerState;
    const [state, setState] = useState(() => getState(missionState.source, missionState.compilerState.lexical));
    function onTokenAdd() {
        const nextId = TokenSpecification.nextId(compilerConfig.lexical);
        console.log('nextid', nextId)
        const newToken = new TokenSpecification(nextId, PatternType.Pattern, '', TokenAction.Literal, '');
        compilerConfig.lexical = [...compilerConfig.lexical, newToken];
        updateState(state.source, missionState.compilerState.lexical);
    }
    function onTokenChange(token: TokenSpecification) {
        missionState.compilerState.lexical = [...compilerConfig.lexical];
        updateState(state.source, compilerConfig.lexical);
    }
    function onTokenDelete(id: number) {
        console.log('ids:' , compilerConfig.lexical.map(l => l.id).join('|'));
        compilerConfig.lexical = compilerConfig.lexical.filter(item => item.id !== id);
        updateState(state.source, compilerConfig.lexical);
    }
    function onSourceChange(value: string) {
        updateState(value, state.lexical);
    }
    function getState(source: string, lexicalUi: TokenSpecification[]) {
        const validatedLexical = lexicalUi.filter(l => l.isValid);
        const tokenSet = tokenize(source, validatedLexical);
        return {
            source: source,
            lexical: lexicalUi,
            tokenSet: tokenSet,
        };
    }
    function updateState(source: string, lexical: TokenSpecification[]) {
        missionState.save();
        setState(getState(source, lexical));
    }
    function sourceCodeError() {
        return state.tokenSet.error ? state.tokenSet.error : undefined;
    }
    return (
        <div>
            <h3>Token definitions</h3>
            <LexerRulesComponent
                tokens={state.lexical}
                onTokenAdd={onTokenAdd}
                onTokenDelete={onTokenDelete}
                onTokenChange={onTokenChange}
            />

            <h3>Source code</h3>
            <div>
                <Help>Source code - input to the compiler. Will be tokenized according to the specifications above.</Help>
                <CodeEditor source={state.source} onCodeChange={onSourceChange} error={sourceCodeError()} />
            </div>

            <h3>Tokens</h3>
            <Tokenized tokenSet={state.tokenSet} />
        </div>
    );
}
