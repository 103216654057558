import { CustomComponentPersistence } from './customComponentPersistence';
import { JsonStorageService } from '../common/storage.service';


export class CustomComponentRepositoryService {
    static readonly componentsKey = 'NandGame:CustomComponents';
    static readonly componentKey = 'NandGame:CustomComponent:';

    constructor(private readonly storage: JsonStorageService) { }

    getComponentKey(key: string) {
        return CustomComponentRepositoryService.componentKey + key;
    }

    getComponents() {
        return this.storage.getItem(CustomComponentRepositoryService.componentsKey) as string[] | null;
    }

    getComponent(key: string) {
        const comp = this.storage.getItem(this.getComponentKey(key)) as CustomComponentPersistence | null;
        if (comp == null) { throw new Error(`No diagram stored for ${key} `); }
        return comp;
    }

    saveComponent(componentKey: string, data: CustomComponentPersistence) {
        this.storage.setItem(this.getComponentKey(componentKey), data);
    }

    deleteComponent(componentKey: string) {
        this.storage.removeItem(this.getComponentKey(componentKey));
    }

    saveComponents(keys: string[]) {
        this.storage.setItem(CustomComponentRepositoryService.componentsKey, keys);
    }
}

