import { useState } from 'react';
import './ButtonComponent.css';
import { IComponentInstanceState } from '../../componentType';
import { ButtonInternalState } from '../../missions/inputOutputMission';

export function ButtonComponent(props: {
		node: IComponentInstanceState;
		isPalette: boolean;
        diagramStateChanged: ()=>void;
    }) { 

	const [state, setState] = useState({pressed: false});
    const nodeState =  props.node.internalState as ButtonInternalState;
    const isPalette =  props.isPalette;

    function mouseDown(ev: React.MouseEvent) {
        // node state not present when in palette
        if (!isPalette) {
            nodeState.setPressed(true);
            setState({pressed: true});
            if (props.diagramStateChanged) {
                props.diagramStateChanged();
            }

            // prevent a drag start
            ev.stopPropagation();
        }
    }
    function mouseUp(_ev: React.MouseEvent) {
        if (!isPalette) {
            nodeState.setPressed(false);
            setState({pressed: false});
            if (props.diagramStateChanged) {
                props.diagramStateChanged();
            }
        }
    }

	return (
    <div className='button-node-box'>
        <div onMouseDown={mouseDown} 
            onMouseUp={mouseUp} 
            className={'button-slot' + (state.pressed ? ' pressed' : '')}>
            <div className='button'>⊼</div>
        </div>
    </div>
    ); 
}

