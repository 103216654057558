import React from 'react';
import './OpcodesMissionComponent.css';
import { MissionItem } from '../app/missionProgression';
import { ErrorState, OpcodesMissionState, Bit } from './opcodes';

export function OpcodesMissionComponent(props: { currentMission: MissionItem}) { 
    const labels = ['zx','nx','zy','ny','f','no'];
    const missionState =  props.currentMission.state as OpcodesMissionState;
    const lines =  missionState.lines;

    const [state,setState] = React.useState(()=>{
        return {lines: missionState.lines};
    });

    function toggle(bit: Bit) {
        bit.toggle();
        setState({lines: missionState.lines});
    }
    function marker(state: ErrorState) {
        if (state === 'error') {
            return (<i className='bi bi-x-lg'></i>);
        } if (state === 'ok') {
            return (<i className='bi bi-check-lg'></i>); 
        } else if (state === 'none') {
            return null;
        }
    }
	return (
<div className='opcodes-content'>
    <table>
        <thead>
            <tr>
                <th>Opcode</th>
                <th colSpan={6}>Bit flags</th>
            </tr>
            <tr>
                <th></th>
                {labels.map(label => <th key={label}>{label}</th>)} 
            </tr>
        </thead>
        <tbody>
            {lines.map(instr => (
                <tr key={instr.opcode}> 
                    <td className='opcode'>{instr.text}</td>
                    {(instr.bits).map((bit, ix) => (
                        <td key={ix}>
                        <span tabIndex={1} 
                            onKeyPress={()=>toggle(bit)}
                            onClick={()=>toggle(bit)} className='bit'>{bit.bit ? '1' : '0'}</span>
                        </td>))} 
                    <td className='marker'>{marker(instr.errorState)}</td>
                </tr>))} 
        </tbody>
    </table>
</div>
); 
}

