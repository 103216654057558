import { CircuitState } from '../diagram/circuitState';
import { DiagramSerializer } from '../diagram/diagramPersistence';
import { Repository } from './repository';

export class DiagramRepository {
    constructor(private readonly repository: Repository) { }
    isNewGame() {
        const levels = this.repository.getLevels();
        return (levels == null || levels.length === 0);
    }

    getLevels() {
        return this.repository.getLevels();
    }

    saveDiagram(levelKey: string, diag: CircuitState) {
        const ser = new DiagramSerializer();
        const levelPersistence = ser.persistDiagram(diag.structure);
        this.repository.saveLevel(levelKey, levelPersistence);
    }

    saveLevels(levels: string[]) {
        this.repository.saveLevels(levels);
    }
}



