import { ConstantPin, InputPinDefinition, OutputPinDefinition, Pin, PinGroup } from './pins';
import { binaryTest } from './verification';

class PinDisplay {
    constructor(readonly pin: Pin, readonly index: number) {}
    get label() { return this.pin.name; }
}

export class TruthTable {
    visibleInputs;
    visibleOutputs;
    constructor(readonly inputPins: readonly OutputPinDefinition[],
        readonly outputPins: readonly InputPinDefinition[],
        readonly truths: readonly [boolean[], boolean[]][]) { 

        /* flatten pin-groups
            dont display columns for constant pins (like in nand-level and cmos-levels)
        */
        const inputs = inputPins
            .flatMap(p => p instanceof PinGroup ? p.ports[0] : [p])
            .map((p, ix) => new PinDisplay(p, ix));
        this.visibleInputs = inputs
            .filter(p => !(p.pin instanceof ConstantPin));
        const outputs = outputPins
            .flatMap(p => p instanceof PinGroup ? p.ports[0] : [p])
            .map((p, ix) => new PinDisplay(p, ix));
        this.visibleOutputs = outputs
            .filter(p => !(p.pin instanceof ConstantPin));
        }

    tests() {
        return this.truths.map(([inp, out]) => binaryTest(inp, out));
    }
}
