import '../popup-dialog.css';
import './LevelHelp.css';
import { UiText } from '../../../locale/UiText';
import { WithPopupWindow } from '../../WithPopupWindow';
import { ActiveMission } from '../TopLevel';

export function LevelHelpSpoiler(props: {
		hint: string;
		ix: number}) { 

	const hint = props.hint;
	const ix = props.ix;
	return (<>
            <p>
                <button type='button' data-bs-toggle='collapse' data-bs-target={'#spoiler'+ix} className='btn btn-outline-default btn-sm'>
                    Hint #{ix+1} <UiText id='click_to_reveal' />
                </button>
            </p>
            <div id={`spoiler${ix}`} className='collapse'>
                <div dangerouslySetInnerHTML={{__html: hint}} className='card card-body'></div>
            </div>
    </>); 
}


export function LevelHelp(props: {
        activeMission: ActiveMission;
		helpClosed: () => void}) { 

    function closeHelp() { props.helpClosed(); }
	const missionTexts = props.activeMission.texts;

	return (
        <WithPopupWindow visible onClose={closeHelp}>
        <div className='level-help'>
            <div>
                <button type='button' aria-label='Close' onClick={()=>closeHelp()} className='btn-close close'></button>
                <h2>{missionTexts.title}</h2>
            </div>
            <div className='instructions elaboration'>
                {(missionTexts.spoilers ?? []).map((spoiler, ix) => (
                    <div className='spoilers' key={ix}>
                        <LevelHelpSpoiler hint={spoiler} ix={ix} />
                    </div>))} 
                <div dangerouslySetInnerHTML={{__html: missionTexts.elaboration ?? ''}} className='elaboration'></div>
            </div>
        </div>
        </WithPopupWindow>
); 
}

