import { LampDevice } from '../../assembler/lampDevice';
import './BlinkenlightComponent.css';

export function BlinkenlightComponent(props: { lampIo: LampDevice }) {
    return (
        <div className="io-area">
            <div className={'blinkenlight ' + (props.lampIo.lampIsOn ? 'on' : '')}></div>
        </div>
    );
}
