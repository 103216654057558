import { DefaultAssembler } from '../../assembler/assembler';
import { Controller } from '../../assembler/controller';
import { EditorBackend } from '../../assembler/editorBackend';
import { HeadlessCodeContainer } from '../../assembler/headlessCodeContainer';
import { Device, Machine, IoRegister } from '../../assembler/machine';
import { bitArrayToNumber, numberToBoolArray } from '../../common/bits';
import { EventEmitter2 } from '../../common/eventEmitter';
import { Repository } from '../../app/repository';
import { MissionKind, MissionState, Task } from '../../app/task';
import { UnitVerificationResultSet, VerificationError, VerificationOk, VerificationResult, VerificationResultSet } from '../../app/verificationResults';
import { AssemblerPersistence, MissionSourceFile } from '../assemblerMissions';
import { Robot, RobotMoveState } from '../robot';


export class RobotIO implements Device {
    inputState = 0;
    // low 8 bits are input to device
    inputMask = 0x00FF;
    readonly changed = new EventEmitter2<number>();

    constructor(readonly robot: Robot) {
        this.robot.stateChanged.addListener(() =>
            this.changed.fire(this.peek()));
    }
    poke(word: number) {
        const input = word & this.inputMask;
        this.inputState = input;
        const bits = numberToBoolArray(input, 16).reverse();
        this.robot.poke(bits);
        return this.peek();
    }
    peek() {
        return bitArrayToNumber(this.robot.getState());
    }
    tick() {
        this.inputState = 0;
    }
    reset() {
        this.robot.resetState();
        this.inputState = 0;
    }
}

/*  The simulation containg the robot and a labyrinth
 */
export class Universe {
    // adjustet .5 relative to walls:
    robotX = 0;
    robotY = 0;
    robotOrientation = 0; // looking north, clockwise 1=east, 2=south, 3=west
    // x,y start and end
    readonly horizontalWalls = [
        // x,y,length (right)
        [-1, -1, 2],
        [0, 0, 1],
        [0, 1, 1],
        [-1, 2, 2],
    ] as const;
    readonly verticalWalls = [
        // x,y,length (down)
        [1, 0, 1],
        [-1, -1, 3],
    ] as const;
    isEscaped = false;
    history: string[];
    constructor(readonly robot: Robot) {
        this.history = this.initHistory();
        this.robot.turnedLeft.addListener(() => {
            this.robotOrientation = (this.robotOrientation + 3) % 4;
            this.reOrient();
            this.history.push(`Turned left to face ${this.orientationText()} `);
        });
        this.robot.turnedRight.addListener(() => {
            this.robotOrientation = (this.robotOrientation + 1) % 4;
            this.reOrient();
            this.history.push(`Turned right to face ${this.orientationText()}`);
        });
        this.robot.movedStep.addListener(() => {
            if (this.robot.obstacle) {
                this.history.push(`tried to move forward, but could not move because of obstacle.`);
                return;
            }
            this.history.push(`Moved forward one step.`);
            if (this.robotOrientation === 0) {
                this.robotY -= 1;
            } else if (this.robotOrientation === 1) {
                this.robotX += 1;
            } else if (this.robotOrientation === 2) {
                this.robotY += 1;
            } else if (this.robotOrientation === 3) {
                this.robotX -= 1;
            }
            this.reOrient();
        });
        // calc inital state:
        this.reOrient();
    }
    initHistory() {
        return [`Facing ${this.orientationText()}`];
    }
    reOrient() {
        const obstacle = this.isObstacle();
        this.robot.setObstacle(obstacle);
        if (!obstacle) {
            // no obstacle - check if escaped labyrinth
            if (this.isFree()) {
                this.isEscaped = true;
            }
        }
    }
    isObstacle() {
        return this.isObstacleInDirection(this.robotOrientation);
    }
    isObstacleInDirection(direction: number) {
        if (direction === 0) {
            // looking north
            return this.horizontalWalls.some(([startX, y, length]) =>
                y === this.robotY && startX <= this.robotX && startX + length > this.robotX);
        } else if (direction === 2) {
            // looking south
            const robotEdgeY = this.robotY + 1;
            return this.horizontalWalls.some(([startX, y, length]) =>
                y === robotEdgeY && startX <= this.robotX && startX + length > this.robotX);
        } else if (direction === 1) {
            // looking east
            const robotEdgeX = this.robotX + 1;
            return this.verticalWalls.some(([x, startY, length]) =>
                x === robotEdgeX && startY <= this.robotY && startY + length > this.robotY);
        } else if (direction === 3) {
            // looking west
            const robotEdgeX = this.robotX;
            return this.verticalWalls.some(([x, startY, length]) =>
                x === robotEdgeX && startY <= this.robotY && startY + length > this.robotY);
        }
        else {
            throw new Error();
        }
    }
    isObstacleInDirections(directions: number[]) {
        return directions.some(d => this.isObstacleInDirection(d));
    }
    isFree() {
        // The robot is free when there are no obstacles in front or at the sides, at any distance
        // looking up
        if (this.robotOrientation === 0) {
            return !this.isObstacleInDirections([1, 2, 3]);
        } else if (this.robotOrientation === 2) {
            return !this.isObstacleInDirections([0, 1, 3]);
        } else if (this.robotOrientation === 1) {
            return !this.isObstacleInDirections([0, 2, 3]);
        } else if (this.robotOrientation === 3) {
            return !this.isObstacleInDirections([0, 1, 2]);
        } else {
            throw new Error();
        }
    }
    get robotState() {
        if (this.robot.moveState === RobotMoveState.Moving) {
            return 'moving forward...';
        } else if (this.robot.moveState === RobotMoveState.TurningLeft) {
            return 'turning left...';
        } else if (this.robot.moveState === RobotMoveState.TurningRight) {
            return 'turning right...';
        } else if (this.robot.moveState === RobotMoveState.Stopped) {
            const obstacle = this.robot.obstacle ? 'Obstacle in front.' : 'No obstacle in front.';
            return 'stopped. ' + obstacle;
        } else {
            return '?';
        }
    }
    orientationText() {
        return this.robotOrientation === 0 ? 'north' :
            this.robotOrientation === 1 ? 'east' :
                this.robotOrientation === 2 ? 'south' :
                    this.robotOrientation === 3 ? 'west' : '?';
    }
    get stateText() {
        if (this.isEscaped) {
            return `The robot has escaped the labyrinth!`;
        }
        if (this.robot.isTipped) {
            return `The robot is tipped over and cannot move.`;
        }
        const orient = this.orientationText();
        return `The robot is in the labyrinth, facing ${orient}.
                Robot is ${this.robotState}
                `;
    }
    resetState() {
        // reset robot state first, since this will also clear pending timeouts
        // which could otherwise cause state to change
        this.robot.resetState();
        this.robotX = 0;
        this.robotY = 0;
        this.robotOrientation = 0;
        this.isEscaped = false;
        this.history = this.initHistory();
        this.reOrient();
    }
}

export const escapeMission = new class implements Task {
    readonly key = 'ESCAPE';
    readonly kind = MissionKind.Escape;
    start(repository: Repository) {
        return new EscapeMissionState(repository);
    }
    restore(repository: Repository) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new EscapeMissionState(repository, data);
    }
};


export function verifyEscapeCode(sourceText: string): VerificationResult {
    // (1) Verify syntax of source code
    const assembler = new DefaultAssembler();
    const program = assembler.assemble(sourceText);
    const errors = program.getAllErrors();
    if (errors.length > 0) {
        return new VerificationError(`Syntax error in assembler code: ${errors[0]} `);
    }
    if (program.instructions.length === 0) {
        return new VerificationError('Program does not have any instructions.');
    }
    // (2) Execute code in sandbox
    const robot = new Robot();
    const universe = new Universe(robot);
    const robotIo = new RobotIO(robot);
    const machine = new Machine([{from: 0x7fff, to: 0x7fff, device: new IoRegister(robotIo)}]);

    const a = new DefaultAssembler();
    const provider = new HeadlessCodeContainer(a);
    provider.setCode(sourceText);
    const controller = new Controller(machine, provider);

    // TODO: Should we test with a different labyrinth to catch a hardcoded path?
    universe.resetState();
    controller.reset();
    try {
        robot.synchronousMode = true;
        let count = 0;
        const max = 100000;
        while (!universe.isEscaped) {
            controller.tick();
            count++;
            if (count > max) {
                return new VerificationError(`Did not escape the labyrinth in ${max} clock ticks.`);
            }
        }
    } finally {
        robot.synchronousMode = false;
    }
    return new VerificationOk();
}

export class EscapeMissionState implements MissionState {
    isCompleted = false;
    code: MissionSourceFile;

    readonly robot = new Robot();
    readonly universe = new Universe(this.robot);
    readonly robotIo = new RobotIO(this.robot);
    readonly robotRegister = new IoRegister(this.robotIo);
    readonly machine = new Machine([{from: 0x7fff, to: 0x7fff, device: this.robotRegister}]);
    readonly editor = new EditorBackend(new DefaultAssembler());
    readonly controller: Controller;

    constructor(private readonly repository: Repository, data?: AssemblerPersistence) {
        const code = this.getCode(data);
        this.code = new MissionSourceFile(code, this);
        this.controller = new Controller(this.machine, this.editor);
    }
    getCode(data?: AssemblerPersistence) {
        if (data) {
            return data.code;
        } else {
            // default source text
            return `# Assembler code \n`;
        }
    }
    save() {
        const data: AssemblerPersistence = { code: this.code.text };
        this.repository.saveLevel(escapeMission.key, data);
    }
    hasState = true;
    resetState() {
        this.universe.resetState();
        this.controller.reset();
    }
    verify(): VerificationResultSet {
        const result = verifyEscapeCode(this.code.text);
        const results = new UnitVerificationResultSet(result);
        this.isCompleted = results.succeeded;
        return results;
    }
}
