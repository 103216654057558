import { VerificationResultSet, VerificationOk, VerificationError, UnitVerificationResultSet, VerificationResult } from '../app/verificationResults';
import { add, sub, and, inv, neg, bitwiseOr } from '../common/arithmetics';
import { aluResolve } from '../diagram/missions/alu';
import { MissionKind, MissionState, Task } from '../app/task';
import { Repository } from '../app/repository';


export class Bit {
    constructor(readonly line: OpcodeLine, readonly bitIx: number) { }
    toggle() {
        this.bit = !this.bit;
    }
    get bit() {
        return this.line.value[this.bitIx];
    }
    set bit(status: boolean) {
        this.line.value[this.bitIx] = status;
        this.line.changed();
    }
}

export function verifyConfiguration(opcode: string, flags: boolean[]) {
    const expectedFunction = expectedFunctions[opcode];
    if (!expectedFunction) {
        throw new Error(opcode);
    }
    // Test numbers
    // 7, 13 chosen so that neither x & y or x | y equal any of the operands
    // otwerwise we might get false positives in tests
    const testInputs = [[1, 2], [7, 13], [13, 7]];
    for (const [x, y] of testInputs) {
        const expected = expectedFunction(x, y);
        const actual = aluResolve(flags, x, y);
        if (expected !== actual) {
            return `Tried with X = ${x} and Y = ${y}. Expected result ${expected} but was ${actual} `;
        }
    }
    return null;
}

export type ErrorState = 'none' | 'ok' | 'error';
class OpcodeLine {
    value = [false, false, false, false, false, false];
    bits = this.value.map((_, ix) => new Bit(this, ix));
    errorState: ErrorState = 'none';
    constructor(readonly state: OpcodesMissionState, readonly opcode: string) { }
    changed() {
        this.state.save();
        this.errorState = 'none';
    }
    get text() { return this.opcode; }
    verify() {
        const flags = this.value;
        const optError = verifyConfiguration(this.opcode, flags);
        this.errorState = optError ? 'error' : 'ok';
        return optError;
    }

}

type SimFun = ((x: number, y: number) => number);
type ExpectedFunctionMap = { [key: string]: SimFun };
const expectedFunctions: ExpectedFunctionMap = {
    'X+Y': ((x, y) => add(x, y)),
    'X-Y': ((x, y) => sub(x, y)),
    'Y-X': ((x, y) => sub(y, x)),
    '0': ((_x, _y) => 0),
    '1': ((_x, _y) => 1),
    '-1': ((_x, _y) => neg(1)),
    'X': ((x, _y) => x),
    'Y': ((_x, y) => y),
    '-X': ((x, _y) => neg(x)),
    '-Y': ((_x, y) => neg(y)),
    'X+1': ((x, _y) => add(x, 1)),
    'Y+1': ((_x, y) => add(y, 1)),
    'X-1': ((x, _y) => sub(x, 1)),
    'Y-1': ((_x, y) => sub(y, 1)),
    'X&Y': ((x, y) => and(x, y)),
    'X|Y': ((x, y) => bitwiseOr(x, y)),
    '~X': ((x, _y) => inv(x)),
    '~Y': ((_x, y) => inv(y))
};

// start with the simplest
const opcodes = <const>[
    ['X'],
    ['Y'],
    ['X&Y'],
    ['X|Y'],
    ['~X'],
    ['~Y'],
    ['X+Y'],
    ['X-Y'],
    ['Y-X'],
    ['0'],
    ['-1'],
    ['1'],
    ['-X'],
    ['-Y'],
    ['X+1'],
    ['Y+1'],
    ['X-1'],
    ['Y-1'],
];



export class OpcodesMissionState implements MissionState {
    isCompleted = false;
    readonly lines = opcodes.map(([opcode]) => new OpcodeLine(this, opcode));
    constructor(private readonly repository: Repository, data?: OpcodePersistence) {
        if (data) {
            for (const item of data) {
                const state = this.lines.find(o => o.opcode === item.key);
                if (state) {
                    state.value = item.value;
                }
            }
        }
    }
    save() {
        const data = this.lines.map(l => ({ key: l.opcode, value: l.value }));
        this.repository.saveLevel(opcodes1Mission.key, data);
    }
    verifyLines(): VerificationResult {
        for (const line of this.lines) {
            const optError: string | null = line.verify();
            if (optError) {
                return new VerificationError(`Flags for ${line.text} is not correct. ` + optError);
            }
        }
        return new VerificationOk();
    }
    verify(): VerificationResultSet {
        const result = this.verifyLines();
        const results = new UnitVerificationResultSet(result);
        this.isCompleted = results.succeeded;
        return results;
    }
    hasState = false;
    resetState() { /* no state */ }
}

type OpcodePersistence = OpcodeItemPersistence[];
type OpcodeItemPersistence = {
    readonly key: string;
    readonly value: boolean[];
};

export const opcodes1Mission = new class implements Task {
    readonly key = 'OPCODES1';
    readonly kind = MissionKind.Opcodes;
    start(repository: Repository) {
        return new OpcodesMissionState(repository);
    }
    restore(repository: Repository) {
        const data = repository.getLevelData(this.key) as OpcodePersistence;
        return new OpcodesMissionState(repository, data);
    }
};
