import { inv, add, and } from '../../common/arithmetics';


export function aluResolve(flags: boolean[], x: number, y: number) {
    const [zx, nx, zy, ny, f, no] = flags;
    x = zx ? 0 : x;
    x = nx ? inv(x) : x;
    y = zy ? 0 : y;
    y = ny ? inv(y) : y;
    let o = f ? add(x, y) : and(x, y);
    o = no ? inv(o) : o;
    return o;
}
