import React from 'react';
import { MacroAssembler } from '../assembler/assembler';
import { Controller } from '../assembler/controller';
import { EditorBackend } from '../assembler/editorBackend';
import { Machine, IoRegister, MemoryMapping } from '../assembler/machine';
import { AppControls } from '../engine/controls/ControlsComponent';
import { EngineComponent } from '../engine/EngineComponent';
import { AssemblerEditorComponent } from '../ide/editor/EditorComponent';
import { AssemblerMissionState } from './assemblerBlinkMission';
import { DisplayComponent, DisplayDevice } from '../engine/devices/DisplayComponent';
import { GameStateContext } from '../app/gameState';
import { StackInstructionsSet } from './stack/stackInstructionsSet';
import './AssemblerMissionComponent.css';
import { NetworkDeviceComponent } from '../engine/devices/NetworkDeviceComponent';
import { NetworkDevice } from '../engine/devices/networkDevice';

export function NetworkMissionComponent(props: { missionState: AssemblerMissionState }) {
    // async callback from device when state changes
    // should re-render engine since memory mapped state 
    const gameState = React.useContext(GameStateContext);
    function callback() {
        setState(st => ({ ...st }));
    }
    const [state, setState] = React.useState(() => {
        const missionState = props.missionState;
        const source = missionState.code;
        // IO
        const mappings: MemoryMapping[] = [];
        // display
        const display = new DisplayDevice();
        mappings.push({from: 0x4000, to: 0x5FFF, device: display});
        // network device
        const networkDevice = new NetworkDevice();
        const networkRegister = new IoRegister(networkDevice);
        mappings.push({from: 0x6001, to: 0x6001, device: networkRegister});
        networkDevice.changed.addListener(() => callback());
        networkRegister.changed = () => callback();
        const ioRegister = networkRegister;
        
        const machine = new Machine(mappings);
        var stackGroupState = gameState.missionProgression.sharedConstants;
        var combinedConstants = stackGroupState;
        var macroProvider = new StackInstructionsSet(gameState.missionProgression);
        const editor = new EditorBackend(new MacroAssembler(macroProvider, combinedConstants));
        editor.setFile(source);
        const controller = new Controller(machine, editor);
        return {
            source: source,
            networkDevice,
            networkRegister,
            ioRegister,
            machine: machine,
            controller: controller,
            editor: editor,
            display: display,
        };
    });
    function onTick(batchSize: number) {
        let complete = false;
        for (let i = 0; i < batchSize; i++) {
            complete = state.controller.tick();
            if (complete) {
                break;
            }
            state.networkDevice.machineTick();
        }
        setState(st => ({ ...st }));
        return complete;
    }
    function onReset() {
        state.controller.reset();
        state.networkDevice.reset();
        setState(st => ({ ...st }));
    }
    return (
        <div className="ide">
            <div>
                <div style={{ height: '300px' }}>
                    <AssemblerEditorComponent editorBackend={state.editor} />
                </div>
            </div>
            <div className="machine-column">
                <DisplayComponent displayIo={state.display} />
                <NetworkDeviceComponent networkDevice={state.networkDevice} />
                <div className="machine">
                    <div className="machine-header">
                        <p>
                            <b>computer</b>
                        </p>
                        <AppControls 
                            onTick={onTick} 
                            onReset={onReset} 
                            isFinished={state.controller.isFinished}
                          />
                    </div>
                    <div className="engine">
                        <EngineComponent machine={state.machine} ioRegister={state.networkRegister} showMemoryMapped />
                    </div>
                </div>
            </div>
        </div>
    );
}
