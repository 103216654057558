import { bit, word } from '../pins';
import { RequiredComponentTestCase } from '../verification';
import { SequentialTest, Step } from '../sequentialTest';
import { diagram } from './missions';
import { splitterNodeType, bundlerNodeType, selector16NodeType, andNodeType, nandNodeType } from './logicMissions';
import { zero16NodeType } from './arithmeticMissions';
import { ComponentInternalState, StateView } from '../componentType';
import { ComponentInstanceState } from '../circuitState';
import { boolToBit } from '../../common/bits';
import { LampInternalState } from './lamp';
import { BaseBuiltinComponentType } from './baseNodeType';
import { transparent } from './dependency';


export const lampNodeType = new class extends BaseBuiltinComponentType {
    name = 'lamp';
    key = 'LAMP';
    inputs = [bit('on'), bit('off')];
    outputs = [];
    hasInternalState = true;
    displayHint = 'lamp';
    depends = transparent();
    createInternalState = (_node: ComponentInstanceState) => new LampInternalState();
};


export class ButtonInternalState implements ComponentInternalState {
    stateView?: StateView | undefined;
    isPressed = false;
    constructor(private node: ComponentInstanceState) { }
    resolveOutputs(_node: ComponentInstanceState) {
        return [boolToBit(this.isPressed)];
    }
    reset(): void {
        this.isPressed = false;
    }
    setPressed(isPressed: boolean) {
        this.isPressed = isPressed;
        this.node.stateChanged();
    }
}

export const buttonNodeType = new class extends BaseBuiltinComponentType {
    name = 'button';
    key = 'BUTTON';
    inputs = [];
    outputs = [bit()];
    displayHint = 'button';
    hasInternalState = true;
    depends = transparent();
    createInternalState = (node: ComponentInstanceState) =>
        new ButtonInternalState(node);
};


export const inputOutputMission = diagram(<const>{
    key: 'IO2',
    inputPins: [bit('st'), word('X'), bit('cl')],
    outputPins: [word()],
    palette: [
        buttonNodeType,
        lampNodeType,
        splitterNodeType, bundlerNodeType, selector16NodeType, andNodeType,
        nandNodeType, zero16NodeType
    ],
    tests: [
        new RequiredComponentTestCase(lampNodeType, 'lamp component'),
        // Check send 'on' signal to lamp
        new SequentialTest([
            // st, word, cl
            Step.set([0, 0b1011110000000010, 0], 'set bit 0 to 1'),
            Step.internalInput(lampNodeType, 'on', 0, 'when <b>st</b>=0'),
            Step.set([1, 0b1011110000000010, 0], 'set <b>st</b>=1'),
            // turn on
            Step.internalInput(lampNodeType, 'on', 0, 'when <b>cl</b>=0'),
            Step.set([1, 0b1011110000000010, 1], 'set <b>cl</b>=1'),
            Step.internalInput(lampNodeType, 'on', 1, 'when bit 0 is 1'),
        ]),
        // Check send 'off' signal to lamp
        new SequentialTest([
            // st, word, cl
            Step.set([0, 0b1011110000000001, 0], 'set bit 1 to 1'),
            Step.internalInput(lampNodeType, 'off', 0, 'when <b>st</b>=0'),
            Step.set([1, 0b1011110000000001, 0], 'set <b>st</b>=1'),
            // turn off
            Step.internalInput(lampNodeType, 'off', 0, 'when <b>cl</b>=0'),
            Step.set([1, 0b1011110000000001, 1], 'set <b>cl</b>=1'),
            Step.internalInput(lampNodeType, 'off', 1, 'when bit 1 is 1'),
        ]),
        // data should not have effect when st=0
        new SequentialTest([
            // st, word, cl
            Step.set([0, 0b1011110000000001, 0], 'set bit 0 to 1'),
            Step.internalInput(lampNodeType, 'on', 0, 'when <b>st</b>=0 and <b>cl</b>=0'),
            Step.internalInput(lampNodeType, 'off', 0, 'when <b>st</b>=0 and <b>cl</b>=0'),
            Step.set([0, 0b1011110000000001, 1], 'set <b>cl</b>=1'),
            Step.internalInput(lampNodeType, 'on', 0, 'when <b>st</b>=0'),
            Step.internalInput(lampNodeType, 'off', 0, 'when <b>st</b>=0'),
        ]),
        new SequentialTest([
            // Other configurations should not affect lamp
            Step.set([0, 0b1011_1111_1111_1100, 0], 'set bit 0 and 1 to 0'),
            Step.internalInput(lampNodeType, 'on', 0, 'when <b>cl</b>=0'),
            Step.internalInput(lampNodeType, 'off', 0, 'when <b>cl</b>=0'),
            Step.set([1, 0b1011_1111_1111_1100, 0], 'set <b>st</b>=1'),
            Step.set([1, 0b1011_1111_1111_1100, 1], 'set <b>cl</b>=1'),
            Step.internalInput(lampNodeType, 'on', 0, 'when bit 0 and 1 is 0'),
            Step.internalInput(lampNodeType, 'off', 0, 'when bit 0 and 1 is 0'),
        ]),
        // button
        new RequiredComponentTestCase(buttonNodeType, 'button component'),
        new SequentialTest([
            Step.setInternalState(buttonNodeType, btnState => (btnState as ButtonInternalState).setPressed(false)),
            Step.assertOutput('', 0b0000_0000_0000_0000, 'when button not pressed'),
            Step.setInternalState(buttonNodeType, btnState => (btnState as ButtonInternalState).setPressed(true)),
            Step.assertOutput('', 0b1000_0000_0000_0000, 'when button pressed'),
            Step.setInternalState(buttonNodeType, btnState => (btnState as ButtonInternalState).setPressed(false)),
            Step.assertOutput('', 0b0000_0000_0000_0000),
        ]),
    ],
    score: undefined
});
