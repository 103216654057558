

import { Controller } from '../assembler/controller';
import { Machine } from '../assembler/machine';
import { EngineComponent } from '../engine/EngineComponent';
import { MachinecodeComponent, ProgramCounter } from '../machinecode/MachineCodeComponent';
import { CodeMissionState } from './machineCodeMission';
import './MachineCodeMissionComponent.css';
import { useState } from 'react';
import { Bit } from '../machinecode/codeline';

export function MachineCodeMissionComponent(props: {missionState: CodeMissionState}) {
    const missionState = props.missionState;
    const codeProvider = missionState.codeProvider;


    const [state, setState] = useState(()=>{
        const machine = new Machine();
        const programCounter = new ProgramCounter(machine);
        const controller = new Controller(machine, codeProvider);
        const code = missionState.code;
        return {
            machine: machine,
            code: code,
            controller: controller,
            programCounter: programCounter
        };
    })

    function onTick() {
        state.controller.tick();
        setState(st => ({ ...st }));
    }
    function onReset() {
        state.controller.reset();
        setState(st => ({ ...st }));
    }
    function toggleBit(bit: Bit): void {
        bit.toggle();
        setState(st => ({ ...st }));
    }

    return (
        <div className="content machine-code-mission">
            <div className="program">
                <p><b>Instructions in program memory</b></p>
                <MachinecodeComponent toggleBit={toggleBit} code={state.code} 
                    programCounter={state.programCounter} />
            </div>
        
            <div>
                <div className="machine">
                    <div className="machine-header">
                        <p><b>computer</b></p>
                        <div className="btn-group">
                            <button className="btn btn-primary" onClick={onTick}>Clock Tick</button>
                            <button className="btn btn-secondary" onClick={onReset}>Reset</button>
                        </div>
                    </div>
                    <EngineComponent machine={state.machine} showRam={false} />
                </div>
            </div>
        </div>);
}
