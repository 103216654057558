/* eslint no-bitwise: 0 */

import { aluFlagsMappings, jumpFlagsMappings, aluFlagsMappingsReverse, jumpFlagsMappingsReverse } from './mnemonics';
import { boolArrayToNumber, bitToBool } from '../common/bits';
import { BitFields } from '../common/bitFields';

export interface Instruction {
    readonly cInstruction: boolean;
    toText(): string | null;
    toWord(): number;
}

export class AInstruction implements Instruction {
    readonly cInstruction = false;
    readonly value: number;
    constructor(public readonly literalValue: number) {
        this.value = this.literalValue & 0x7fff;
    }

    toWord() {
        return this.value;
    }
    toText() {
        return `A = ${this.value}`;
    }
}

export class CInstruction implements Instruction {
    readonly cInstruction = true;
    // indicate reading from *A
    address = false;
    // destination
    da = false;
    dm = false;
    dd = false;
    // condition
    jumpCondition!: string;
    // computation
    computation!: string;

    toWord() {
        // second and third bit not used but always true
        const prefix = [true, true, true];
        const a = [this.address];
        const computation = aluFlagsMappings[this.computation].map(f => bitToBool(f));
        const destination = [this.da, this.dd, this.dm];
        const jump = jumpFlagsMappings[this.jumpCondition].map(f => bitToBool(f));
        const bits = prefix.concat(a).concat(computation).concat(destination).concat(jump);
        if (bits.length !== 16) {
            throw new Error('bit len');
        }
        return boolArrayToNumber(bits);
    }

    toText() {
        // Special case for unconditional jump
        if (!this.hasDest && this.jumpCondition === 'JMP') {
            return this.jumpCondition;
        }
        let op = this.computation;
        if (!op) {
            return null;
        }
        const y = this.address ? '*A' : 'A';
        const x = 'D';
        op = op.replace('X', x).replace('Y', y);
        return this.getDestText() + op + this.getJmpText();
    }
    get hasDest() {
        return this.da || this.dd || this.dm;
    }
    getDestText() {
        if (!this.hasDest) {
            return '';
        }
        const dests = [];
        if (this.da) {
            dests.push('A');
        }
        if (this.dm) {
            dests.push('*A');
        }
        if (this.dd) {
            dests.push('D');
        }
        const dest = dests.join(',');
        return dest + ' = ';
    }
    getJmpText() {
        if (this.jumpCondition === 'NULL') {
            return '';
        }
        return ';' + this.jumpCondition;
    }
}

export function disassemble(inp: number) {
    const bits = new BitFields(inp);
    const cInstruction = bits.getBit(15);
    if (!cInstruction) {
        return new AInstruction(inp & 0x7fff);
    }
    const i = new CInstruction();
    i.address = bits.getBit(12);
    const opValue = bits.sliceValue(6, 12);
    i.computation = aluFlagsMappingsReverse[opValue];
    const jmpValue = bits.sliceValue(0, 3);
    i.jumpCondition = jumpFlagsMappingsReverse[jmpValue];
    i.da = bits.getBit(5);
    i.dd = bits.getBit(4);
    i.dm = bits.getBit(3);
    return i;
}
