import { Pos } from './position';
import { deleteItem } from '../common/utilities';
import { ComponentType, IComponentInstanceState, IInputConnectorState } from './componentType';
import { NodeInputConnector, NodeOutputConnector, ComponentInstance, CircuitStructure, DiagramEvents, ComponentInstanceEvents, PaletteComponentInstance, InputConnector, EdgeOutputConnector, EdgeInputConnector, OutputConnector, InputGroupStructure, OutputGroupStructure } from './circuitStructure';
import { ConnectorState } from './connectorState';
import { DiagramType } from './diagramMissionType';
export { PaletteComponentInstance };

export class OutputConnectorState {
    state: ConnectorState = 0;
    oscillating = false;

    constructor(readonly connector: OutputConnector) { }

    get numState(): number { return this.state ?? 0; }
    get bitState(): boolean { return this.state === 1; }

    setState(state: number) {
        if (state >= 2 ** this.connector.pin.width) {
            throw new Error(`input ${state} is too large to bus width of ${this.connector.pin.width} bit `);
        }
        this.state = state;
    }

    get name() { return this.connector.name; }
    get width() { return this.connector.width; }
    get index() { return this.connector.index; }
}

class NodeOutputConnectorState extends OutputConnectorState {
    constructor(readonly connector: NodeOutputConnector, readonly node: ComponentInstanceState) {
        super(connector);
    }
}

export class EdgeOutputConnectorState extends OutputConnectorState {
    constructor(readonly connector: EdgeOutputConnector) {
        super(connector);
    }
    clear() {
        this.setState(this.connector.pin.defaultValue);
    }
}

export let connectorIdCounter = 0; 

export class InputConnectorState implements IInputConnectorState {
    state: ConnectorState = 0;
    oscillating = false;
    id = connectorIdCounter++;

    constructor(readonly connector: InputConnector) { }

    get numState(): number { return this.state ?? 0; }
    get bitState(): boolean { return this.state === 1; }

    setState(state: number) {
        if (state >= 2 ** this.connector.pin.width) {
            throw new Error(`input ${state} is too large to bus width of ${this.connector.pin.width} bit `);
        }
        this.state = state;
    }
    createConnection(targetConnector: OutputConnectorState) {
        this.connector.createConnection(targetConnector.connector);
    }
    deleteConnection() { this.connector.deleteConnection(); }

    get name() { return this.connector.name; }
    get width() { return this.connector.width; }
    get index() { return this.connector.index; }
    get connection() { return this.connector.connection; }
}

class NodeInputConnectorState extends InputConnectorState {
    constructor(connector: NodeInputConnector, readonly node: ComponentInstanceState) {
        super(connector);
    }
}

export class EdgeInputConnectorState extends InputConnectorState { }

class NodeStateSnapshot {
    constructor(readonly state: ConnectorState[]) { }
    isSame(state2: NodeStateSnapshot) {
        const s2 = state2.state;
        return this.state.length === s2.length
            && this.state.every((s, ix) => s === s2[ix]);
    }
}

export let componentInstanceIdCounter = 0;

/*
 * State of a component instance in a circuit instance.
 */
export class ComponentInstanceState implements IComponentInstanceState, ComponentInstanceEvents {
    oscillating = false;
    errorMessage?: string;
    internalState;

    inputConnectorStates!: InputConnectorState[];
    outputConnectorStates!: OutputConnectorState[];
    id;

    constructor(readonly diagram: CircuitState, readonly componentInstance: ComponentInstance) {
        // create connector instances
        this.loadConnectors(componentInstance);
        this.internalState = componentInstance.nodeType.createInternalState(this);
        this.componentInstance.registerInstanceState(this);
        this.id = componentInstanceIdCounter++;
    }

    loadConnectors(instance: ComponentInstance) {
        this.inputConnectorStates = instance.inputConnectors.map(cnn => new NodeInputConnectorState(cnn, this));
        this.outputConnectorStates = instance.outputConnectors.map(cnn => new NodeOutputConnectorState(cnn, this));
    }

    resolveOutput() {
        const states = this.internalState.resolveOutputs(this);
        states.forEach((state, ix) => {
            this.outputConnectorStates[ix].state = state;
        });
    }
    getState(): NodeStateSnapshot {
        // the state of output connectors.
        // for convenience we convert into a number
        return new NodeStateSnapshot(this.outputConnectorStates.map(conn => conn.state));
    }
    stateChanged() {
        // callback when internal state changes
        // only used by IO devices, since they can change state on their own
        this.diagram.update();
    }
    delete() { this.componentInstance.delete(); }
    moved() { this.componentInstance.moved(); }

    get pos() { return this.componentInstance.pos; }
    moveTo(pos: Pos) { this.componentInstance.pos = pos; }
    get nodeType() { return this.componentInstance.nodeType; }

    inputPinDeleted(connector: NodeInputConnector) {
        const doomed = this.inputConnectorStates.find(c => c.connector === connector);
        deleteItem(this.inputConnectorStates, doomed);
    }
    outputPinDeleted(connector: NodeOutputConnector) {
        const doomed = this.outputConnectorStates.find(c => c.connector === connector);
        deleteItem(this.outputConnectorStates, doomed);
    }
    inputPinAdded(connector: NodeInputConnector) {
        const cnnState = new NodeInputConnectorState(connector, this);
        this.inputConnectorStates.push(cnnState);
    }
    outputPinAdded(connector: NodeOutputConnector) {
        const cnnState = new NodeOutputConnectorState(connector, this);
        this.outputConnectorStates.push(cnnState);
    }
    markError(message: string) {
        this.errorMessage = message;
        this.oscillating = true;
    }
    clearError() {
        this.errorMessage = undefined;
        this.oscillating = false;
        for (const out of this.outputConnectorStates) {
            out.oscillating = false;
        }
    }
}

export const createPaletteNode = (nodeType: ComponentType) => new PaletteComponentInstance(nodeType);

class StaleDependency {
    constructor(readonly node: ComponentInstanceState, readonly staleState: NodeStateSnapshot) { }
}

export class InputStateGroup {
    constructor(public g: InputGroupStructure | null, public nodes: EdgeOutputConnectorState[]) { }
    get label() { return this.g?.label; }
}

export class OutputStateGroup {
    constructor(public g: OutputGroupStructure | null, public nodes: EdgeInputConnectorState[]) {}
    get label() { return this.g?.label; }
}

/*  Represent the state of a circuit
 *  There may be multiple state instances corresponding to a single circuit structure
 *  e.g each instance of a custom component will have a state instance for the same structure
 */
export class CircuitState implements DiagramEvents {

    inputGroups: InputStateGroup[] = [];
    outputGroups: OutputStateGroup[] = [];
    nodes: ComponentInstanceState[] = [];
    structureNodeMap = new WeakMap<ComponentInstance, ComponentInstanceState>();

    constructor(readonly structure: CircuitStructure) {
        this.nodes = structure.nodes.map(n => this.createNode(n));
        this.loadPins();
        this.update();
        structure.eventListeners.push(this);
        structure.onStructureChange.addListener(() => {
            this.update();
        });
    }
    loadPins() {
        this.inputGroups = this.structure.inputGroups.map(ig =>
            new InputStateGroup(ig, ig.nodes.map(icn => new EdgeOutputConnectorState(icn))));
        this.outputGroups = this.structure.outputGroups.map(og =>
            new OutputStateGroup(og, og.nodes.map(oun => new EdgeInputConnectorState(oun))));
    }
    get inputPins(): EdgeOutputConnectorState[] { return this.inputGroups.flatMap(ig => ig.nodes); }
    get outputPins(): EdgeInputConnectorState[] { return this.outputGroups.flatMap(ig => ig.nodes); }

    addNode(nodeType: ComponentType, pos: Pos) {
        const initialState = nodeType.hasPersistentState ? nodeType.initPersistentState() : undefined;
        const structureNode = this.structure.addNode(nodeType, pos, initialState);
        const node = this.structureNodeMap.get(structureNode);
        if (!node) {
            throw new Error('Node not found in map');
        }
        return node;
    }

    createNode(structureNode: ComponentInstance) {
        const node = new ComponentInstanceState(this, structureNode);
        this.structureNodeMap.set(structureNode, node);
        return node;
    }

    nodeAdded(structureNode: ComponentInstance) {
        this.nodes.push(this.createNode(structureNode));
    }

    deleteNode(node: ComponentInstanceState) {
        this.structure.deleteNode(node.componentInstance);
    }

    // event from structure when a node is deleted
    // delete corresponding node in state layer
    nodeDeleted(structureNode: ComponentInstance) {
        const node = this.structureNodeMap.get(structureNode);
        if (node) {
            deleteItem(this.nodes, node);
        }
    }
    outputPinAdded(cnn: EdgeInputConnector) {
        const cnnState = new EdgeInputConnectorState(cnn);
        this.outputGroups.push(new OutputStateGroup(null, [cnnState]));
    }
    inputPinAdded(cnn: EdgeOutputConnector) {
        const cnnState = new EdgeOutputConnectorState(cnn);
        this.inputGroups.push(new InputStateGroup(null, [cnnState]));
    }
    outputPinDeleted(cnn: EdgeInputConnector) {
        for (const grp of this.outputGroups) {
            grp.nodes = grp.nodes.filter(n => n.connector !== cnn);
        }
        this.outputGroups = this.outputGroups.filter(grp => grp.nodes.length > 0);
    }
    inputPinDeleted(cnn: EdgeOutputConnector) {
        for (const grp of this.inputGroups) {
            grp.nodes = grp.nodes.filter(n => n.connector !== cnn);
        }
        this.inputGroups = this.inputGroups.filter(grp => grp.nodes.length > 0);
    }
    update() {
        this.resolve();
    }
    resolve() {
        // TODO: Should prevent oscillating circuits by detecting recurring states
        // but for now we just keep a count of cycles.
        let counter = 0;
        while (true) {
            const unstable = this.resolveOnce();
            if (unstable.length === 0) { break; }
            counter++;
            if (counter > 100) {
                this.markOscillating(unstable);
                return;
            }
        }
    }

    markOscillating(unstable: ComponentInstanceState[]) {
        const marked: ComponentInstanceState[] = [];
        for (const node of unstable) {
            this.markOscillatingRecursive(node, marked);
        }
    }

    markOscillatingRecursive(node: ComponentInstanceState, marked: ComponentInstanceState[]) {
        if (marked.includes(node)) {
            return;
        }
        node.markError('oscillating between 1 and 0');
        marked.push(node);
        for (const connector of node.outputConnectorStates) {
            connector.state = null;
            connector.oscillating = true;
            for (const connection of connector.connector.connections) {
                const inputConnector = this.findInputConnector(connection.targetConnector);
                inputConnector.state = null;
                inputConnector.oscillating = true;
                if (inputConnector instanceof NodeInputConnectorState) {
                    this.markOscillatingRecursive(inputConnector.node, marked);
                }
            }
        }
    }


    // Resolves the circuit once.
    // Returns list of nodes where other nodes have used a stale state
    // (due to a recursive dependency)
    resolveOnce(): ComponentInstanceState[] {
        const stale: StaleDependency[] = [];
        const resolved: ComponentInstanceState[] = [];

        // first resolve output pins
        // (which recursively will resolve all connected components)
        for (const connector of this.outputPins) {
            this.resolveInput(connector, resolved, [], stale);
        }

        // resolve nodes which have not already been resolved (i.e. islands)
        for (const node of this.nodes) {
            this.resolveNodeOutputs(node, resolved, [], stale);
        }
        return stale.filter(s => !s.node.getState().isSame(s.staleState)).map(s => s.node);
    }

    resolveOutput(outputConnector: OutputConnectorState,
        resolved: ComponentInstanceState[],
        stack: ComponentInstanceState[],
        stale: StaleDependency[]) {
        if (outputConnector instanceof NodeOutputConnectorState) {
            this.resolveNodeOutputs(outputConnector.node, resolved, stack, stale);
        }
    }

    // updates states for all output connectors
    resolveNodeOutputs(node: ComponentInstanceState,
        resolved: ComponentInstanceState[],
        stack: ComponentInstanceState[],
        stale: StaleDependency[]) {

        // if node is already resolved, just return
        if (resolved.includes(node)) {
            return;
        }

        if (stack.includes(node)) {
            // cycle detected, i.e. this node depends on its own state
            // record current state and return it.
            // (this returned state might be stale)
            stale.push(new StaleDependency(node, node.getState()));
            return;
        }
        const stack1 = stack.concat([node]);

        // reset oscillating state
        node.clearError();

        // first resolve inputs
        for (const input of node.inputConnectorStates) {
            this.resolveInput(input, resolved, stack1, stale);
        }
        node.resolveOutput();
        resolved.push(node);
    }

    findInputConnector(connectorStructure: InputConnector) {
        if (connectorStructure instanceof EdgeInputConnector) {
            // might be an output pin
            const outp = this.outputPins.find(n => n.connector === connectorStructure);
            if (!outp) { throw new Error('output pin not found'); }
            return outp;
        }
        const nodeConnector = connectorStructure as NodeInputConnector;
        const nodeStructure = nodeConnector.node;
        const node = this.structureNodeMap.get(nodeStructure);
        if (!node) {
            throw new Error('node not found');
        }
        return node.inputConnectorStates.single(ic => ic.connector === connectorStructure);
    }

    findOutputConnector(connectorStructure: OutputConnector) {
        if (connectorStructure instanceof EdgeOutputConnector) {
            // might be an input pin
            const inp = this.inputPins.find(n => n.connector === connectorStructure);
            if (!inp) { throw new Error('input pin not found'); }
            return inp;
        }
        const nodeConnector = connectorStructure as NodeOutputConnector;
        const nodeStructure = nodeConnector.node;
        const node = this.structureNodeMap.get(nodeStructure)!;
        const cnn = node.outputConnectorStates.find(ic => ic.connector === connectorStructure);
        if (!cnn) { throw new Error('connector not found'); }
        return cnn;
    }


    // Updates state for an input connector. Resolves recursively
    // true if the state was depending on a previous state
    resolveInput(input: InputConnectorState,
        resolved: ComponentInstanceState[],
        stack: ComponentInstanceState[],
        stale: StaleDependency[]) {
        if (input.connector.connection) {
            // the output connector from the other node
            const connection = input.connector.connection;
            const sourceConnector = this.findOutputConnector(connection.sourceConnector);
            this.resolveOutput(sourceConnector, resolved, stack, stale);
            let state = sourceConnector.state;
            // take low bits if input is narrower than output
            if (state) {
                state = state % (2 ** input.width);
            }
            input.state = state;
        } else {
            // disconnected connectors default to 0
            input.state = this.disconnectedState;
        }
        input.oscillating = false;
    }
    get disconnectedState() {
        if (this.structure.diagramType === DiagramType.TransistorLevel) {
            // In transistor-level diagrams, unconnected input pins are not implicitly connected to 0
            return null;
        }
        return 0;
    }

    setInputs(inputs: number[]) {
        if (inputs.length !== this.inputPins.length) {
            const names = this.inputPins.map(inp => inp.name);
            throw new Error(`Input array size mismatch: ${inputs} does not match ${names} `);
        }
        inputs.forEach((state, ix) => {
            this.inputPins[ix].setState(state);
        });
        this.update();
    }

    getOutputs() {
        return this.outputPins.map(n => n.state);
    }

    resolveInputs(inputs: number[]) {
        this.setInputs(inputs);
        return this.getOutputs();
    }

    clearInputs() {
        for (const input of this.inputPins) {
            input.clear();

        }
        this.update();
    }
    get hasState() {
        return this.structure.nodes.some(n => n.hasState);
    }
    resetState() {
        for (const node of this.nodes) {
            node.internalState.reset();
            for (const c of node.outputConnectorStates) {
                c.state = 0;
            }
            for (const c of node.inputConnectorStates) {
                c.state = 0;
            }
        }
        this.update();
    }
    clearCanvas() {
        this.structure.clearCanvas();
    }
    findInternalNode(type: ComponentType) {
        return this.nodes.find(n => n.nodeType === type);
    }
    findInternalNodes(type: ComponentType) {
        return this.nodes.filter(n => n.nodeType === type);
    }
}

