import { PortHints, bit, word, Pin, PinGroup } from '../../diagram/pins';
import {
    nandNodeType, invNodeType, andNodeType, xorNodeType, orNodeType, inv16NodeType, splitterNodeType, bundlerNodeType, selector16NodeType, and16NodeType
} from '../../diagram/missions/logicMissions';
import { zeroNodeType, incNodeType, add16cNodeType, sub16NodeType, add16NodeType, isZero16NodeType, isNegNodeType } from '../../diagram/missions/arithmeticMissions';
import { IOTestCase, numericTest } from '../../diagram/verification';
import { diagram, Truth } from '../../diagram/missions/missions';
import { component } from '../../diagram/missions/baseNodeType';
import { OutputRuleArray } from '../../diagram/missions/outputRules';
import { depends } from '../../diagram/missions/dependency';


export const norMission = diagram(<const>{
    key: 'NOR',
    unlock: true,
    inputPins: [bit('a'), bit('b')],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType],
    truth: [
        [[false, false], [true]],
        [[false, true], [false]],
        [[true, false], [false]],
        [[true, true], [false]],
    ] as Truth,
    score: { min: 2, nands: 4 }
});


export const xnorMission = diagram(<const>{
    key: 'XNOR',
    unlock: true,
    inputPins: [bit('a'), bit('b')],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType],
    truth: [
        [[false, false], [true]],
        [[false, true], [false]],
        [[true, false], [false]],
        [[true, true], [true]],
    ] as Truth,
    score: { min: 2, nands: 5 }
});

export const shlMission = diagram(<const>{
    key: 'SHL',
    unlock: true,
    inputPins: [
        word()
    ],
    outputPins: [
        word()
    ],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, splitterNodeType, bundlerNodeType, zeroNodeType],
    tests: [
        numericTest([0], [0]),
        numericTest([1], [2]),
        numericTest([2], [4]),
        numericTest([4], [8]),
        numericTest([0x0100], [0x0200]),
        numericTest([0x0200], [0x0400]),
        numericTest([0x0400], [0x0800]),
        numericTest([0x0800], [0x1000]),
        numericTest([0x1000], [0x2000]),
        numericTest([0x2000], [0x4000]),
        numericTest([0x4000], [0x8000]),
        // high bit is discarded
        numericTest([0x8000], [0x0000]),
    ],
    score: { min: 0 }
});


export const shlNodeType = component('shl',
    'SHL',
    [word()],
    [word()],
    new OutputRuleArray(([a]) =>
        [(a << 1) & 0xFFFF]
    ),
    depends(shlMission)
);

export const shrNodeType = component('shr',
    'SHR',
    [word()],
    [word()],
    new OutputRuleArray(([a]) =>
        [(a >> 1) & 0xFFFF]
    ),
    depends(shlMission)
);

/*
export const minMission = diagram(<const>{
    key: 'MIN16',
    unlock: true,
    inputPins: [new Pin(16, 'A'), new Pin(16, 'B')],
    outputPins: [word()],
    palette: [
        nandNodeType, invNodeType, orNodeType, incNodeType,
        add16NodeType, sub16NodeType, isZero16NodeType, isNegNodeType,
        selector16NodeType,
        inv16NodeType
    ],
    tests: [
        numericTest([0, 0], [0]),
        numericTest([1, 0], [0]),
        numericTest([0, 1], [0]),
        numericTest([1, 1], [1]),
        numericTest([1, 2], [1]),
        numericTest([0x7000, 2], [2]),

    ],
    score: undefined /* TODO * /,
});
*/

export const maxMission = diagram(<const>{
    key: 'MAX16',
    unlock: true,
    inputPins: [new Pin(16, 'A'), new Pin(16, 'B')],
    outputPins: [word()],
    palette: [
        nandNodeType, invNodeType, orNodeType, incNodeType,
        add16NodeType, sub16NodeType, isZero16NodeType, isNegNodeType,
        selector16NodeType,
        inv16NodeType
    ],
    tests: [
        numericTest([0, 0], [0]),
        numericTest([1, 0], [1]),
        numericTest([0, 1], [1]),
        numericTest([1, 1], [1]),
        numericTest([1, 2], [2]),
        numericTest([0x7000, 2], [0x7000]),

    ],
    score: undefined /* TODO */,
});

/*
export const minNodeType = component('min',
    'MIN16',
    [word(), word()],
    [word()],
    new OutputRuleArray(([a, b]) =>
        [a < b ? a : b]
    ),
    depends(minMission, 1)
);
*/

export const maxNodeType = component('max',
    'MAX16',
    [word(), word()],
    [word()],
    new OutputRuleArray(([a, b]) =>
        [a > b ? a : b]
    ),
    depends(maxMission, 1)
);

export const mulMission = diagram(<const>{
    key: 'MUL16',
    unlock: true,
    inputPins: [new Pin(16, 'A', PortHints.Signed), new Pin(16, 'B', PortHints.Signed)],
    outputPins: [word()],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, incNodeType,
        add16cNodeType, selector16NodeType,
        inv16NodeType, shlNodeType, and16NodeType,
        bundlerNodeType, splitterNodeType
    ],
    tests: [
        numericTest([0, 0], [0]),
        numericTest([1, 0], [0]),
        numericTest([0, 1], [0]),
        numericTest([1, 1], [1]),
        numericTest([1, 2], [2]),
        numericTest([1, 3], [3]),
        numericTest([2, 2], [4]),
        numericTest([4, 2], [8]),
        numericTest([23, 97], [2231]),
    ],
    score: undefined /* TODO */,
});


export const barrelShlMission = diagram({
    key: 'SHL16',
    unlock: true,
    tag: 'Preview',
    inputPins: [new PinGroup('count', [bit('1'), bit('0')]), new Pin(16)],
    outputPins: [word()],
    palette: [
        nandNodeType, invNodeType, orNodeType, incNodeType,
        shlNodeType, selector16NodeType
    ],
    tests: [
        new IOTestCase([0, 0, 27], [27]),
        new IOTestCase([0, 1, 27], [27 << 1]),
        new IOTestCase([1, 1, 27], [27 << 3])
    ],
    score: undefined, // TODO
});

export const barrelShlNodeType = component('b.shl',
    'BARREL_SHL',
    [new Pin(4), word()],
    [word()],
    new OutputRuleArray(([count, a]) =>
        [(a << count) & 0xFFFF]
    ),
    depends(barrelShlMission)
);

export const barrelShrNodeType = component('b.shr',
    'BARREL_SHR',
    [new Pin(4), new Pin(16)],
    [new Pin(16)],
    new OutputRuleArray(([count, a]) =>
        [(a >> count) & 0xFFFF]
    ),
    depends(barrelShlMission)
);


export const barrelShr22NodeType = component('b.shr',
    'BARREL_SHR22',
    [new Pin(4, 'ct'), new Pin(22)],
    [new Pin(22)],
    new OutputRuleArray(([count, a]) =>
        [(a >> count) & 0xFFFF]
    ),
    depends(barrelShlMission)
);
