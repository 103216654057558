import { useState } from 'react';
import { assembleInstruction } from '../../../assembler/assembler';
import { disassemble } from '../../../assembler/instructions';


export function AsmInput(props: {value: number, update: (value: number) => void}) {
	const [state, setState] = useState(()=>{
		return { value: props.value, asmString: toAsmString(props.value), error: false}; 
	});
    if (props.value !== state.value) {
        reset(props.value);
    }
    function reset(value: number) {
        setState({value: value, asmString: toAsmString(value), error: false});
    }
    function toAsmString(word: number) {
        return disassemble(word).toText();
    }
    function mousedown(ev: React.MouseEvent) {
        // avoid drag start if in component
        ev.stopPropagation();
    }
    function blur() {
        if (state.error) {
            reset(props.value);
        }
    }
    function setAssemblerState(instr: string) {
        try {
            const word = assembleInstruction(instr).toWord();
            setState({value: word, asmString: instr, error: false});
            props.update(word);
        } catch (e) {
            setState({...state, asmString: instr, error: true});
        }
    }
    return (<div className='input-row'>
        <label>
            asm<> </>
            <input type='text' size={10} 
                value={state.asmString ?? '???'} 
                onChange={(ev)=>setAssemblerState(ev.target.value)} 
                onMouseDown={mousedown} 
                onBlur={blur}
                className={'assembler-input ' + (state.error ? 'is-invalid' : '')} />
        </label>
        </div>);
}
