import { AssemblerPersistence, MissionSourceFile } from './assemblerMissions';
import {
    UnitVerificationResultSet,
    VerificationError,
    VerificationOk,
    VerificationResult,
    VerificationResultSet,
} from '../app/verificationResults';
import { MissionKind, Task } from '../app/task';
import { Repository } from '../app/repository';
import { MissionProgression } from '../app/missionProgression';
import { Machine } from '../assembler/machine';
import { AssemblerMissionState } from './assemblerBlinkMission';
import { DefaultAssembler } from '../assembler/assembler';
import { HeadlessCodeContainer } from '../assembler/headlessCodeContainer';
import { Controller } from '../assembler/controller';
import { DisplayDevice, getFigureBounds } from '../engine/devices/DisplayComponent';

/*
    The logo should be at least 16 x 16, but otherwise we dont care how it looks.
*/
export function verifyDisplayCode(sourceText: string, ): VerificationResult {
    // (1) Verify syntax of source code
    const assembler = new DefaultAssembler();
    const program = assembler.assemble(sourceText);
    if (program.errors.length > 0) {
        return new VerificationError(`Syntax error in assembler code: ${program.errors[0].errorText} `);
    }
    const allErrors = program.getAllErrors();
    if (allErrors.length > 0) {
        return new VerificationError(`Error in assembler code ${allErrors[0]}`);
    }
    if (program.instructions.length === 0) {
        return new VerificationError('Program does not have any instructions.');
    }
    // (2) Execute code in sandbox
    const display = new DisplayDevice();
    const machine = new Machine([{from: 0x4000, to: 0x6000, device: display}]);
    const a = new DefaultAssembler();
    const provider = new HeadlessCodeContainer(a);
    provider.setCode(sourceText);
    const controller = new Controller(machine, provider);
    /*
     *  Run up to 1000 clock cycles 
     */
    let ticks = 0;
    while (machine.pc.get() < program.instructions.length) {
        controller.tick();
        if (ticks++ > 1000) {
            break;
        }
    }
    if (ticks > 1000) {
        return new VerificationError('Ran more than 1000 clock cycles without finishing.');
    }
    const logoBounds = getFigureBounds(display);
    if (logoBounds === undefined) {
        return new VerificationError('Did not detect any pixels drawn on the display.');
    }
    if (logoBounds.height < 16) {
        return new VerificationError(`Height of logo is only ${logoBounds.height} pixels.`);
    }
    if (logoBounds.width < 16) {
        return new VerificationError(`Width of logo is only ${logoBounds.width} pixels.`);
    }
    return new VerificationOk();
}




export class DisplayMissionState implements AssemblerMissionState {
    enableDisplay = true;
    enableLamp = false;
    code: MissionSourceFile;
    isCompleted = false;
    constructor(private readonly task: Task, private readonly repository: Repository, readonly data?: AssemblerPersistence) {
        const code = this.getInitialCode(data);
        this.code = new MissionSourceFile(code, this);
    }
    private getInitialCode(data?: AssemblerPersistence) {
        if (data) {
            return data.code;
        } else {
            // default source text
            return `# Assembler code \n`;
        }
    }
    save() {
        const data: AssemblerPersistence = { code: this.code.text };
        this.repository.saveLevel(this.task.key, data);
    }
    verify(): VerificationResultSet {
        const result = verifyDisplayCode(this.code.text);
        const results = new UnitVerificationResultSet(result);
        this.isCompleted = results.succeeded;
        return results;
    }
    hasState = false;
    resetState() {
        /* not supported in the toolbar, although there is a reset button on the engine */
    }
}

export const displayMission = new class implements Task {
    readonly key = 'ASSEMBLER_DISPLAY';
    readonly kind = MissionKind.Assembler;
    start(repository: Repository, _missions: MissionProgression): DisplayMissionState {
        return new DisplayMissionState(this, repository);
    }
    restore(repository: Repository, _missions: MissionProgression): DisplayMissionState {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new DisplayMissionState(this, repository, data);
    }
};
