import { assembleValidate } from './assembler';

/*
 *  Assembler code to generate fibbonacci sequence in RAM
 *  Used for various smoke tests
 */
const fibCode =
`# This program generates the fibonacci sequence of numbers
# in the RAM. It is used to check that the components
# are connected correctly.
#
# START: Initialize
    A = 0
    *A = 1
    A = A+1
    *A = 1
    # store, so D is last written address before loop
    D = A
.loop:
    A = D-1
    D = *A
    A = A+1
    D = D+*A
    A = A+1
    *A = D
    # store, so D is last written address before loop
    D = A
    # Jump to LOOP if address is not overflow
    A = .loop
    D;JGT
.infiniteLoop:
    A = .infiniteLoop
    0;JMP
`;

// we cache the compiled code the first time it is used
let _fibMachineCode: null | number[] = null;
export function fibMachineCode(): number[] {
    if (!_fibMachineCode) {
        _fibMachineCode = assembleValidate(fibCode);
    }
    return _fibMachineCode;
}
