import { useState } from 'react';
import { DefaultAssembler } from '../assembler/assembler';
import { Controller } from '../assembler/controller';
import { EditorBackend } from '../assembler/editorBackend';
import { Machine, IoRegister, MemoryMapping } from '../assembler/machine';
import { BlinkenlightComponent } from '../engine/devices/BlinkenlightComponent';
import { AppControls } from '../engine/controls/ControlsComponent';
import { EngineComponent } from '../engine/EngineComponent';
import { AssemblerEditorComponent } from '../ide/editor/EditorComponent';
import { AssemblerMissionState } from './assemblerBlinkMission';
import { DisplayComponent, DisplayDevice } from '../engine/devices/DisplayComponent';
import "./AssemblerMissionComponent.css";
import { LampDevice } from '../assembler/lampDevice';

export function AssemblerMissionComponent(props: { missionState: AssemblerMissionState }) {
    // async callback from device when state changes
    // should re-render engine since memory mapped state 
    function callback() {
        setState(st => ({ ...st }));
    }
    const [state, setState] = useState(() => {
        const missionState = props.missionState;
        const source = missionState.code;
        const lampIo = new LampDevice();
        const lampRegister =  missionState.enableLamp ? new IoRegister(lampIo) : undefined;
        const display = missionState.enableDisplay ? new DisplayDevice() : undefined;
        const mappings: MemoryMapping[] = [];
        let ioRegister;
        if (lampRegister) {
            mappings.push({from: 0x7fff, to: 0x7fff, device: lampRegister});
            ioRegister = lampRegister;
        }
        if (display) {
            mappings.push({from: 0x4000, to: 0x5FFF, device: display});
        }
        const machine = new Machine(mappings);
        const editor = new EditorBackend(new DefaultAssembler());
        editor.setFile(source);
        const controller = new Controller(machine, editor);
        return {
            source: source,
            lampIo,
            lampRegister,
            ioRegister,
            machine: machine,
            controller: controller,
            editor: editor,
            display: display
        };
    });

    function onTick() {
        const complete = state.controller.tick();
        setState(st => ({ ...st }));
        return complete;
    }
    function onReset() {
        state.controller.reset();
        setState(st => ({ ...st }));
    }
    const isFinished = state.controller.isFinished;
    return (
        <div className="ide">
            <div>
                <div style={{ height: '300px' }}>
                    <AssemblerEditorComponent editorBackend={state.editor} />
                </div>
            </div>
            <div className="machine-column">
                {state.display && <DisplayComponent displayIo={state.display} /> }
                {state.lampRegister && <BlinkenlightComponent lampIo={state.lampIo} /> }
                <div className="machine">
                    <div className="machine-header">
                        <p>
                            <b>computer</b>
                        </p>
                        <AppControls onTick={onTick} onReset={onReset} isFinished={isFinished} />
                    </div>
                    <div className="engine">
                        <EngineComponent machine={state.machine} ioRegister={state.ioRegister} showMemoryMapped />
                    </div>
                </div>
            </div>
        </div>
    );
}
