import { MacroAssembler, PlaceholderValues } from '../../assembler/assembler';
import { Controller } from '../../assembler/controller';
import { HeadlessCodeContainer } from '../../assembler/headlessCodeContainer';
import { Machine } from '../../assembler/machine';
import { VerificationError, VerificationOk, VerificationResult } from '../../app/verificationResults';
import { add, sub } from '../../common/arithmetics';
import { ConstantsProvider, InstructionProvider } from '../../assembler/instructionProvider';

export abstract class CodeTester {
    readonly SP = 0;
    readonly ARGS = 1;
    readonly LOCALS = 2;
    readonly TEMP = 5;
    readonly RETVAL = 6;
    readonly STACK_START = 0x100;
    constructor(readonly macros: InstructionProvider, 
        readonly constants: ConstantsProvider,
        readonly placeholders?: PlaceholderValues
    ) {}
    getPlaceholders() {
        return this.placeholders;
    }
    test(sourceText: string): VerificationResult {
        // (1) Verify syntax of macro source code
        const placeholders = this.getPlaceholders();
        const assembler = new MacroAssembler(this.macros, this.constants, placeholders);
        const program1 = assembler.assemble(sourceText);
        const errors = program1.errors;
        if (errors.length > 0) {
            const err = errors[0];
            console.error('assembler errors', program1.errors.length, program1.errors);
            return new VerificationError(`Syntax error in assembler code: ${err.errorText!} at line ${err.lineNumber} `);
        }
        const allErrors = program1.getAllErrors();
        if (allErrors.length > 0) {
            return new VerificationError(`Error in assembler code ${allErrors[0]}`);
        }
        if (program1.instructions.length === 0) {
            return new VerificationError('Program does not have any instructions.');
        }

        // (2) Execute test code in sandbox
        const machine = new Machine();
        const a = new MacroAssembler(this.macros, this.constants, placeholders);
        const provider = new HeadlessCodeContainer(a);
        const testCode = this.getTestCode(sourceText);
        provider.setCode(testCode);
        const program = provider.program;
        if (program.errors.length > 0) {
            const err = program.errors[0];
            return new VerificationError(`Syntax error in test code: ${err.errorText!} at line ${err.lineNumber} `);
        }
        if (program.instructions.length === 0) {
            return new VerificationError('Test program does not have any instructions.');
        }
        const controller = new Controller(machine, provider);

        this.reset(machine);
        this.init(machine);
        this.setup(machine);

        // execute 100 steps or until end of program
        let count = 0;
        while (machine.pc.get() < program.instructions.length) {
            controller.tick();
            count++;
            if (count > 100) {
                return new VerificationError('Ran 100 clock cycles without finishing.');
            }
        }

        return this.verify(machine);
    }
    getTestCode(missionCode: string) {
        return missionCode;
    }
    reset(machine: Machine) {
        machine.ram.pokeImmediately(0, 0);
    }
    init(machine: Machine) {
        // set up stack pointer. Corresponding to INIT macro
        machine.ram.pokeImmediately(0, 0x100);
    }
    setD(machine: Machine, val: number) {
        machine.d.setImmediately(val);
    }
    push(machine: Machine, val: number) {
        let sp = machine.ram.peek(0);
        machine.ram.pokeImmediately(sp, val);
        sp = add(sp, 1);
        machine.ram.pokeImmediately(0, sp);
    }
    pop(machine: Machine) {
        let sp = machine.ram.peek(0);
        const val = machine.ram.peek(sp);
        sp = sub(sp, 1);
        machine.ram.pokeImmediately(0, sp);
        return val;
    }
    expectStacTop(machine: Machine, val: number) {
        const top = this.stackTop(machine);
        if (top !== val) {
            return new VerificationError(`Expected the number ${val} to be on the stack (Was ${top.toString(16)}).`);
        }
    }
        /* stack top is SP - 1 */
    stackTop(machine: Machine) {
        const sp = machine.ram.peek(this.SP);
        return machine.ram.peek(sp - 1);
    }
    expectStack(machine: Machine, depth: number) {
        const expectedSp = this.STACK_START + depth;
        const sp = machine.ram.peek(this.SP);
        if (sp !== expectedSp) {
            return new VerificationError(`Expected SP (Ram address 0) to be hex ${expectedSp.toString(16)}. (Was ${sp.toString(16)})`);
        }
    }
    expectMemory(machine: Machine, addr: number, expectedVal: number) {
        const actualVal = machine.ram.peek(addr);
        if (actualVal !== expectedVal) {
            return new VerificationError(
                `Expected ram address ${addr} to have value hex ${expectedVal.toString(16)}. (Was ${actualVal.toString(16)})`
            );
        }
    }
    abstract setup(machine: Machine): void;
    abstract verify(machine: Machine): VerificationResult;
}



export function pureStackTest(stack: number[], stackAfter: number[]) {
    return class extends CodeTester {
        setup(machine: Machine) {
            stack.forEach(val => this.push(machine, val));
        }
        verify(machine: Machine) {
            let err = this.expectStack(machine, stackAfter.length);
            if (err) {
                return err;
            }
            if (stackAfter.length !== 1) {
                throw new Error('Not supported yet!');
            }
            const expectedTop = stackAfter[0];
            err = this.expectStacTop(machine, expectedTop);
            if (err) {
                return err;
            }
            return new VerificationOk();
        }
    };
}
