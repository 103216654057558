import { useState } from 'react';
import './edge-node.css';
import './node.css';
import { BinaryComponent } from './BinaryComponent';
import { OutputConnectorComponent } from '../connector/OutputConnectorComponent';
import { bitArrayToNumber } from '../../../common/bits';
import { aluFlagsMappingsOrdered, aluFlagsMappings } from '../../../assembler/mnemonics';
import { ComponentBuilder } from '../../componentBuilder';
import { EdgeOutputConnectorState, InputConnectorState } from '../../circuitState';
import React from 'react';
import { HexInput } from './HexInput';
import { DecimalInput } from './DecimalInput';
import { AsmInput } from './AsmInput';
import { DiagramActions } from '../builder/DiagramBuilder';


export function InputNodeComponent(props: {
        isPalette: boolean;
        isFree: boolean;
        selectedConnector: InputConnectorState | undefined;
        connector: EdgeOutputConnectorState;
        model: ComponentBuilder
        diagramActions: DiagramActions;
    }) { 

    const [state, setState] = useState(props.connector.numState);
    const isWord =  props.connector.connector.pin.width === 16;

    function setNodeState(state: number) {
        props.connector.setState(state);
        props.model.diagram.update();
        setState(props.connector.numState);
        props.diagramActions.stateChanged();
    }
    const pinTypeHint =  props.connector.connector.pin.typeHint;

    /* --alu inp-- */
    const options =  aluFlagsMappingsOrdered.map(([key, _value]) => key);
    function select(key: string) {
        const flags = aluFlagsMappings[key];
        const val = bitArrayToNumber(flags);
        setNodeState(val);
    }
    const elementRef = React.createRef<HTMLDivElement>();

    function toggleState() {
        setNodeState(1 - state);
    }
    function deletePin() {
        props.diagramActions.deleteInputPin(props.connector);
    }
    function editLabel() {
        const newLbl = window.prompt('label');
        if (newLbl !== null) {
            props.diagramActions.updateInputPinLabel(connector, newLbl);
        }
    }
	const connector = props.connector;
	const model = props.model;
	const isPalette = props.isPalette;

	return (<div ref={elementRef} className='outer'>

    <div className='diagram-node input-node'>
        <div className='connector-row'>
            <OutputConnectorComponent 
                connector={connector} 
                isPalette={isPalette} 
                isFree={props.isFree} 
                selectedConnector={props.selectedConnector}
                diagramActions={props.diagramActions}
                />
        </div>

        { pinTypeHint === 'aluOperation' && (
            <div className='node-box word'>
                <div className='binary-word'>
                    <BinaryComponent value={state} width={6} />
                </div>

                <div>
                    <label>
                        Op
                        <select onChange={(ev)=>select(ev.target.value)}>
                            {(options).map(opt => (
                                <option>{opt}</option>))} 
                        </select>
                    </label>
                </div>
            </div>
        )} 
        { pinTypeHint !== 'aluOperation' && (
            <>
            { isWord && (
                <div className='node-box word'>
                    <div className='binary-word'>
                        <BinaryComponent value={state} />
                    </div>
                    { pinTypeHint === 'instruction' && (
                        <AsmInput value={state} update={setNodeState} />
                    )} 

                    <div>
                        <HexInput value={state} update={setNodeState} />
                    </div>

                    { pinTypeHint === 'signed' && (
                        <DecimalInput value={state} update={setNodeState} />
                    )} 

                </div>
            )} 
            { !isWord && (
                <>
                { pinTypeHint === 'constant' && (
                    <div className='node-box' style={{width: '90px'}}>
                        <div className='node-label' style={{width: '80px'}}>
                            { state === 0 && (
                                <>Ground ⏚<br />(always 0)</>)} 
                            { state === 1 && (
                                <>Power +<br />(always 1)</>)} 
                        </div>
                    </div>
                )} 
                { pinTypeHint !== 'constant' && (
                    
                    <div onClick={toggleState} className='node-box'>
                        <div className='state'>
                            {state}
                        </div>
                        <div className='node-label'>
                            { state===0 && (
                                <>☐</>)} 
                            { state===1 && (
                                <>☑</>)} 
                        </div>
                    </div>
                )} 
                </>)} 
            </>)} 

    </div>

    { model.canModifyPins && (
        <div>
        <div className='dropup'>
            <button type='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='btn btn-sm btn-light dropdown-toggle'>
            </button>
            <ul className='dropdown-menu'>
                <li><button onClick={deletePin} className='dropdown-item'><i className='bi bi-x-lg'></i> Delete</button></li>
                <li><button onClick={editLabel} className='dropdown-item'><i className='bi bi-tag'></i> Label</button></li>
            </ul>
        </div>
    </div>)} 
</div>
); 
}




