import { ConstantsProvider, InstructionProvider, Placeholder } from './instructionProvider';
import { Instruction } from './instructions';
import { Parser } from './parser';
import { AsmInstructionSyntax, AsmLabel, AsmLoadLabelInstruction, AsmMacroInvocation, AsmSyntax, LabelMap } from './syntax';


/*  Collects label definitions
 */
class AssemblerState {
    definitions = new LabelMap();
    addr = 0; // address of next instruction
    registerLabel(labelNode: AsmLabel) {
        const id = labelNode.identifier.toUpperCase();
        if (this.definitions.labels.get(id)) {
            labelNode.error(`Label '${labelNode.identifier}' is already defined.`);
            return;
        }
        this.definitions.labels.set(id, labelNode);
    }
}

/*
 * Parsed syntax tree for an assembler program
 */
export class AssemblerProgram {
    // instructions is the code with all macros expanded
    instructions: AsmInstructionSyntax[] = [];
    constructor(readonly lines: AsmSyntax[]) {
        const state = new AssemblerState();
        const defs = this.definitionsPass(lines, state);
        this.resolvePass(lines, defs);
        this.instructions = [];
        this.resolveAddresses(lines);
        this.resolveLabelAddresses(lines);
    }

    get machineCode() {
        return this.instructions.map(i => i.instruction.toWord());
    }

    get errors() {
        return this.lines.filter(i => !i.isValid);
    }
    // errors including errors in macro expansions
    getAllErrors() {
        function getAllErrors(lines: AsmSyntax[]): string[] {
            const errors = lines.filter(i => !i.isValid).map(l => l.errorText!);
            const macroErrors = lines
                .filter(line => line instanceof AsmMacroInvocation)
                .flatMap(line => getAllErrors((line as AsmMacroInvocation).expansion));
            return errors.concat(macroErrors);
        }
        return getAllErrors(this.lines);
    }

    // Registers labels
    definitionsPass(lines: AsmSyntax[], state: AssemblerState) {
        for (const line of lines) {
            if (line.isValid) {
                if (line instanceof AsmLabel) {
                    state.registerLabel(line);
                }
                if (line instanceof AsmMacroInvocation && line.expansion) {
                    const subScope = new AssemblerState();
                    this.definitionsPass(line.expansion, subScope);
                    line.definitions = subScope.definitions;
                }
            }
        }
        return state.definitions;
    }

    // resolves label references to syntax nodes
    resolvePass(lines: AsmSyntax[], defs: LabelMap) {
        for (const line of lines) {
            if (line.isValid) {
                if (line instanceof AsmLoadLabelInstruction) {
                    const labelDef = defs.labels.get(line.identifier.toUpperCase());
                    if (!labelDef) {
                        line.setIdentifierError(`label '${line.identifier}' not found.`);
                    } else {
                        line.labelReference = labelDef;
                    }
                }
                if (line instanceof AsmMacroInvocation && line.expansion) {
                    const innerDefs = line.definitions!;
                    // Verify lable arguments exist, and pass to macro expansion scope
                    for (const { token: labelToken} of line.labelArguments) {
                        const labelDef = defs.labels.get(labelToken.value.toUpperCase());
                        if (!labelDef) {
                            labelToken.isValid = false;
                            line.setError(`label '${labelToken.value}' not found.`);
                        } else {
                            // Pass label definition to macro expansion.
                            // Since the label text will have been replaced at expnasion time
                            // this will be the same name
                            innerDefs.labels.set(labelDef.identifier.toUpperCase(), labelDef);
                        }
                    }
                    // Resolve recursively in the expanded macro
                    this.resolvePass(line.expansion, innerDefs);
                }
            }
        }
    }
    // generate sequence of instructions (including macro expansions (recursively))
    resolveAddresses(lines: AsmSyntax[]) {
        for (const line of lines) {
            if (line.isValid) {
                if (line instanceof AsmLabel) {
                    // will be the address of the next instruction
                    // since a label does not itself occupy an address
                    line.labelAddress = this.instructions.length;
                }
                if (line instanceof AsmMacroInvocation && line.expansion) {
                    this.resolveAddresses(line.expansion);
                }
                if (line instanceof AsmInstructionSyntax) {
                    line.address = this.instructions.length;
                    this.instructions.push(line);
                }
            }
        }
    }
    // After all valid labels have assigned an address, we resolve references to labels into adresses
    resolveLabelAddresses(lines: AsmSyntax[]) {
        for (const line of lines) {
            if (line instanceof AsmLoadLabelInstruction) {
                if (line.isValid) {
                    line.resolveReference();
                }
            }
            if (line instanceof AsmMacroInvocation && line.expansion) {
                this.resolveLabelAddresses(line.expansion);
            }
        }
    }
}

export class PlaceholderValues implements ConstantsProvider {
    names;
    map;
    constructor(readonly placeholders: Placeholder[]) {
        this.names = placeholders.map(p => p.name);
        this.map = new Map(placeholders.map(n => [n.name, 0]));
    }
    get(name: string) {
        return this.map.get(name);
    }
    set(name: string, value: number) {
        return this.map.set(name, value);
    }
}

export class MacroAssembler {
    parser;
    constructor(readonly macros: InstructionProvider, 
        readonly constants: ConstantsProvider,
        readonly placeholders?: PlaceholderValues,
    ) {
        this.parser = new Parser(macros, constants, [], placeholders);
    }
    assemble(code: string) {
        const stmts = this.parser.scanLines(code);
        return new AssemblerProgram(stmts);
    }
}

class EmptyMacroProvider implements InstructionProvider {
    names: string[] = [];
    get(_name: string) {
        return undefined;
    }
}

class EmptyConstantsProvider implements ConstantsProvider {
    names: string[] = [];
    get(_name: string) {
        return undefined;
    }
}

export class DefaultAssembler extends MacroAssembler {
    constructor() {
        super(new EmptyMacroProvider(), new EmptyConstantsProvider(), new PlaceholderValues([]));
    }
    scanLine1(line: string): AsmSyntax {
        return this.parser.scanAndParseLine(line, -1);
    }
}

export class DefaultParser extends Parser {
    constructor() {
        super(new EmptyMacroProvider(), new EmptyConstantsProvider());
    }
}

/*
 * Assembles source to machine code (without preserving a syntax tree)
 * Throws on error
 * */
export function assembleValidate(code: string) {
    const assembler = new DefaultAssembler();
    const program = assembler.assemble(code);
    if (program.getAllErrors().length > 0) {
        throw new Error();
    }
    return program.machineCode;
}

/*  Parses an assembler instruction but discards the AST and returns just the instruction
 */
export function assembleInstruction(line: string): Instruction {
    const defaultParser = new DefaultParser();
    const syntx = defaultParser.parseInstruction(line);
    return syntx.instruction;
}
