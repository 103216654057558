import * as Instructions from './instructions';
import { FunctionDefinition, Segment } from './instructions';
import { Consumer, Token, Scanner, TokenDefinition, TokenAction, splitOn } from './scanner';
import { Option, ErrorResult, SourcedErrorResult } from '../compiler/shared';

/* Vm source code might contain different segments with different tracking info
    This is not yet supported */
export interface SourceSegmentSet {
    toString(): string;
}

export class VmScanner extends Scanner {
    constructor() {
        const patterns = [
            new TokenDefinition('//.*?$', TokenAction.Ignore),
            new TokenDefinition('[ \\t]+', TokenAction.Ignore),
            new TokenDefinition('[\\n\\r]+', TokenAction.Token, 'LineBreak'),
            new TokenDefinition('\\d+', TokenAction.Token, 'Number'),
            new TokenDefinition('\\S+', TokenAction.Token, 'Identifier'),
            new TokenDefinition('\\.', TokenAction.Error),
        ];
        super(patterns);
    }
}

export class CodeUnit {
    constructor(public functions: Map<string, FunctionDefinition>) {}
}

class VmParser {
    unit;
    functions = new Map<string, FunctionDefinition>();
    constructor(src: SourceSegmentSet) {
        this.unit = this.parse(src);
    }
    consumeSegment(tokens: Consumer) {
        const token = this.expectToken(tokens, 'segment');
        if (!Object.values(Segment).includes(token.value as Segment)) {
            throw new SourcedErrorResult(`Invalid segment: '${token.value}'`, token);
        }
        return token.value as Segment;
    }
    consumeInstruction(tokens: Consumer, fun: FunctionDefinition): Instructions.Instruction | undefined {
        const token = tokens.consume();
        const type = token.value;

        switch (type.toLocaleLowerCase()) {
            case 'label': {
                const label = tokens.consume().value;
                const addr = fun.instructions.length;
                fun.labels.set(label, new Instructions.Label(addr));
                // don't return an instruction.
                return undefined;
            }
            case 'init_stack': {
                return new Instructions.InitStackInstruction(token);
            }
            case 'stop': {
                return new Instructions.StopInstruction(token);
            }
            case 'goto': {
                const symbol = tokens.consume().value;
                return new Instructions.GotoInstruction(token, symbol);
            }
            case 'if_goto': {
                const symbol = tokens.consume().value;
                return new Instructions.IfGotoInstruction(token, symbol);
            }
            case 'add':
                return new Instructions.Add(token);
            case 'sub':
                return new Instructions.Sub(token);
            case 'neg':
                return new Instructions.Neg(token);
            case 'eg':
                return new Instructions.Eq(token);
            case 'lt':
                return new Instructions.Lt(token);
            case 'gt':
                return new Instructions.Gt(token);
            case 'and':
                return new Instructions.And(token);
            case 'or':
                return new Instructions.Or(token);
            case 'not':
                return new Instructions.Not(token);
            case 'push': {
                const segment = this.consumeSegment(tokens);
                const index = this.consumeInteger(tokens);
                return new Instructions.PushInstruction(token, segment, index);
            }
            case 'push_value': {
                const value = this.consumeInteger(tokens);
                return new Instructions.PushValueInstruction(token, value);
            }
            case 'pop': {
                const segment = this.consumeSegment(tokens);
                const index = this.consumeInteger(tokens);
                return new Instructions.PopInstruction(token, segment, index);
            }
            case 'call': {
                const functionName = tokens.consume().value;
                const argumentsCount = this.consumeInteger(tokens);
                return new Instructions.CallInstruction(token, functionName, argumentsCount);
            }
            case 'return':
                return new Instructions.ReturnInstruction(token);
            default:
                throw new SourcedErrorResult(`Unsupported instruction: '${type}' `, token);
        }
    }
    parse(src: SourceSegmentSet) {
        const s = new VmScanner();
        const tokens = s.scan(src.toString());
        const lines = splitOn(tokens, 'LineBreak');

        // consume top level code (code not inside a function), if any.
        // in case of top-level code, it is added to a pseudo-function called .toplevel
        const hasTopLevelCode = lines.length > 0 && lines[0][0].value.toLocaleLowerCase() !== 'function';
        if (hasTopLevelCode) {
            const pseudoFunction = new FunctionDefinition(null as unknown as Token, 0);
            this.consumeInstructions(pseudoFunction, lines);
            if (pseudoFunction.instructions.length > 0) {
                pseudoFunction.isTop = true;
                this.functions.set('.toplevel', pseudoFunction);
            }
        }
        // consume functions
        while (lines.length > 0) {
            this.consumeFunction(lines);
        }

        // validate functions
        new Validator(this.functions);
        return new CodeUnit(this.functions);
    }
    consumeFunction(lines: Token[][]) {
        const line = lines.shift()!;
        const consumer = new Consumer(line);
        const keywordToken = consumer.consume();
        if (keywordToken.value.toLocaleLowerCase() !== 'function') {
            throw new SourcedErrorResult(`Expected 'function' `, keywordToken);
        }
        const nameToken = this.expectToken(consumer, 'name');
        const name = nameToken.value;
        if (this.functions.has(name)) {
            throw new SourcedErrorResult(`Function with name '${name}' is already defined.`, nameToken);
        }
        const localsCount = this.consumeInteger(consumer);
        const fun = new FunctionDefinition(keywordToken, localsCount);
        this.functions.set(name, fun);
        this.consumeInstructions(fun, lines);
    }
    /* consumes instructions until EOF or new function */
    consumeInstructions(fun: FunctionDefinition, lines: Token[][]) {
        while (lines.length > 0 && lines[0][0].value.toLocaleLowerCase() !== 'function') {
            const line = lines.shift()!;
            const consumer = new Consumer(line);
            const instr = this.consumeInstruction(consumer, fun);
            // returns after consuming enough tokens, so if there are more on the same line it must be an error
            if (!consumer.atEnd) {
                const unexpectedToken = consumer.consume();
                throw new SourcedErrorResult(`Unexpected token '${unexpectedToken.value}' after instruction`, unexpectedToken);
            }
            // labels are also parsed but does not generate an instruction
            if (instr) {
                fun.instructions.push(instr);
            }
        }
    }
    expectToken(tokens: Consumer, expected: string) {
        if (tokens.atEnd) {
            // TODO: how to indicate position
            const previousToken = tokens.last;
            const endOfToken = {
                startCharacterIndex: previousToken.endCharacterIndex,
            };
            throw new SourcedErrorResult(`Unexpected end of line. Expected ${expected} `, endOfToken);
        }
        return tokens.consume();
    }
    consumeInteger(tokens: Consumer) {
        const token = this.expectToken(tokens, 'integer');
        if (token.type !== 'Number') {
            const foundText = token.kind.typeName ? token.kind.typeName : `${token.value}'`;
            throw new SourcedErrorResult(`Expected integer, found: ${foundText} `, token);
        }
        return parseInt(token.value);
    }
    consumeName(tokens: Consumer) {
        return tokens.consume();
    }
}

/*
    Semantic validation of VM code
*/
class Validator {
    constructor(public functions: Map<string, FunctionDefinition>) {
        this.functions.forEach(fun => this.validateFunction(fun));
    }
    validateFunction(fun: FunctionDefinition) {
        // assign label addresses
        // resolve functions
        for (const instr of fun.instructions) {
            if (instr instanceof Instructions.GotoInstruction) {
                const label = fun.labels.get(instr.symbol);
                if (!label) {
                    throw new SourcedErrorResult(`Label '${instr.symbol}' not found for goto.`, instr.token);
                }
                instr.address = label.addr;
            }
            if (instr instanceof Instructions.CallInstruction) {
                const functionDefinition = this.functions.get(instr.functionName);
                if (!functionDefinition) {
                    throw new SourcedErrorResult(`Function '${instr.functionName}' not found.`, instr.token);
                }
                instr.functionDefinition = functionDefinition;
            }
        }
        if (!fun.isTop) {
            // check function ends with a return
            if (fun.instructions.length > 0) {
                const last = fun.instructions[fun.instructions.length - 1];
                if (last.type.toLocaleLowerCase() !== 'return') {
                    throw new SourcedErrorResult(`Function block should end with a return.`, last.token);
                }
            } else {
                // empty function. Should at least have a return!
                throw new SourcedErrorResult(`Function block should end with a return. `, fun.token);
            }
        } else {
            if (this.functions.size > 1) {
                // TODO: top level code should exit before function
                const last = fun.instructions[fun.instructions.length - 1];
                if (last.type.toLocaleLowerCase() !== 'stop') {
                    throw new SourcedErrorResult(`Top level code should end with a STOP before first function.`, last.token);
                }
            }
        }
    }
}

/* Generated code need support for different segments which different tracking info,
    but the runtime library is just a single string, so generate a single segment. */
class StringSourceProvider {
    segments: string[];
    constructor(src: string) {
        this.segments = [src];
    }
}
export function parseVmStr(src: string): Option<CodeUnit> {
    return parseVm(new StringSourceProvider(src));
}

export function parseVm(src: SourceSegmentSet): Option<CodeUnit> {
    try {
        const p = new VmParser(src);
        return p.unit;
    } catch (e) {
        if (e instanceof ErrorResult) {
            return e;
        }
        console.error(e);
        return new ErrorResult(`Internal parser error: ${(e as Error).message} `);
    }
}

export function linkVm(unit1: CodeUnit, unit2: CodeUnit): Option<CodeUnit> {
    try {
        return linkVm1(unit1, unit2);
    } catch (e) {
        if (e instanceof ErrorResult) {
            return e;
        }
        console.error(e);
        return new ErrorResult(`Internal linker error: ${(e as Error).message} `);
    }
}

/** Link multiple code units. Errors if a function name is defined multiple times. */
export function linkVm1(unit1: CodeUnit, unit2: CodeUnit): CodeUnit {
    const map = new Map(unit1.functions.entries());
    unit2.functions.forEach((func, name) => {
        if (map.has(name)) {
            throw new ErrorResult(`Function with name '${name}' defined multiple times. `);
        }
    });
    return new CodeUnit(map);
}
