import './errors.css';

window.addEventListener('error', (event: ErrorEvent) => {
    console.error('on error: ', event);
    let {error} = event;
    /*
    // Ignore errors that will be processed by componentDidCatch.
    // SEE: https://github.com/facebook/react/issues/10474
    if (error.stack && error.stack.indexOf('invokeGuardedCallbackDev') >= 0) {
        console.log('handled by boundary');
        return true;
    }
    */
    reportUnhandledError(error);
    return true;
});

/* Rejected promises are not caught by onerror, so need a seperare event handler
    Unfortunately we don't get stack trace from rejected promises. 
*/
window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
    console.error('on unhandledrejection: ', event);
    reportUnhandledError(event.reason);
    return true;
});
  
export function reportUnhandledError(error: any){
    try {
        const errorText = error instanceof Error ? error.name + ': ' + error.message + '\n' + error.stack 
        : error.toString();
        logErrorOnServer(errorText);
        const body = document.querySelector('body')!;
        const div = body.appendChild(document.createElement('div'));
        console.log('ERROR', error, error instanceof Error)
        div.setAttribute('class', 'top-level-error-box')
        div.innerHTML = `
            <div>An error occurred.
            <button onClick="location.reload()" class="btn btn-primary">Try again</button>
            </div>
            <div><b>Error details:</p>
            <pre>${errorText}</pre>
            </div>
            `;
    }
    catch (e: unknown) {
      console.error('Error in error handling', e);
    }
}

function logErrorOnServer(errorText: string) {
    // post to server, but ignore any errors
    fetch('https://backend.nandgame.com/api/errors', { 
        method: 'POST', 
        body: JSON.stringify({ message: errorText }),
        headers: {
            'Content-Type': 'application/json'
            },
    }).catch(err => {
        console.log('posting error failed', err);
    }).then(() => {
        console.log('error logged on server');
    });
}
  
