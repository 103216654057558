import { MissionKind, MissionStatus } from '../../app/task';
import { MissionItem, MissionProgression } from '../../app/missionProgression';
import { StackMission } from './stackMissionState';

import './MacroList.css';

function MacroLabel(props: { mission: MissionItem; selectMission: (m: MissionItem) => void; currentMission: MissionItem }) {
    const mission = props.mission.mission as StackMission;
    const macroName = mission.macro.name;
    if (props.mission === props.currentMission) {
        return (
            <div className="list-group-item active">
                <i className="bi bi-play-fill"></i>
                <span>{macroName}</span>
            </div>
        );
    }
    if (props.mission.status === MissionStatus.Locked) {
        return (
            <div className="list-group-item list-group-item-danger">
                <i className="bi bi-lock-fill"></i>
                <span>{macroName}</span>
            </div>
        );
    } else if (props.mission.status === MissionStatus.Complete) {
        return (
            <button className="list-group-item list-group-item-action"
                onClick={() => props.selectMission(props.mission)}>
                <i className="bi bi-check-lg"></i>
                <span>{macroName}</span>
            </button>
        );
    } else {
        return (
            <div className="list-group-item list-group-item-action list-group-item-danger">
                <i className="bi bi-x-lg"></i>
                <button className="btn" onClick={() => props.selectMission(props.mission)}>
                    {macroName}
                </button>
            </div>
        );
    }
}

export function MacroList(props: {
    history: MissionProgression;
    selectMission: (m: MissionItem) => void;
    currentMission: MissionItem;
}) {
    const macroMisssions = props.history.activatedItems.filter(m => m.mission.kind === MissionKind.Stack);
    const levels = macroMisssions.map((m, ix) => (
        <MacroLabel key={ix} mission={m} currentMission={props.currentMission} selectMission={props.selectMission} />
    ));
    return <nav className="macro-list">{levels}</nav>;
}
