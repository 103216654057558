import { Machine } from '../../assembler/machine';
import { AssemblerPersistence } from '../assemblerMissions';
import { VerificationOk } from '../../app/verificationResults';
import { CodeTester } from './codeTester';
import { StackMissionState } from './stackMissionState';
import { Placeholder, PlaceholderType } from '../../assembler/instructionProvider';
import { Repository } from '../../app/repository';
import { MissionProgression } from '../../app/missionProgression';
import { MissionKind } from '../../app/task';

/* Macro should push memory value on stack */
class PushStaticTest extends CodeTester {
    setup(machine: Machine) {
        machine.ram.pokeImmediately(42, 17);
    }
    getPlaceholders() {
        const placeholders = this.placeholders!;
        placeholders.set('address', 42);
        return placeholders;
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 1);
        if (err) {
            return err;
        }
        err = this.expectStacTop(machine, 17);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export const pushStaticMissionTests = [PushStaticTest];

export class PushStaticMissionState extends StackMissionState {
    tests = pushStaticMissionTests;
}

export const pushStaticMission = {
    key: 'STACK_PUSH_STATIC',
    kind: MissionKind.Stack,
    macro: {
        name: 'PUSH_STATIC',
        parameters: [new Placeholder('address', PlaceholderType.Address)],
    },
    start(repository: Repository, game: MissionProgression) {
        return new PushStaticMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PushStaticMissionState(this, repository, game, data);
    },
};

class PopStaticTest extends CodeTester {
    override setup(machine: Machine) {
        this.push(machine, 17);
    }
    getPlaceholders() {
        const placeholders = this.placeholders!;
        placeholders.set('address', 42);
        return placeholders;
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 0);
        if (err) {
            return err;
        }
        err = this.expectMemory(machine, 42, 17);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export const popStaticMissionTests = [PopStaticTest];

export class PopStaticMissionState extends StackMissionState {
    tests = popStaticMissionTests;
}

export const popStaticMission = {
    key: 'STACK_POP_STATIC',
    kind: MissionKind.Stack,
    macro: {
        name: 'POP_STATIC',
        parameters: [new Placeholder('address', PlaceholderType.Address)],
    },
    start(repository: Repository, game: MissionProgression) {
        return new PopStaticMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PopStaticMissionState(this, repository, game, data);
    },
};


class PushMemoryTest extends CodeTester {
    setup(machine: Machine) {
        machine.ram.pokeImmediately(1042, 17);
        this.push(machine, 1042);
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 1);
        if (err) {
            return err;
        }
        err = this.expectStacTop(machine, 17);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export const pushMemoryMissionTests = [PushMemoryTest];

export class PushMemoryMissionState extends StackMissionState {
    tests = pushMemoryMissionTests;
}

// addr -> val
export const pushMemoryMission = {
    key: 'STACK_PUSH_MEMORY',
    kind: MissionKind.Stack,
    macro: {
        name: 'PUSH_MEMORY',
        parameters: [],
    },
    start(repository: Repository, game: MissionProgression) {
        return new PushMemoryMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PushMemoryMissionState(this, repository, game, data);
    },
};

class PopMemoryTest extends CodeTester {
    override setup(machine: Machine) {
        machine.ram.pokeImmediately(1001, 32);
        this.push(machine, 1001);
        this.push(machine, 17);
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 0);
        if (err) {
            return err;
        }
        err = this.expectMemory(machine, 1001, 17);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export const popMemoryMissionTests = [PopMemoryTest];

export class PopMemoryMissionState extends StackMissionState {
    tests = popMemoryMissionTests;
}

// addr, val -> ()
export const popMemoryMission = {
    key: 'STACK_POP_MEMORY',
    kind: MissionKind.Stack,
    macro: {
        name: 'POP_MEMORY',
        parameters: [],
    },
    start(repository: Repository, game: MissionProgression) {
        return new PopMemoryMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PopMemoryMissionState(this, repository, game, data);
    },
};


/* Macro should push memory value on stack */
class PushSegmentOffsetTest extends CodeTester {
    setup(machine: Machine) {
        // set ARGS to 1000
        machine.ram.pokeImmediately(1, 1000);
        machine.ram.pokeImmediately(1000, 42);
        machine.ram.pokeImmediately(1000 - 3, 42);
        machine.ram.pokeImmediately(1000 + 3, 42);
    }
    override getTestCode() {
        return 'PUSH_SEGMENT_OFFSET 1 3';
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 1);
        if (err) {
            return err;
        }
        err = this.expectStacTop(machine, 42);
        if (err) {
            return err;
        }
        return new VerificationOk();
    }
}

export class PushSegmentOffsetMissionState extends StackMissionState {
    tests = [PushSegmentOffsetTest];
}

export const pushSegmentOffsetMission = {
    key: 'STACK_PUSH_SEGMENT_OFFSET',
    kind: MissionKind.Stack,
    macro: {
        name: 'PUSH_SEGMENT_OFFSET',
        parameters: [new Placeholder('address', PlaceholderType.Address), new Placeholder('index', PlaceholderType.Integer)],
    },
    start(repository: Repository, game: MissionProgression) {
        return new PushSegmentOffsetMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PushSegmentOffsetMissionState(this, repository, game, data);
    },
};

class PopSegmentOffsetTest extends CodeTester {
    override setup(machine: Machine) {
        this.push(machine, 17);
        machine.ram.pokeImmediately(2, 2000);
        machine.ram.pokeImmediately(2000 - 1, 42);
    }
    override getTestCode() {
        return 'POP_SEGMENT_OFFSET 2 1';
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 0);
        if (err) {
            return err;
        }
        err = this.expectMemory(machine, 2000 - 1, 42);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export class PopSegmentOffsetMissionState extends StackMissionState {
    tests = [PopSegmentOffsetTest];
}

export const popSegmentOffsetMission = {
    key: 'STACK_POP_SEGMENT_OFFSET',
    kind: MissionKind.Stack,
    macro: {
        name: 'POP_SEGMENT_OFFSET',
        parameters: [new Placeholder('address', PlaceholderType.Address), new Placeholder('index', PlaceholderType.Integer)],
    },
    start(repository: Repository, game: MissionProgression) {
        return new PopSegmentOffsetMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PopSegmentOffsetMissionState(this, repository, game, data);
    },
};

/* Macro should push memory value on stack */
export class PushValueTest extends CodeTester {
    override setup(_machine: Machine) {
        /* no setup */
    }
    getPlaceholders() {
        const placeholders = this.placeholders!;
        placeholders.set('value', 42);
        return placeholders;
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 1);
        if (err) {
            return err;
        }
        err = this.expectStacTop(machine, 42);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export const pushValueMissionTests = [PushValueTest];

export class PushMValueMissionState extends StackMissionState {
    tests = pushValueMissionTests
}

export const pushValueMission = {
    key: 'STACK_PUSH_VALUE',
    kind: MissionKind.Stack,
    macro: {
        name: 'PUSH_VALUE',
        parameters: [new Placeholder('value', PlaceholderType.Integer)],
    },
    start(repository: Repository, game: MissionProgression) {
        return new PushMValueMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PushMValueMissionState(this, repository, game, data);
    },
};

/* Macro should push memory value on stack */
class PushLocalTest extends CodeTester {
    setup(machine: Machine) {
        // set LOCAL to 1000
        machine.ram.pokeImmediately(this.LOCALS, 1000);
        machine.ram.pokeImmediately(1000 + 3, 42);
    }
    override getTestCode(_missionCode: string) {
        return 'PUSH_LOCAL 3';
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 1);
        if (err) {
            return err;
        }
        err = this.expectStacTop(machine, 42);
        if (err) {
            return err;
        }
        return new VerificationOk();
    }
}

export class PushLocalMissionState extends StackMissionState {
    tests = [PushLocalTest];
}

export const pushLocalMission = {
    key: 'STACK_PUSH_LOCAL',
    kind: MissionKind.Stack,
    macro: {
        name: 'PUSH_LOCAL',
        parameters: [new Placeholder('index', PlaceholderType.Integer)],
    },
    start(repository: Repository, game: MissionProgression) {
        return new PushLocalMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PushLocalMissionState(this, repository, game, data);
    },
};

class PopLocalTest extends CodeTester {
    override setup(machine: Machine) {
        this.push(machine, 17);
        machine.ram.pokeImmediately(this.LOCALS, 2000);
        machine.ram.pokeImmediately(2000 + 2, 42);
    }
    override getTestCode(_missionCode: string) {
        return 'POP_LOCAL 2';
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 0);
        if (err) {
            return err;
        }
        err = this.expectMemory(machine, 2000 + 2, 42);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export class PopLocalMissionState extends StackMissionState {
    tests = [PopLocalTest];
}

export const popLocalMission = {
    key: 'STACK_POP_LOCAL',
    kind: MissionKind.Stack,
    macro: {
        name: 'POP_LOCAL',
        parameters: [new Placeholder('index', PlaceholderType.Integer)],
    },
    start(repository: Repository, game: MissionProgression) {
        return new PopLocalMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PopLocalMissionState(this, repository, game, data);
    },
};

/* Macro should push memory value on stack */
class PushArgTest extends CodeTester {
    setup(machine: Machine) {
        // set ARGS to 1000
        machine.ram.pokeImmediately(this.ARGS, 1000);
        machine.ram.pokeImmediately(1000, 42);
        machine.ram.pokeImmediately(1000 - 3, 42);
        machine.ram.pokeImmediately(1000 + 3, 42);
    }
    override getTestCode() {
        return 'PUSH_ARG 3';
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 1);
        if (err) {
            return err;
        }
        err = this.expectStacTop(machine, 42);
        if (err) {
            return err;
        }
        return new VerificationOk();
    }
}

export class PushArgMissionState extends StackMissionState {
    tests = [PushArgTest];
}

export const pushArgMission = {
    key: 'STACK_PUSH_ARG',
    kind: MissionKind.Stack,
    macro: {
        name: 'PUSH_ARG',
        parameters: [new Placeholder('index', PlaceholderType.Integer)],
    },
    start(repository: Repository, game: MissionProgression) {
        return new PushArgMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PushArgMissionState(this, repository, game, data);
    },
};

class PopArgTest extends CodeTester {
    override setup(machine: Machine) {
        this.push(machine, 17);
        machine.ram.pokeImmediately(this.ARGS, 2000);
        machine.ram.pokeImmediately(2000 - 1, 42);
    }
    override getTestCode() {
        return 'POP_ARG 1';
    }
    verify(machine: Machine) {
        let err = this.expectStack(machine, 0);
        if (err) {
            return err;
        }
        err = this.expectMemory(machine, 2000 - 1, 42);
        if (err) {
            return err;
        }

        return new VerificationOk();
    }
}

export class PopArgMissionState extends StackMissionState {
    tests = [PopArgTest];
}

export const popArgMission = {
    key: 'STACK_POP_ARG',
    kind: MissionKind.Stack,
    macro: {
        name: 'POP_ARG',
        parameters: [new Placeholder('index', PlaceholderType.Integer)],
    },
    start(repository: Repository, game: MissionProgression) {
        return new PopArgMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new PopArgMissionState(this, repository, game, data);
    },
};
