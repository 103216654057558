import { ComponentInstanceState } from '../circuitState';
import { ComponentInstance } from '../circuitStructure';
import { ConnectorState } from '../connectorState';
import { BaseBuiltinComponentType } from './baseNodeType';
import { bit, word } from '../pins';
import { transparent } from './dependency';


export const constNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'const';
    readonly key = 'CONST';
    readonly inputs = [];
    readonly outputs = [word()];
    readonly depends = transparent();
    readonly hasPersistentState = true;
    readonly displayHint = 'const';
    createInternalState = () => ({
        reset() {},
        resolveOutputs: (node: ComponentInstanceState): ConnectorState[] =>
            // just return the constant value
            [node.componentInstance.persistentState as number]
    });
    getPersistentState = (node: ComponentInstance) => node.persistentState as number;
    /* Instance-specific persistent state  */
    initPersistentState = () => 0;
    restorePersistentState = (data: any) =>
        data ?? this.initPersistentState();
};

export const bitSelectNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'bit';
    readonly key = 'BIT_SELECT';
    readonly inputs = [word()];
    readonly outputs = [bit()];
    readonly depends = transparent();
    readonly hasPersistentState = true;
    readonly displayHint = 'bit';
    createInternalState = () => ({
        reset() { },
        resolveOutputs: (node: ComponentInstanceState): ConnectorState[] => {
            const bitNr = node.componentInstance.persistentState as number;
            const state = node.inputConnectorStates[0]?.state ?? 0;
            const bitValue = (state >> bitNr) & 1;
            return [bitValue];
        }
    });
    getPersistentState = (node: ComponentInstance) => node.persistentState as number;
    /* Instance-specific persistent state  */
    initPersistentState = () => 0;
    restorePersistentState = (data: any) =>
        data ?? this.initPersistentState();
};

