import { ComponentType } from './componentType';
import { TruthTable } from './truthTable';
import { Hint } from './hint';
import { TestCase, } from './verification';
import { InputPinDefinition, OutputPinDefinition } from './pins';


export interface MissionTexts {
    title: string;
    splash?: string;
    specification: string;
    spoilers?: string[];
    elaboration?: string;
    debriefing?: string;
    hintText(key: string): string;
}

export type Score = {
    min: number;
    nands?: number;
};

export enum DiagramType {
    TransistorLevel
}

export interface DiagramMissionType {
    readonly key: string;
    readonly unlock?: boolean;
    readonly tag?: string;
    readonly inputPins: readonly OutputPinDefinition[];
    readonly outputPins: readonly InputPinDefinition[];
    readonly palette: readonly ComponentType[];
    readonly tests: readonly TestCase[];
    readonly hints?: readonly Hint[];
    readonly truthTable?: TruthTable;
    readonly score?: Score;
    readonly diagramType?: DiagramType;
}


