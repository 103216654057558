import { MacroAssembler, PlaceholderValues } from '../../assembler/assembler';
import { Controller } from '../../assembler/controller';
import { EditorBackend } from '../../assembler/editorBackend';
import { ConstantsProvider, InstructionProvider, Placeholder } from '../../assembler/instructionProvider';
import { Machine } from '../../assembler/machine';
import { AssemblerPersistence, MissionSourceFile } from '../assemblerMissions';
import { Repository } from '../../app/repository';
import { MissionProgression } from '../../app/missionProgression';
import { CompositeVerificationResultSet, VerificationResult } from '../../app/verificationResults';
import { CodeTester } from './codeTester';
import { StackInstructionsSet } from './stackInstructionsSet';
import { MissionState, Task } from '../../app/task';

interface MacroSpecification {
    name: string;
    parameters?: Placeholder[];
}

export interface StackMission extends Task {
    macro: MacroSpecification;
}

type TestClass = new (
    macros: InstructionProvider, 
    constants: ConstantsProvider,
    placeholders:  PlaceholderValues
    ) => CodeTester;

export abstract class StackMissionState implements MissionState {
    readonly code: MissionSourceFile;
    isCompleted = false;
    readonly machine;
    readonly editorBackend;
    readonly controller;
    readonly macroProvider;
    readonly placeholders;
    readonly globalConstants;
    constructor(
        public readonly mission: StackMission,
        private readonly repository: Repository,
        protected readonly game: MissionProgression,
        data?: AssemblerPersistence
    ) {
        const code = this.getCode(data);
        this.code = new MissionSourceFile(code, this);
        this.machine = new Machine();
        this.globalConstants = game.sharedConstants;
        this.placeholders = new PlaceholderValues(this.mission.macro.parameters ?? []);
        this.macroProvider = new StackInstructionsSet(game);
        this.editorBackend = new EditorBackend(
            new MacroAssembler(this.macroProvider, this.globalConstants, this.placeholders));
        this.editorBackend.setFile(this.code);
        this.controller = new Controller(this.machine, this.editorBackend);
    }
    defaultCode = `# Assembler code \n`;
    getCode(data?: AssemblerPersistence) {
        if (data) {
            return data.code;
        } else {
            return this.defaultCode;
        }
    }
    save() {
        const data: AssemblerPersistence = { code: this.code.text };
        this.repository.saveLevel(this.mission.key, data);
    }
    verify() {
        const tests = this.tests;
        const results: VerificationResult[] = tests.map(t => 
            new t(this.macroProvider, this.globalConstants, this.placeholders).test(this.code.text));
        const result = new CompositeVerificationResultSet(results);
        this.isCompleted = result.succeeded;
        return result;
    }
    hasState = false;
    resetState() {
        /* do nothing */
    }
    abstract tests: TestClass[];
}
