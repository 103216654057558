import { ReactNode } from 'react';
import Draggable from 'react-draggable';
import './game/popup-dialog.css';

export function WithPopupWindow(props: { 
    visible: boolean; 
    children: ReactNode; 
    title?: string; 
    onClose: () => void;
}) {
    if (!props.visible) {
        return null;
    }
    return (
        <Draggable onStart={ev=>ev.stopPropagation()} cancel='button'>
        <div className="popup-dialog levels card">
            {props.title && 
            <div className="card-header">
                {props.title}
                <button type="button" className="btn-close" aria-label="Close" onClick={props.onClose}></button>
            </div>}
            {props.children}
        </div>
        </Draggable>
    );
}

export function WithSimplePopupWindow(props: { 
    visible: boolean; 
    children: ReactNode; 
    title: string; 
    onClose: () => void;
}) {
    return (
        <WithPopupWindow title={props.title} onClose={props.onClose} visible={props.visible}>
            <div className="content card-body">
                <div>{props.children}</div>
            </div>
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" aria-label="Close" onClick={props.onClose}>
                    Close
                </button>
            </div>
        </WithPopupWindow>
    );
}
