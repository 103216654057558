import React from 'react';
import textJson from '../languages/en.json';


type LanguageName = { lang: string; name: string };

export type TranslationDict = { [key: string]: TranslationNode };
export type TranslationNode = string | TranslationDict | TranslationNode[];

export const enTexts: TranslationDict = textJson as unknown as TranslationDict;

export class TextService {
    languageNames: LanguageName[] = [
        { lang: 'en', name: 'English' },
        { lang: 'ru', name: 'Русский' },
        { lang: 'zh_cn', name: '中文' },
        { lang: 'fr', name: 'Français' },
    ];
    private supported = this.languageNames.map(ln => ln.lang);
    currentLang = 'en';
    enTexts = enTexts;
    private loadedLanguages: { [key: string]: TranslationDict } = { 'en': this.enTexts };
    current: TranslationDict = this.enTexts;

    async changeLanguage(lang: string) {
        if (!(this.supported.includes(lang))) {
            console.log('language not supported', lang);
            return;
        }
        if (lang in this.loadedLanguages) {
            this.current = this.loadedLanguages[lang];
            this.currentLang = lang;
        } else {
            console.log('lazy load lang', lang);
            try {
                const response = await fetch(`./languages/${lang}.json?` + Math.random());
                console.log('load', lang);
                const data = await response.json(); 
                this.loadedLanguages[lang] = data as TranslationDict;
                this.current = data as TranslationDict;
                this.currentLang = lang;
            } catch(err: unknown) {
                console.error(err);
            }
        }
    }
    getText(...keys: string[]): string {
        const node = this.getNode(...keys);
        if (!this.isStringNode(node)) {
            return '';
        }
        return node;
    }
    getOptText(...keys: string[]): (string | undefined) {
        const node = this.getNode(...keys);
        if (node === undefined) {
            return node;
        }
        if (!this.isStringNode(node)) {
            return '';
        }
        return node;
    }
    isStringNode(node: TranslationNode | undefined): node is string {
        return typeof node === 'string';
    }
    getNode(...keys: string[]): TranslationNode | undefined {
        let node = this.getNodeIn(this.current, keys);
        if (node === undefined && this.currentLang !== 'en') {
            // if current lang is not english and text is missing, then fall back to English
            node = this.getNodeIn(this.enTexts, keys);
        }
        return node;
    }
    getList(...keys: string[]): TranslationNode[] {
        const node = this.getNode(...keys);
        if (Array.isArray(node)) {
            return node;
        }
        return [];
    }
    getNodeIn(texts: TranslationDict, keys: string[]) {
        let base: TranslationNode = texts;
        for (const key of keys) {
            base = (base as TranslationDict)[key];
            if (!base) {
                return undefined;
            }
        }
        return base;
    }
}

export const TextContext = React.createContext(null as unknown as TextService);
