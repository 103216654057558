
// Currently only used for diagram input pins
type PinTypeHint = string;

export type InputPinDefinition = Pin | PinGroup;
export type OutputPinDefinition = Pin | PinGroup;

export class Pin {
    readonly defaultValue: number = 0;
    constructor(readonly width: number, public name: string = '',
        public typeHint?: PinTypeHint) { }
}

// A set of ports which are displayed close together
export class PinGroup {
    constructor(readonly name: string, readonly ports: Pin[]) { }
}


// Currently only used for diagram input pins
// Affect how the pin state is displayed
export enum PortHints {
    Signed = 'signed',
    Instruction = 'instruction',
    AluOperation = 'aluOperation',
    ConditionFlags = 'jumpOperation',
    AluDestination = 'aluTarget'
}

/* convenience functions for specifying pins */
export const bit = (name = '') => new Pin(1, name);
export const word = (name = '') => new Pin(16, name);



/*  Only used for source and drain pins which cannot be changed or reset
 */
export class ConstantPin implements Pin {
    readonly width = 1;
    readonly typeHint = 'constant';
    constructor(public name: string, public defaultValue: number) { }
}
