import { useState, useContext, useEffect } from 'react';
import './popup-dialog.css';
import './GameComponent.css';
import { DiagramHint } from '../../diagram/components/tip/DiagramHint';
import { DiagramBuilder } from '../../diagram/components/builder/DiagramBuilder';
import { SuccessDialog } from './SuccessDialog';
import { TruthTableComponent } from '../../diagram/components/truth/TruthTableComponent';
import { LevelHelp } from './level-help/LevelHelp';
import { DiagramMissionState } from '../../diagram/diagramMissionState';
import { DiagramMissionComponentBuilder } from '../../diagram/diagramMissionComponentBuilder';
import { GameState, GameStateContext } from '../gameState';
import { MissionStatus } from '../missionStatus';
import { VerificationResultSet } from '../verificationResults';
import { TextContext } from '../../locale/text.service';
import { MissionItem } from '../missionProgression';
import { UiText } from '../../locale/UiText';
import { MissionKind } from '../task';
import { MachineCodeMissionComponent } from '../../missions/MachineCodeMissionComponent';
import { EscapeMissionComponent } from '../../missions/escape/EscapeMissionComponent';
import { AssemblerMissionComponent } from '../../missions/AssemblerMissionComponent';
import { AssemblerMissionState } from '../../missions/assemblerBlinkMission';
import { EscapeMissionState } from '../../missions/escape/escapeMission';
import { CodeMissionState } from '../../missions/machineCodeMission';
import { OpcodesMissionComponent } from '../../missions/OpcodesMissionComponent';
import { Hint } from '../../diagram/hint';
import { PopupMessage } from './PopupMessage';
import { StackMissionComponent } from '../../missions/stack/StackMissionComponent';
import { GrammarMissionComponent } from '../../missions/grammar/GrammarMissionComponent';
import { TokenizeMissionComponent } from '../../missions/tokenize/TokenizeMissionComponent';
import { TokenizeMissionState } from '../../missions/tokenize/tokenizeMissionState';
import { StackMissionState } from '../../missions/stack/stackMissionState';
import { ExpressionGrammarState } from '../../missions/grammar/expressionGrammarMission';
import { CompilerMission } from '../../compiler/CompilerMission';
import { CodegenMissionState } from '../../missions/codegenMissionState';
import { StandardDiagramObserver, FirstMissionHints } from './diagramHints';
import { nandMission } from '../../diagram/missions/nandMission';
import { ActiveMission } from './TopLevel';
import { ErrorDialog } from './ErrorDialog';
import { DiagramVerificationResultSet } from '../../diagram/verification';
import { WithPopupWindow } from '../WithPopupWindow';
import { NetworkMissionComponent } from '../../missions/NetworkMissionComponent';


export function GameComponent({ activeMission, selectMission } : {
    activeMission: ActiveMission;
    selectMission: (mission: MissionItem) => void; 
}) { 
    const mission =  activeMission.mission;
    const missionState =  activeMission.state;
    const gameState = useContext(GameStateContext);
    const textService = useContext(TextContext);

	const [state, setState] = useState(() => {
		return {
			testResults: undefined as VerificationResultSet | undefined,
            showTestDialog: false,
            popupMessage: getSplashText(activeMission),
            showFinished: false,
            // hints are undefined initally
            // can only be rendered when the rest have been rendered
            hints: [] as Hint[]
        };
	});
    const {testResults, showTestDialog, showFinished, popupMessage, hints } = state;


    // advance to the next mission in the track
    function nextMission() {
        const next = gameState.missionNavigator.getNextMission(activeMission.item);
        if (!next) {
            setState(st => ({...st, showTestDialog: false, popupMessage: undefined, showFinished: true}));
        } else {
            selectMission(next);
        }
    }
    function getSplashText(activeMission: ActiveMission) {
        // splash only if mission is not finished
        if (activeMission.item.status !== MissionStatus.Complete) {
            // display splash if it exists
            const splash = activeMission.texts.splash;
            if (splash && splash.trim() !== '') {
                return splash;
            }
        }
    }

    function verify() {
        gameState.missionProgression.save();
        const testResults = missionState.verify();
        setState({...state, testResults: testResults, showTestDialog: true, popupMessage: undefined });
    }

    function skipLevel() {
        nextMission();
    }

    function closeTestDialog() { 
        setState({...state, showTestDialog: false})
    }
    function closeMessage() { 
        setState({...state, popupMessage: undefined});
    }

    function completeDialog() {
        state.showTestDialog = false;
        nextMission();
    }

    function getUiText(id: string) {
        return textService.getText('ui', id);
    }
    function resetGame() {
        if (window.confirm(getUiText('confirm_clear_levels'))) {
            // just clear local storage and reload page.
            window.localStorage.clear();
            window.location.reload();
        }
    }

    function setHints(hints: Hint[]) {
        // queue to after rendering, since hints may depend on newly created elements
        window.setTimeout(()=> {
            setState(st => ({...st, hints: hints}));
        }, 0);
    }

	return (
    <div className='game'>

    <div className='popup-container'>
    { popupMessage && (
        <PopupMessage key={popupMessage} html={popupMessage} close={closeMessage} />
    )} 

    { showTestDialog && testResults && testResults.succeeded && (
        <SuccessDialog testResults={testResults} closeTestDialog={closeTestDialog} completeDialog={completeDialog} 
            selectMission={selectMission} 
            activeMission={activeMission}  />)} 

    { showTestDialog && testResults && !testResults.succeeded && (
        <ErrorDialog testResults={testResults} closeTestDialog={closeTestDialog} />)} 

    { showFinished && <CompletedDialog onClose={()=>setState(st => ({...state, showFinished: false}))} />}
    
    </div>

        <MissionRoom key={mission.key} activeMission={activeMission} 
            selectMission={selectMission}
            setHints={setHints}
            skipLevel={skipLevel}
            resetGame={resetGame}
            verify={verify}
            gameState={gameState} />

        {hints.map(hint => (
            <DiagramHint key={hint.key} hint={hint} missionTexts={activeMission.texts} />))} 

    </div>);
}

function Specification({activeMission}: {activeMission: ActiveMission}) {
    const missionTexts = activeMission.texts;
    const [state, setState] = useState(() => ({helpVisible: false}));
    const {helpVisible} = state;
    const missionHasHelp =  missionTexts.elaboration !== undefined || missionTexts.spoilers !== undefined;
    function toggleHelp() {
        setState({...state, helpVisible: !state.helpVisible});
    }
    function helpClosed() {
        setState({...state, helpVisible: false});
    }
    const tag = activeMission.mission.tag;
    return (<>    

    { helpVisible && (
        <LevelHelp activeMission={activeMission} helpClosed={helpClosed} />)} 

    <nav className='navbar navbar-expand'>
        { missionHasHelp && (
            <div className='mission-help-button'>
            <button onClick={toggleHelp} className='btn btn-outline-secondary ms-2'><UiText id='level_help' /></button>
        </div>)} 
    </nav>
    <div className='specification'>
        <div className='instructions'>
            <p>
                <b>{missionTexts.title}</b>
                { tag && (
                    <span className='badge bg-warning text-dark'>
                    {tag}
                    </span>)} 
            </p>
            <div dangerouslySetInnerHTML={{__html: missionTexts.specification}}></div>
        </div>
    </div>
    </>);
}

function MissionRoom(props: {
        activeMission: ActiveMission, 
        selectMission: (mission: MissionItem) => void;
        setHints: (hints: Hint[]) => void;
        skipLevel: () => void;
        verify: () => void;
        resetGame: () => void;
        gameState: GameState
    }) {
    const activeMission = props.activeMission;
    const missionItem = activeMission.item;
    const missionState = activeMission.state;
    const currentMission = activeMission.mission;
    const missionKind = missionItem.mission.kind;

    function resetState() { missionState.resetState(); }

    function getMissionComponent() {
    switch (missionKind) {
        case MissionKind.Opcodes: return (
            <OpcodesMissionComponent currentMission={missionItem} key={currentMission.key} />); 
        case MissionKind.MachineCode: return (
            <MachineCodeMissionComponent missionState={missionState as CodeMissionState} key={currentMission.key} />); 
        case MissionKind.Assembler: return (
            <AssemblerMissionComponent missionState={missionState as AssemblerMissionState} key={currentMission.key} />); 
        case MissionKind.Escape: return (
            <EscapeMissionComponent missionState={missionState as EscapeMissionState} key={currentMission.key} />); 
        case MissionKind.Network: return (
            <NetworkMissionComponent missionState={missionState as AssemblerMissionState} key={currentMission.key} />);
        case MissionKind.Stack:
            return (
                    <StackMissionComponent
                        missionState={missionState as StackMissionState}
                        selectMission={props.selectMission}
                        gameState={props.gameState}
                    />
                );
        case MissionKind.Tokenize:
            return <TokenizeMissionComponent missionState={missionState as TokenizeMissionState} />;
        case MissionKind.Grammar:
            return <GrammarMissionComponent missionState={missionState as ExpressionGrammarState} />;
        default:
            return <CompilerMission missionState={missionState as CodegenMissionState} gameState={props.gameState} />;
    }
}
if (missionKind === MissionKind.ComponentBuilder) {
    return (
        <DiagramMissionComponent key={currentMission.key} 
            activeMission={activeMission} missionState={missionState as DiagramMissionState}
            setHints={props.setHints} resetGame={props.resetGame} skipLevel={props.skipLevel}
            verify={props.verify}
            />); 
    } else {
        return (<>
        <div className='leftbar'>
        <Specification activeMission={activeMission} />
        </div>

        <div className='current'>
            <div className='navbar navbar-expand toolbar'>
            <div className='btn-group me-2'>
                <button id='btnCompleted' onClick={props.verify} className='btn btn-primary'>
                    <i className='bi bi-hand-thumbs-up'></i>
                    <UiText id='btn_level_complete' />
                </button>
            </div>
            { missionState.hasState && (
                <div className='btn-group me-2'>
                <button onClick={resetState} className='btn btn-secondary'>
                <UiText id='btn_reset_state' />
                </button>
            </div>)} 

            <div className='btn-group me-2'>
                <button id='btnClear' onClick={props.resetGame} className='btn btn-secondary'><UiText id='btn_clear_levels' /></button>
            </div>
            <div className='btn-group me-2'>
                <button onClick={props.skipLevel} className='btn btn-secondary'><UiText id='btn_skip_level' /></button>
            </div>
        </div>

        <div className='builder'>
            {getMissionComponent()}
        </div>
        </div>

        </>);
    }
}


function DiagramMissionComponent({activeMission, missionState, setHints, skipLevel, verify, resetGame}: {
        activeMission: ActiveMission;
        missionState: DiagramMissionState;
        setHints: (hints: Hint[]) => void;
        skipLevel: () => void;
        verify: () => void;
        resetGame: () => void;
}) {
    const gameState = useContext(GameStateContext);
    
    const [state, setState] = useState(()=>{
        console.log('init listner');
        return {
            listener: createListener(),
            verification: undefined as VerificationResultSet | undefined
    }}); 
    const {listener, verification} = state;

    const mission = missionState.missionType;
    const truthTable = mission.truthTable;
    const verifyTable = (verification instanceof DiagramVerificationResultSet) ? verification : undefined;

    useEffect(()=>{
        /* rendering complete */
        listener.ready();
     }, []);

    function clearCanvas() {
        missionState.diagram.clearCanvas();
        // force render
        setState(st => ({...st}));
    }
    function resetState() { 
        missionState.resetState(); 
        // force render
        setState(st => ({...st}));
    }
    function backgroundVerify(isConnected: boolean) {
        // background verify
        if (isConnected) {
            const testResults = missionState.verifyDiagram();
            setState(st => ({...state, verification: testResults}));
        } else {
            setState(st => ({...state, verification: undefined}));
        }
    }

    function createListener() {
        return missionState.missionType.key === nandMission.key ? 
                new FirstMissionHints(missionState, setHints, backgroundVerify) :
                new StandardDiagramObserver(missionState, setHints, backgroundVerify);
    }

    const model = new DiagramMissionComponentBuilder(missionState, gameState);
    return (<>
        
        <div className='leftbar'>
        <Specification activeMission={activeMission} />
        <div className='specification'>
        { truthTable && (
                    <TruthTableComponent table={truthTable} verification={verifyTable}  />)} 
        </div>
        </div>

        <div className='current'>
        <div className='navbar navbar-expand toolbar'>
            <div style={{width: '132px'}}></div>
            <div className='btn-group me-2'>
                <button id='btnCompleted' onClick={()=>verify()} className='btn btn-primary'>
                    <i className='bi bi-hand-thumbs-up'></i>
                    <UiText id='btn_level_complete' />
                </button>
            </div>

            { missionState.hasState && 
                <div className='btn-group me-2'>
                    <button onClick={()=>resetState()} className='btn btn-secondary'>
                        <UiText id='btn_reset_state' />
                    </button>
                </div>} 

            <div className='btn-group me-2'>
                <button id='btnClear' onClick={clearCanvas} className='btn btn-secondary'>
                    <UiText id='btn_clear_canvas' />
                </button>
                <button id='btnClear' onClick={resetGame} className='btn btn-secondary'><UiText id='btn_clear_levels' /></button>
            </div>
            <div className='btn-group me-2'>
                <button onClick={skipLevel} className='btn btn-secondary'><UiText id='btn_skip_level' /></button>
            </div>
        </div>
            <div className='builder'>
            <DiagramBuilder model={model} listener={listener} />
            </div>
        </div>
    </>);
}

function CompletedDialog({onClose}: {onClose: () => void}) {
    return (
        <WithPopupWindow onClose={onClose} visible>
            <div className="alert alert-success">
            <p><span className='game-completed-checkmark' style={{color:'green',fontSize:'40px'}}>✔</span>
            <b>Congratulations!</b> You have completed the game!</p>
            <p>You now know more about how a computer works than most software development professionals.</p>

            <p>If you loved it or you hated it,
            or if you have suggestions for improvements or have discovered bugs,
            write to me at <a href="mailto:olav@olav.dk">olav@olav.dk</a></p>
            </div>
            
            <div className='card-footer'>
                <button type="button" className="btn btn-primary" aria-label="Close" onClick={onClose}>
                    Close
                </button>
            </div>
        </WithPopupWindow>
    );
}