import './edge-group.css';
import { OutputNodeComponent } from './OutputNodeComponent';
import { InputConnectorState, OutputStateGroup } from '../../circuitState';
import { ComponentBuilder } from '../../componentBuilder';
import { DiagramActions } from '../builder/DiagramBuilder';

export function OutputGroupComponent(props: {
		group: OutputStateGroup;
		model: ComponentBuilder;
        selectedConnector: InputConnectorState | undefined
        diagramActions: DiagramActions;
    }) { 

    const outputNodes =  props.group.nodes;
    const multi =  props.group.nodes.length > 1;
    const hasLabel =  props.group.label !== undefined;
    const label =  props.group.label;
	const model = props.model;

	return (<div className={'diagram-output-group' + (multi ? ' multi' : '')}>
    <div className='component-row'>
        { hasLabel && (
            <div className='group-label'>{label}</div>)} 
        {(outputNodes).map((connector, ix) => (
            <OutputNodeComponent key={ix} connector={connector} model={model} 
                selectedConnector={props.selectedConnector}
                diagramActions={props.diagramActions}
                />))} 
    </div>
</div>
); 
}

