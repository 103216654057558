import { AssemblerPersistence } from '../assemblerMissions';
import { pureStackTest } from './codeTester';
import { StackMissionState } from './stackMissionState';
import { MissionKind } from '../../app/task';
import { Repository } from '../../app/repository';
import { MissionProgression } from '../../app/missionProgression';

export const stackAddMissionTests = [
    pureStackTest([9, 9], [18])
]

export class StackAddMissionState extends StackMissionState {
    tests = stackAddMissionTests;
}

export const stackAddMission = {
    key: 'STACK_ADD',
    kind: MissionKind.Stack,
    macro: { name: 'ADD' },
    start(repository: Repository, game: MissionProgression) {
        return new StackAddMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new StackAddMissionState(this, repository, game, data);
    },
};

export const stackSubMissionTests = [
    pureStackTest([9, 8], [1])
];

export class StackSubMissionState extends StackMissionState {
    tests = stackSubMissionTests;
}

export const stackSubMission = {
    key: 'STACK_SUB',
    kind: MissionKind.Stack,
    macro: { name: 'SUB' },
    start(repository: Repository, game: MissionProgression) {
        return new StackSubMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new StackSubMissionState(this, repository, game, data);
    },
};

export const stackAndMissionTests = [
    pureStackTest([0x100, 0x101], [0x100]),
];

export class StackAndMissionState extends StackMissionState {
    tests = stackAndMissionTests;
}

export const stackAndMission = {
    key: 'STACK_AND',
    kind: MissionKind.Stack,
    macro: { name: 'AND' },
    start(repository: Repository, game: MissionProgression) {
        return new StackAndMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new StackAndMissionState(this, repository, game, data);
    },
};

export const stackOrMissionTests = [
    pureStackTest([0x100, 0x001], [0x101])
];

export class StackOrMissionState extends StackMissionState {
    tests = stackOrMissionTests;
}

export const stackOrMission = {
    key: 'STACK_OR',
    kind: MissionKind.Stack,
    macro: { name: 'OR' },
    start(repository: Repository, game: MissionProgression) {
        return new StackOrMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new StackOrMissionState(this, repository, game, data);
    },
};

