import { Placeholder, PlaceholderType } from '../../assembler/instructionProvider';
import { Machine } from '../../assembler/machine';
import { AssemblerPersistence } from '../assemblerMissions';
import { MissionKind } from '../../app/task';
import { Repository } from '../../app/repository';
import { MissionProgression } from '../../app/missionProgression';
import { VerificationError, VerificationOk } from '../../app/verificationResults';
import { CodeTester } from './codeTester';
import { StackMissionState } from './stackMissionState';

class GotoTest extends CodeTester {
    setup(_machine: Machine) {
        // No setup necessary
    }
    getPlaceholders() {
        const placeholders = this.placeholders!;
        placeholders.set('label', 99);
        return placeholders;
    }
    verify(machine: Machine) {
        const pc = machine.pc.get();
        if (pc !== 99) {
            return new VerificationError(`Expected SP to be 99. (Was ${pc.toString(16)})`);
        }
        return new VerificationOk();
    }
}

export const gotoMissionTests = [GotoTest];

export class GotoMissionState extends StackMissionState {
    tests = gotoMissionTests;
}

export const gotoMission = {
    key: 'STACK_GOTO',
    kind: MissionKind.Stack,
    macro: {
        name: 'GOTO',
        parameters: [new Placeholder('label', PlaceholderType.Label)],
    },
    start(repository: Repository, game: MissionProgression) {
        return new GotoMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new GotoMissionState(this, repository, game, data);
    },
};

class IfGotoTest extends CodeTester {
    setup(machine: Machine) {
        // Setup - push true on the stack
        this.push(machine, 0xFFFF);
    }
    getPlaceholders() {
        const placeholders = this.placeholders!;
        placeholders.set('label', 99);
        return placeholders;
    }
    verify(machine: Machine) {
        const pc = machine.pc.get();
        if (pc !== 99) {
            return new VerificationError(`Expected SP to be 99. (Was ${pc})`);
        }
        return new VerificationOk();
    }
}

class IfGotoTest2 extends CodeTester {
    setup(machine: Machine) {
        // Setup - push true on the stack
        this.push(machine, 0);
    }
    getPlaceholders() {
        const placeholders = this.placeholders!;
        placeholders.set('label', 99);
        return placeholders;
    }
    verify(machine: Machine) {
        const pc = machine.pc.get();
        if (pc >= 99) {
            return new VerificationError(`Expected SP to be less than 99. (Was ${pc})`);
        }
        return new VerificationOk();
    }
}
export const ifGotoMissionTests =  [IfGotoTest, IfGotoTest2];

export class IfGotoMissionState extends StackMissionState {
    tests = ifGotoMissionTests;
}

export const ifGotoMission = {
    key: 'STACK_IF_GOTO',
    kind: MissionKind.Stack,
    macro: {
        name: 'IF_GOTO',
        parameters: [new Placeholder('label', PlaceholderType.Label)],
    },
    start(repository: Repository, game: MissionProgression) {
        return new IfGotoMissionState(this, repository, game);
    },
    restore(repository: Repository, game: MissionProgression) {
        const data = repository.getLevelData(this.key) as AssemblerPersistence;
        return new IfGotoMissionState(this, repository, game, data);
    },
};
