import { DiagramMissionType } from '../diagram/diagramMissionType';
import { DiagramMissionState } from './diagramMissionState';
import { DiagramRepository } from '../app/diagramRepository';
import { DiagramPersistence } from '../diagram/diagramPersistence';
import { MissionKind, Task } from '../app/task';
import { Repository } from '../app/repository';
import { MissionProgression } from '../app/missionProgression';

/** A mission where the task is to create a diagram of a component. */
export class DiagramMission implements Task {
    kind = MissionKind.ComponentBuilder;
    constructor(public missionType: DiagramMissionType) { }
    get key() { return this.missionType.key; }
    get tag() { return this.missionType.tag; }
    get unlock() { return this.missionType.unlock ?? false; }
    /** Initates the mission first time it is started */
    start(repository: Repository) {
        const levelRepository = new DiagramRepository(repository)
        const mission = new DiagramMissionState(this.missionType, levelRepository);
        // Need to reset input to set to the default state (which might not be 0).
        mission.diagram.clearInputs();
        mission.diagram.resetState();
        mission.diagram.structure.onStructureChange.addListener(() => { mission.save(); });
        mission.save();
        return mission;
    }
    restore(repository: Repository, missionProgression: MissionProgression) {
        const mission = new DiagramMissionState(this.missionType, new DiagramRepository(repository));
        const diagramData = repository.getLevelData(this.missionType.key) as DiagramPersistence;
        if (diagramData) {
            const diagramPersister = missionProgression.diagramDeserializer;
            diagramPersister.restoreDiagram(diagramData, mission.diagram.structure);
            mission.diagram.structure.onStructureChange.addListener(() => { mission.save(); });
            mission.diagram.clearInputs();
            mission.diagram.resetState();
        }
        return mission;
    }
    get hints() { return this.missionType.hints; }
}
