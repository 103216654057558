import { EventEmitter2 } from '../common/eventEmitter';
import { Machine } from './machine';

export interface CodeProvider {
    size: number;
    currentValueChanged: EventEmitter2<number>;
    fetch(addr: number): number;
}

export class Controller {
    runTimeoutId?: number;
    isRunning = false;
    tickDelayMs = 200;
    constructor(readonly machine: Machine, readonly editor: CodeProvider) {
        // onValueChanged is called if the value of the currently selected ROM address changes
        // (due to editing the assembler)
        editor.currentValueChanged.addListener(() => this.load());
        this.load();
    }
    tick() {
        this.machine.tick();
        this.load();
        return this.isFinished;
    }

    load() {
        const word = this.editor.fetch(this.machine.pc.get());
        this.machine.resolve(word);
    }
    reset() {
        this.machine.reset();
        this.load();
    }
    get isFinished() {
        return this.machine.pc.get() >= this.editor.size;
    }
}
